import React, { useContext, useState } from "react";
import { mainFunctions } from "../providers/MainProvider";
import CustomDatePicker from "../components/DatePicker.js";
import { MdDateRange } from "react-icons/md";
import { CreateWalletAsync } from "../redux/actions";
import { useDispatch } from "react-redux";

const CreateWallet = () => {
	const dispatch = useDispatch();
	const { setShowModal } = useContext(mainFunctions);
	const [data, setData] = useState({
		bvn: "",
		dateOfBirth: "",
	});
	const handleSubmit = () => {
		dispatch(
			CreateWalletAsync(data, async (_) => {
				setShowModal(false);
			})
		);
	};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">Create Wallet</div>
			<p className="subtitle text-center"></p>
			<div className="auth_form_container">
				<div className="fieldset">
					<label className="label">BVN</label>
					<div className="input_box">
						<input
							type="text"
							required={true}
							value={data.bvn}
							onChange={(e) => setData({ ...data, bvn: e.target.value })}
						/>
					</div>
					<label className="label">Date of Birth</label>
					<div className="input_box">
						<CustomDatePicker
							date={data.dateOfBirth}
							setDate={(value) => setData({ ...data, dateOfBirth: value })}
						/>
						<MdDateRange size={22} />
					</div>
				</div>

				<div className="btn_ btn_orange" onClick={handleSubmit}>
					Create
				</div>
			</div>
		</div>
	);
};

export default CreateWallet;
