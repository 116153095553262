import axios from "./axios";

export default class ResourceService {
	async getTables() {
		const response = await axios.get(`api/resource/get-tables`);
		return response;
	}

	async getPersonnel() {
		const response = await axios.get(`api/resource/get-personnels`);
		return response;
	}

	async getResources() {
		const response = await axios.get(`api/resource/get-resources`);
		return response;
	}

	async getResource(id) {
		const response = await axios.get(
			`api/resource/get-resource?resourceId=${id}`
		);
		return response;
	}

	async addResource(data) {
		const response = await axios.post(`api/resource/add-resource`, data);
		return response;
	}

	async updateResource(data) {
		const response = await axios.put(`api/resource/update-resource`, data);
		return response;
	}

	async deleteResource(id) {
		const response = await axios.delete(
			`api/resource/delete-resource?tableOrPersonnelId=${id}`
		);
		return response;
	}
}
