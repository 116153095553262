import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { loginAsync } from "../redux/actions";
import { useNavigate } from "react-router-dom";
import Input from "../components/Input";

export default function Login() {
	const dispatch = useDispatch();
	let navigate = useNavigate();
	const { loading } = useSelector((state) => state.auth);
	const [showPassword, setShowPassword] = useState(false);
	const [userData, setUserData] = useState({
		UserName: "",
		Password: "",
	});

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(
			loginAsync(userData, async (_) => {
				navigate("/dashboard");
			})
		);
	};

	return (
		<div className="auth_bg">
			<div className="auth_form">
				<div className="auth_form_container">
					<div className="logo"></div>
					<div className="auth_title">Welcome Back!</div>
					<div className="fieldset">
						<label className="label">Email Address</label>
						<div className="input_box">
							<input
								type="text"
								onChange={(e) =>
									setUserData({ ...userData, UserName: e.target.value })
								}
							/>
						</div>
					</div>

					<div className="fieldset">
						<label className="label">Password</label>
						<div className="input_box">
							<Input
								type={showPassword ? "text" : "password"}
								onChange={(e) =>
									setUserData({ ...userData, Password: e.target.value })
								}
							/>
							<span
								className="togglepassword"
								onClick={() => setShowPassword(!showPassword)}
							>
								{showPassword ? "Hide" : "Show"}
							</span>
						</div>
						<Link to="/forgotpassword">
							<div className="more-info">Forgot Password</div>
						</Link>
					</div>

					<div
						className="btn_ btn_orange"
						onClick={!loading ? handleSubmit : () => {}}
					>
						{loading ? (
							<div className="spinner-border" role="status"></div>
						) : (
							"LOGIN"
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
