import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { mainFunctions } from "../providers/MainProvider";
import {
	createCategoryAsync,
	getCategoriesAsync,
	updateCategoryAsync,
} from "../redux/actions";
import { showToast } from "../utils";

const AddCategory = ({ category, type }) => {
	const { setShowModal } = useContext(mainFunctions);
	const dispatch = useDispatch();
	const [addData, setAddData] = useState({
		categoryName: "",
	});
	const [editData, setEditData] = useState({
		categoryName: category?.name || "",
		prodCategoryId: category?.id || "",
	});

	const handleSubmit = (e) => {
		e.preventDefault();
		if (type === "add") {
			const { categoryName } = addData;
			if (!categoryName) {
				return showToast("Fill out all the fields", "error");
			}
			dispatch(
				createCategoryAsync(addData, async (_) => {
					dispatch(getCategoriesAsync());
					setShowModal(false);
				})
			);
		}
		if (type === "edit") {
			const { categoryName, prodCategoryId } = editData;
			if (!categoryName || !prodCategoryId) {
				return showToast("Fill out all the fields", "error");
			}
			dispatch(
				updateCategoryAsync(editData, async (_) => {
					dispatch(getCategoriesAsync());
					setShowModal(false);
				})
			);
		}
	};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">
				{type === "add" ? "Add a new Category" : "Edit Category Details"}
			</div>
			<p className="subtitle text-center"></p>

			{type === "add" && (
				<div className="auth_form_container">
					<div className="fieldset">
						<label className="label">Category Name</label>
						<div className="input_box">
							<input
								type="text"
								required={true}
								value={addData.categoryName}
								onChange={(e) =>
									setAddData({ ...addData, categoryName: e.target.value })
								}
							/>
						</div>
					</div>
					<div className="btn_ btn_orange" onClick={handleSubmit}>
						Save
					</div>
				</div>
			)}
			{type === "edit" && (
				<div className="auth_form_container">
					<div className="fieldset">
						<label className="label">Category Name</label>
						<div className="input_box">
							<input
								type="text"
								required={true}
								value={editData.categoryName}
								onChange={(e) =>
									setEditData({ ...editData, categoryName: e.target.value })
								}
							/>
						</div>
					</div>
					<div className="btn_ btn_orange" onClick={handleSubmit}>
						Save
					</div>
				</div>
			)}
		</div>
	);
};

export default AddCategory;
