import { resourceService } from "../../services";
import * as types from "../types";
import { showErrorMessage } from "./auth.actions";

export const addResourceAsync = (data, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.ADD_RESOURCE.REQUEST });
		try {
			await resourceService.addResource(data);
			dispatch({ type: types.ADD_RESOURCE.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.ADD_RESOURCE.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};

export const getTablesAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_TABLES.REQUEST });
		try {
			const responseData = await resourceService.getTables();
			dispatch({ type: types.GET_TABLES.SUCCESS, payload: responseData });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_TABLES.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};

export const getPersonnelAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_PERSONNEL.REQUEST });
		try {
			const responseData = await resourceService.getPersonnel();
			dispatch({ type: types.GET_PERSONNEL.SUCCESS, payload: responseData });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_PERSONNEL.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};

export const updateResourceAsync = (data, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.UPDATE_RESOURCE.REQUEST });
		try {
			const responseData = await resourceService.updateResource(data);
			dispatch({ type: types.UPDATE_RESOURCE.SUCCESS, payload: responseData });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.UPDATE_RESOURCE.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};

export const getResourceAsync = (
	resourceId,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_RESOURCE.REQUEST });
		try {
			const responseData = await resourceService.getResource(resourceId);
			dispatch({ type: types.GET_RESOURCE.SUCCESS, payload: responseData });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_RESOURCE.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};

export const getResourcesAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_RESOURCES.REQUEST });
		try {
			const responseData = await resourceService.getResources();
			dispatch({ type: types.GET_RESOURCES.SUCCESS, payload: responseData });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_RESOURCES.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};

export const deleteResourceAsync = (id, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.DELETE_RESOURCE.REQUEST });
		try {
			dispatch({ type: types.DELETE_RESOURCE.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.DELETE_RESOURCE.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
