import React, { useContext } from "react";
import { mainFunctions } from "../providers/MainProvider";
import { Link, useNavigate } from "react-router-dom";
import { useGuard } from "../hooks/useGuard";
import { storageService } from "../services";
import { useSelector } from "react-redux";

export default function TopBar(props) {
	useGuard();
	const { companyInfo } = useSelector((state) => state.auth);
	const { name, userName, Role } = storageService.getAuthData();

	const {
		setShowSidebar,
		// logout
	} = useContext(mainFunctions);
	const navigate = useNavigate();
	// const [showSetting, setShowSettings] = useState(false)
	return (
		<div className="topbar bg-dark-gray2">
			<div
				className="page-anchor"
				onClick={() => {
					setShowSidebar(true);
				}}
			></div>
			<div className="d-flex align-items-center gap-3">
				{props.small && (
					<div className="back">
						<div className="back_icon"></div>
						<div className="back_text" onClick={() => navigate(-1)}>
							Back
						</div>
					</div>
				)}
				<div className={`title ${props.small ? "small" : ""}`}>
					{props.title}
				</div>
				{props.handleSearch && (
					<div className="search_wrapper">
						<div className="icon search"></div>
						<input
							className="search_input"
							placeholder="Search..."
							type="text"
							onChange={(e) => props.handleSearch(e.currentTarget.value)}
						/>
					</div>
				)}
			</div>
			<div className="align-right right-box">
				{Role === "Cashier" && (
					<div className="d-flex items-center text-glow">
						<Link to="/cashier/transactions">Transactions</Link>
					</div>
				)}
				{props.alt_button && (
					<div
						className="btn_ download_button btn_white ms-4"
						onClick={() => {
							props.alt_buttonAction();
						}}
					>
						{props.alt_button.title}
					</div>
				)}
				{props.button && (
					<div
						className="btn_ btn_orange download_button ms-4"
						onClick={() => {
							props.buttonAction();
						}}
					>
						{props.button.title}
					</div>
				)}
				<div className="profile">
					<div className="profile-image">
						<img src={companyInfo.coverImage} alt="logo" />
					</div>
					<div className="profile-details">
						<div className="username">{name}</div>
						<div className="email">{userName}</div>
					</div>
					{/* <div className='icon more'
            onClick={() => setShowSettings(!showSetting)}
          ></div>
          {showSetting &&
            <div className='settings_dropdown'>
              <div className='settings_dropdown_list'
                onClick={() => logout()}
              >Logout</div>
            </div>
          } */}
				</div>
			</div>
		</div>
	);
}
