import React, { useContext, useEffect, useState } from "react";
import { mainFunctions } from "../../providers/MainProvider";

import Sidebar from "../../components/Sidebar";
import TopBar from "../../components/TopBar";
import { useGuard } from "../../hooks/useGuard";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesAsync } from "../../redux/actions";
import Tables from "../../components/Tables";
import { getDate } from "../../utils";
import { BsThreeDots } from "react-icons/bs";
import CreateEditCategory from "../../modals/Create_EditCategory";
import DeleteCategory from "../../modals/DeleteCategory";

export default function Category() {
	useGuard();
	const dispatch = useDispatch();
	const { categories } = useSelector((state) => state.product);
	useEffect(() => {
		dispatch(getCategoriesAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { setShowModal, setModalData } = useContext(mainFunctions);

	const tableColumns = [
		{
			title: "ID",
			dataIndex: "id",
		},
		{
			title: "Name",
			dataIndex: "name",
		},
		{
			title: "No Of Products",
			dataIndex: "noOfProducts",
		},
		{
			title: "Added Date",
			dataIndex: "addedDate",
		},
		{
			title: "Last Updated Date",
			dataIndex: "updateDate",
		},
		{
			title: "",
			dataIndex: "option",
		},
	];

	const [filteredTableData, setFilteredTableData] = useState(categories);

	const [visibilities, setVisibilities] = useState(() =>
		filteredTableData.map((x) => false)
	);

	const handleClick = (index) => {
		const newVisibilities = [...visibilities];
		newVisibilities.map((thisVisibility, ind) => {
			index !== ind
				? (newVisibilities[ind] = false)
				: (newVisibilities[index] = !newVisibilities[index]);
			return 0;
		});
		setVisibilities(newVisibilities);
	};

	const dataSource =
		filteredTableData && filteredTableData.length > 0
			? filteredTableData.map((row, index) => {
					return {
						id: <div>ID: {row.id}</div>,
						name: <div>{row.name}</div>,
						noOfProducts: <div>{row.totalNoProduct}</div>,
						addedDate: <div>{getDate(row.dateCreated)}</div>,
						updateDate: <div>{getDate(row.dateUpdated)}</div>,
						option: (
							<div className="">
								<div className="position-relative">
									<div
										className="d-flex items-center"
										style={{ cursor: "pointer" }}
									>
										<BsThreeDots onClick={() => handleClick(index)} size={24} />
									</div>
									{visibilities[index] ? (
										<div
											className="
											position-absolute border border-muted px-3 py-2 w-32 bg-dark-gray2 rounded-md
											"
											style={{
												right: "0",
												top: "100%",
												zIndex: "2",
												width: "150px",
											}}
										>
											<div
												onClick={async () => {
													setModalData(
														<CreateEditCategory category={row} type="edit" />
													);
													setShowModal(true);
												}}
												style={{ cursor: "pointer" }}
												className="
												d-flex text-left text-white hover:text-glow text-md py-[10px] border-b border-gray-300"
											>
												Edit Details
											</div>
											<div
												onClick={() => {
													setModalData(<DeleteCategory data={row} />);
													setShowModal(true);
												}}
												style={{ cursor: "pointer" }}
												className={`d-flex text-left text-red-600 hover:text-glow text-md py-[10px]`}
											>
												{"Delete Category"}
											</div>
										</div>
									) : (
										""
									)}
								</div>
							</div>
						),
					};
				})
			: [];

	useEffect(() => {
		setFilteredTableData(categories);
		setVisibilities(() => categories.map((x) => false));
	}, [categories]);
	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar
					title="Categories"
					// button={{
					//   title: "DOWNLOAD REPORT"
					// }}
					// buttonAction={()=>{}}
				/>
				<div className="mainbar-container">
					<div
						className="btn_ btn_green mb-3 top_btn"
						onClick={async () => {
							await setModalData(<CreateEditCategory type="add" />);
							await setShowModal(true);
						}}
					>
						ADD CATEGORY
					</div>

					<Tables
						title="All Categories"
						dataSource={dataSource}
						columns={tableColumns}
						handleSearch={true}
						showPagination={true}
						showPageSize={true}
						source={categories}
						setFilteredTableData={setFilteredTableData}
					/>
				</div>
			</div>
		</div>
	);
}
