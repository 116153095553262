import React, { useState, useContext, useEffect } from "react";
import { mainFunctions } from "../providers/MainProvider";
import Sidebar from "../components/Sidebar";
import SideTabTitle from "../components/SideTabTitle";
import TopBar from "../components/TopBar";
import Support from "../components/Support";
import Setup from "../components/Setup";

import { useDispatch, useSelector } from "react-redux";
import Security from "../components/Security";
import UserRoles from "../components/UserRoles";
import AddUser from "../modals/AddUser";
import EditUser from "../modals/EditUser";
import DeactivateUser from "../modals/DeactivateUser";
import { storageService } from "../services";
import { useGuard } from "../hooks/useGuard";
import { getUsersAsync } from "../redux/actions";

export default function AccountSettings() {
	useGuard();
	const dispatch = useDispatch();
	const { users } = useSelector((state) => state.user);
	useEffect(() => {
		dispatch(getUsersAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const {
		setModalPage,
		setModalData,
		setShowModal,
		EDIT_USER_MODAL,
		ADD_USER_MODAL,
		DEACTIVATE_USER_MODAL,
	} = useContext(mainFunctions);

	const [userModal, setUserModal] = useState("");
	const [currentUser, setCurrentUser] = useState(null);

	useEffect(() => {
		if (userModal) {
			if (userModal === "add") {
				setModalPage(ADD_USER_MODAL);
				setModalData(<AddUser />);
			} else if (userModal === "edit" && currentUser !== null) {
				setModalPage(EDIT_USER_MODAL);
				setModalData(<EditUser user={currentUser} />);
			} else if (userModal === "deactivate" && currentUser !== null) {
				setModalPage(DEACTIVATE_USER_MODAL);
				setModalData(<DeactivateUser user={currentUser} />);
			}
			setShowModal(true);
		}
		//eslint-disable-next-line
	}, [userModal]);

	const authData = storageService.getAuthData();
	// const SECURITY_TAB = {id:"security", title:"Security"};
	const USER_TAB = { id: "user", title: "User & Roles" };
	const SUPPORT_TAB = { id: "support", title: "Support" };
	const SETUP_TAB = { id: "setup", title: "EW Setup" };
	const [activeChartTab, setActiveChartTab] = useState(
		authData.Role === "SuperAdministrator" ? SETUP_TAB : SUPPORT_TAB
	);

	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar title="Account Settings" />
				<div className="mainbar-container full-width">
					<SideTabTitle
						pages={
							authData.Role === "SuperAdministrator"
								? [SETUP_TAB, USER_TAB, SUPPORT_TAB]
								: [SUPPORT_TAB]
						}
						active={activeChartTab}
						setActive={setActiveChartTab}
					/>
					<div className="mainbar-container half-container">
						{activeChartTab.id === "security" && <Security />}
						{activeChartTab.id === "user" && (
							<div>
								{/* <div className="page-filter" style={{
                      position:"unset",
                      width:"100%",
                      display:"flex", 
                      flexDirection:"row-reverse"
                    }}>
                      <div className='btn_ btn_orange mb-3' style={{position:"unset"}}
                      onClick={async ()=>{
                        await setModalPage(ADD_USER_MODAL)
                        await setModalData(<AddUser />);
                        setShowModal(true)
                      }}
                      >ADD USER</div>
                    </div> */}
								<UserRoles
									users={users}
									setUserModal={setUserModal}
									setCurrentUser={setCurrentUser}
									setModalPage={setModalPage}
									setModalData={setModalData}
									setShowModal={setShowModal}
									EDIT_USER_MODAL={EDIT_USER_MODAL}
									DEACTIVATE_USER_MODAL={DEACTIVATE_USER_MODAL}
								/>
							</div>
						)}
						{activeChartTab.id === "support" && <Support />}
						{activeChartTab.id === "setup" && <Setup />}
					</div>
				</div>
			</div>
		</div>
	);
}
