import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { mainFunctions } from "../providers/MainProvider";
import { convertNumberToDateTime, getDateTime } from "../utils";
import EditAppointment from "../modals/EditAppointment";
import { BsThreeDots } from "react-icons/bs";
import { getAppointmentsAsync, getServicesAsync } from "../redux/actions";
import Tables from "./Tables";
import DeleteAppointment from "../modals/DeleteAppointment";

export default function Appointments() {
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const { appointments } = useSelector((state) => state.appointment);
	const { services } = useSelector((state) => state.service);
	const [allServices, setAllServices] = useState([]);
	const [visibilities, setVisibilities] = useState([]);
	const { setShowModal, setModalData } = useContext(mainFunctions);

	const tableStatusOptions = [
		{ title: "All", value: "" },
		{ title: "booked", value: "Booked" },
		{ title: "active", value: "Active" },
		{ title: "used", value: "Used" },
	];
	const [allAppointments, setAllAppointments] = useState([]);
	const [tableStatus, setTableStatus] = useState(tableStatusOptions[0]);
	const allColumns = [
		{
			title: "ID",
			dataIndex: "id",
		},
		{
			title: "Resource Type",
			dataIndex: "resourceType",
		},
		{
			title: "Booking Time",
			dataIndex: "bookingTime",
		},

		{
			title: "Customer Name",
			dataIndex: "customerName",
			search: true,
			filter: true,
		},
		{
			title: "Appointment Time",
			dataIndex: "appointmentTime",
			search: true,
			filter: true,
		},
		{
			title: "Payment Info",
			dataIndex: "paymentInfo",
		},
		{
			title: "",
			dataIndex: "option",
			search: "false",
		},
	];
	const [filteredTableData, setFilteredTableData] = useState(allAppointments);

	const handleClick = (index) => {
		const newVisibilities = [...visibilities];
		newVisibilities.map((thisVisibility, ind) => {
			index !== ind
				? (newVisibilities[ind] = false)
				: (newVisibilities[index] = !thisVisibility);
			return 0;
		});
		setVisibilities(newVisibilities);
	};

	useEffect(() => {
		setFilteredTableData(allAppointments);
	}, [allAppointments]);

	useEffect(() => {
		let e = filteredTableData.map((x, ind) => {
			return false;
		});
		setVisibilities(e);
	}, [filteredTableData]);

	useEffect(() => {
		if (typeof appointments.data !== "undefined") {
			if (typeof appointments.data.data !== "undefined") {
				setAllAppointments(appointments.data.data);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appointments]);

	const getDataWithId = (id, data) => {
		let r = {};
		data.forEach((thisdata) => {
			if (thisdata.id === id) {
				r = thisdata;
			}
		});
		return r;
	};
	useEffect(() => {
		dispatch(getAppointmentsAsync());
		dispatch(getServicesAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const dataSource =
		filteredTableData && filteredTableData.length > 0
			? filteredTableData.map((row, index) => {
					let serviceData = getDataWithId(row.serviceId, allServices);
					let r = allAppointments;
					let resourceData = getDataWithId(row.resourceId, r);

					return {
						id: <div>ID: {row.id}</div>,
						customerName: row.customerName,
						tableName: resourceData.tableName,
						numberOfGuest: row.numberOfGuest,
						resource: resourceData.firstName
							? `${resourceData.firstName} ${resourceData.lastName}`
							: "",
						service: serviceData.name ? serviceData.name : "",
						resourceType: row.resourceType === 0 ? "Table" : "Personnel",
						bookingTime: <div>{getDateTime(row.createdAt)}</div>,
						paymentInfo: (
							<div className={`status-${row.paymentStatus.toLowerCase()}`}>
								{" "}
								{row.paymentStatus}{" "}
							</div>
						),
						appointmentTime: (
							<div> {convertNumberToDateTime(row.appointmentTime)} </div>
						),

						option: (
							<div className="">
								<div className="position-relative">
									<div
										className="d-flex items-center"
										style={{ cursor: "pointer" }}
									>
										<BsThreeDots
											onClick={() => {
												handleClick(index);
											}}
											size={24}
										/>
									</div>
									{visibilities[index] && (
										<div
											className="position-absolute border border-muted px-3 w-32 bg-white"
											style={{
												right: "0",
												top: "100%",
												zIndex: "2",
												width: "150px",
											}}
										>
											<div
												onClick={async () => {
													navigate(`/appointment/${row.id}`, {
														state: {
															row: row,
														},
													});
												}}
												style={{ cursor: "pointer" }}
												className="d-flex text-left py-3 border-bottom border-muted hover:text-blue-dark text-small"
											>
												View
											</div>
											<div
												onClick={async () => {
													setModalData(<EditAppointment row={row} />);
													setShowModal(true);
												}}
												style={{ cursor: "pointer" }}
												className="d-flex text-left py-3 border-bottom border-muted status-success hover:text-blue-dark text-small"
											>
												Edit Appointment
											</div>
											<div
												onClick={() => {
													setModalData(<DeleteAppointment row={row} />);
													setShowModal(true);
												}}
												style={{ cursor: "pointer" }}
												className="d-flex text-left py-3 status-failed hover:text-blue-dark text-small"
											>
												Remove
											</div>
										</div>
									)}
								</div>
							</div>
						),
					};
				})
			: [];

	useEffect(() => {
		if (typeof services !== "undefined" && services !== null) {
			if (typeof services.data !== "undefined") {
				if (typeof services.data.data !== "undefined") {
					setAllServices(services.data.data);
				}
			}
		}
	}, [services]);

	return (
		<div className="mainbar-container">
			<div className="col-sm-12">
				<Tables
					title={`All Appointments`}
					dataSource={dataSource}
					columns={allColumns}
					setFilteredTableData={setFilteredTableData}
					source={allAppointments}
					//Filters Props
					handleStatusFilter={false}
					handleDateFilter={false}
					tableStatusOptions={tableStatusOptions}
					tableStatus={tableStatus}
					setTableStatus={setTableStatus}
					//search prop
					handleSearch={true}
					// pagination props
					showPagination={true}
					showPageSize={true}
					// extrabutton={{
					//   title: "Calendar View",
					//   iconClassName: "calendar",
					//   className: "orange",
					//   action:
					//     async () => {
					//       await setModalData(
					//         <CalendarViewAppointments />
					//       )
					//       await setShowModal(true);
					//     }
					// }}
				/>
			</div>
		</div>
	);
}
