import React from "react";

export default function DetailsBox(props) {
	return (
		<div className="details_box col-md-3 col-sm-6 col-12">
			<div className="title">{props.title}</div>
			<div className={`value ${props.cssClass}`}>{props.value}</div>
		</div>
	);
}
