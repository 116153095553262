import React, { useState, useEffect, useContext } from "react";
import PieChart from "../../components/charts/PieChartComponent";
import SummaryBox from "../../components/SummaryBox";
import TabTitle from "../../components/TabTitle";
import Title from "../../components/Title";
import TopBar from "../../components/TopBar";
import LineChartComponent from "../../components/charts/LineChartComponent";
import Sidebar from "../../components/Sidebar";
import { useGuard } from "../../hooks/useGuard";
import Tables from "../../components/Tables";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardDataAsync } from "../../redux/actions/transaction.actions";
import { formatSales, formatToCurrency, formatTransactions } from "../../utils";
import { mainFunctions } from "../../providers/MainProvider";
import PageFilters from "../../components/PageFilters";
import AnalyticsWrapper from "../../components/AnalyticsWrapper";
import Divider from "../../components/Divider";

export default function Dashboard() {
	useGuard();
	const dispatch = useDispatch();
	const { dateFilter } = useContext(mainFunctions);
	const { dashboardData } = useSelector((state) => state.transaction);
	const d = new Date();
	let year = d.getFullYear();
	useEffect(() => {
		let activeDateFilter = {
			startDate: "",
			endDate: "",
		};
		if (dateFilter?.id) {
			if (dateFilter.id === "today") {
				const currentDate = new Date();
				const year = currentDate.getFullYear();
				const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
				const day = currentDate.getDate().toString().padStart(2, "0");

				const formattedDate = `${year}-${month}-${day}`;
				activeDateFilter = {
					startDate: formattedDate,
					endDate: formattedDate,
				};
			} else if (dateFilter.id === "thisweek") {
				const currentDate = new Date();
				const currentDay = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
				const startDate = new Date(currentDate);
				startDate.setDate(currentDate.getDate() - currentDay); // Start of the week (Sunday)
				const startYear = startDate.getFullYear();
				const startMonth = (startDate.getMonth() + 1)
					.toString()
					.padStart(2, "0");
				const startDay = startDate.getDate().toString().padStart(2, "0");

				const formattedStartDate = `${startYear}-${startMonth}-${startDay}`;

				const endDate = new Date(startDate);
				endDate.setDate(startDate.getDate() + 6); // End of the week (Saturday)
				const endYear = endDate.getFullYear();
				const endMonth = (endDate.getMonth() + 1).toString().padStart(2, "0");
				const endDay = endDate.getDate().toString().padStart(2, "0");

				const formattedEndDate = `${endYear}-${endMonth}-${endDay}`;
				activeDateFilter = {
					startDate: formattedStartDate,
					endDate: formattedEndDate,
				};
			} else if (dateFilter.id === "thismonth") {
				const currentDate = new Date();

				const startDate = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth(),
					1
				);
				const startYear = startDate.getFullYear();
				const startMonth = (startDate.getMonth() + 1)
					.toString()
					.padStart(2, "0");
				const startDay = startDate.getDate().toString().padStart(2, "0");

				const formattedStartDate = `${startYear}-${startMonth}-${startDay}`;

				const endDate = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth() + 1,
					0
				);
				const endYear = endDate.getFullYear();
				const endMonth = (endDate.getMonth() + 1).toString().padStart(2, "0");
				const endDay = endDate.getDate().toString().padStart(2, "0");

				const formattedEndDate = `${endYear}-${endMonth}-${endDay}`;
				activeDateFilter = {
					startDate: formattedStartDate,
					endDate: formattedEndDate,
				};
			} else if (dateFilter.id === "thisyear") {
				const currentDate = new Date();

				const startDate = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth(),
					1
				);
				const startYear = startDate.getFullYear();

				const formattedStartDate = `${startYear}-01-01`;

				const endDate = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth() + 1,
					0
				);
				const endYear = endDate.getFullYear();

				const formattedEndDate = `${endYear}-12-31`;
				activeDateFilter = {
					startDate: formattedStartDate,
					endDate: formattedEndDate,
				};
			}
		}
		dispatch(getDashboardDataAsync(activeDateFilter));
	}, [dateFilter, dispatch]);

	let SALES_CHART_TAB = {
		id: "amount",
		title: "Revenue",
		yTitle: "Amount",
		xTitle: `Period - ${year}`,
		data: formatSales(dashboardData.revenueOfSalesInPeriods),
	};
	let VISITS_CHART_TAB = {
		id: "noOfTransaction",
		yTitle: "Transactions",
		xTitle: `Period - ${year}`,
		title: "Number of Transactions",
		data: formatTransactions(dashboardData.numberOfTransactions),
	};
	const [activeChartTab, setActiveChartTab] = useState(SALES_CHART_TAB);
	const [top5Stores, setTop5Stores] = useState([]);
	const [top5SellingProducts, setTop5SellingProducts] = useState([]);

	useEffect(() => {
		setTop5Stores(dashboardData.topStores ? dashboardData.topStores : []);
		setTop5SellingProducts(
			dashboardData.topSellingProducts ? dashboardData.topSellingProducts : []
		);
		setFilteredTableData(dashboardData.stores);
		setActiveChartTab(SALES_CHART_TAB);
	}, [dashboardData]);

	const tableColumns = [
		{
			title: "Store ID",
			dataIndex: "id",
		},
		{
			title: "Store Name",
			dataIndex: "store",
		},
		{
			title: "Location",
			dataIndex: "location",
		},
		{
			title: "Store Manager",
			dataIndex: "manager",
		},
		{
			title: "Revenue",
			dataIndex: "revenueMade",
		},
		{
			title: "Total Customers",
			dataIndex: "totalCustomers",
		},
	];

	const [filteredTableData, setFilteredTableData] = useState([]);
	const dataSource =
		filteredTableData && filteredTableData?.length > 0
			? filteredTableData.map((row) => {
					return {
						id: <div>{row.storeId}</div>,
						store: <div>{row.storeName}</div>,
						location: <div>{row.location}</div>,
						manager: <div>{row.storeManager}</div>,
						revenueMade: <div>₦{formatToCurrency(row.revenueMade, 2)}</div>,
						totalCustomers: <div>{row.totalCustomers}</div>,
					};
				})
			: [];

	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar title="Dashboard" />
				<div className="mainbar-container">
					<PageFilters showStoreFilter={false} />
					<Title title="Overview" marginBottom="0" />
					<AnalyticsWrapper>
						<SummaryBox
							title={"Total Stores"}
							value={dashboardData.totalStores}
							comma={true}
						/>
						<Divider />
						{typeof dashboardData.totalRevenue !== "undefined" &&
						<>
							<SummaryBox
								title={"Revenue Made"}
								value={`₦${formatToCurrency(dashboardData.totalRevenue, 2)}`}
							/>
							<Divider />
						</>
						}
						{typeof dashboardData.overallActiveCustomers !== "undefined" &&
						<>
							<SummaryBox
								title={"Overall Active Customers"}
								value={dashboardData.overallActiveCustomers}
								comma={true}
							/>
							<Divider />
						</>
						}
						{/* {typeof dashboardData.walletBalance !== "undefined" &&
						<>
							<SummaryBox
								title={"Wallet Balance"}
								value={`₦${formatToCurrency(dashboardData.walletBalance, 2)}`}
							/>
						</>
						} */}
					</AnalyticsWrapper>

					<TabTitle
						pages={[SALES_CHART_TAB, VISITS_CHART_TAB]}
						active={activeChartTab}
						setActive={setActiveChartTab}
					/>
					<div className="col-12">
						<LineChartComponent
							data={activeChartTab.data}
							dataKey={activeChartTab.id}
							yTitle={activeChartTab.yTitle}
							xTitle={activeChartTab.xTitle}
						/>
					</div>

					<div className="row">
						<div className="col-xs-12 col-md-6 col-sm-12">
							<Title title="Top 5 Stores" />
							<PieChart data={top5Stores} />
						</div>
						<div className="col-xs-12 col-md-6 col-sm-12">
							<Title title="Top Selling Products" />
							<PieChart data={top5SellingProducts} />
						</div>
					</div>

					<Tables
						title={"My Stores"}
						dataSource={dataSource}
						columns={tableColumns}
						showPagination={true}
						source={dashboardData.stores}
						setFilteredTableData={setFilteredTableData}
					/>
				</div>
			</div>
		</div>
	);
}
