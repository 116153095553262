import cn from 'classnames';
import './ChecklistTask.scss';
export function ChecklistTask({
  isOpen,
  isComplete,
  isCurrent,
  showCompletionAnimation,
  title,
  description,
  children,
  onOpen,
}) {
  const canOpen = Boolean(description || children);

  const onClickSummary = (event) => {
    event.preventDefault();
    if (canOpen && !isOpen) {
      onOpen(event);
    }
  };

  return (
    <details
      className={cn(
        'details',
        isComplete && 'isComplete',
        isCurrent && 'isCurrentTask',
        isOpen && 'isOpen',
        !canOpen && 'disabled',
        showCompletionAnimation && 'completionAnimation'
      )}
      open={isOpen}
    >
      <summary
        className={'summary'}
        onClick={onClickSummary}
        tabIndex={canOpen ? '0' : '-1'}
      >
        <i className={'statusIcon'} />
        <span className={'title'}>{title}</span>
        {/* <SvgChevronDown className={$.toggleIcon} /> */}
      </summary>
      <div className={'content'}>
        <p className={'description'}>{description}</p>
        {children}
      </div>
    </details>
  );
}
