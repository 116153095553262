import { identityService, storageService } from "../services";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { suspend } from "../redux/actions/suspense.actions";
import { getCompanyInfoAsync, ssoLoginAsync } from "../redux/actions";
import { useNavigate } from "react-router-dom";

export const useGuard = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const authData = storageService.getAuthData();

	useEffect(() => {
		dispatch(suspend(true));
		identityService
			.isAuthenticated()
			.then((authorized) => {
				if (!authorized) {
					dispatch(
						ssoLoginAsync(
							async (_) => {
								await dispatch(getCompanyInfoAsync());
								return navigate("/dashboard");
							},
							async (_) => {
								// return window.location.replace(process.env.REACT_APP_LOGIN_URL);
							}
						)
					);
				}
			})
			.finally(() => {
				dispatch(suspend(false));
			});
		// eslint-disable-next-line
	}, [authData.accessToken]);

	return null;
};
