import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mainFunctions } from "../providers/MainProvider";
import {
	getChallengeTypesAsync,
	getChallengePeriodsAsync,
	getChallengesAsync,
	updateChallengeAsync,
} from "../redux/actions";
import CurrencyFormat from "react-currency-format";

const EditChallenge = ({ data }) => {
	const { setShowModal } = useContext(mainFunctions);
	const dispatch = useDispatch();
	const [challengeData, setChallengeData] = useState({
		id: data.id,
		title: data.title,
		description: data.description,
		rewardAmount: data.rewardAmount,
		targetAmount: data.targetAmount,
		challengePeriod: data.resetTimePeriod,
		challengeType: data.type,
	});
	const { challengeTypes, challengePeriods, loading } = useSelector(
		(state) => state.reward
	);
	useEffect(() => {
		dispatch(getChallengeTypesAsync());
		dispatch(getChallengePeriodsAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(
			updateChallengeAsync(challengeData, async (_) => {
				dispatch(getChallengesAsync());
				setShowModal(false);
			})
		);
	};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">Edit Challenge</div>
			<p className="subtitle text-center"></p>
			<div className="auth_form_container">
				<div className="fieldset">
					<label className="label">Title</label>
					<div className="input_box">
						<input
							type="text"
							value={challengeData.title}
							onChange={(e) =>
								setChallengeData({ ...challengeData, title: e.target.value })
							}
						></input>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Description</label>
					<div className="input_box">
						<textarea
							defaultValue={challengeData.description}
							onChange={(e) =>
								setChallengeData({
									...challengeData,
									description: e.target.value,
								})
							}
						></textarea>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Challenge Period</label>
					<div className="input_box">
						<select
							value={challengeData.challengePeriod}
							onChange={(e) =>
								setChallengeData({
									...challengeData,
									challengePeriod: e.target.value,
								})
							}
						>
							<option value="" disabled>
								Set a Period
							</option>
							{challengePeriods.map((item, index) => {
								return (
									<option value={item} key={index}>
										{item}
									</option>
								);
							})}
						</select>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Challenge Type</label>
					<div className="input_box">
						<select
							value={challengeData.challengeType}
							onChange={(e) =>
								setChallengeData({
									...challengeData,
									challengeType: e.target.value,
								})
							}
						>
							<option value="" disabled>
								Set a Challenge Type
							</option>
							{challengeTypes.map((item, index) => {
								return (
									<option value={item} key={index}>
										{item}
									</option>
								);
							})}
						</select>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Reward Amount</label>
					<div className="input_box">
						<CurrencyFormat
							value={challengeData.rewardAmount}
							thousandSeparator={true}
							prefix={"₦"}
							onValueChange={(values) => {
								const { value } = values;
								setChallengeData({
									...challengeData,
									rewardAmount: value,
								});
							}}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Target</label>
					<div className="input_box">
						<input
							type="text"
							value={challengeData.targetAmount}
							onChange={(e) =>
								setChallengeData({
									...challengeData,
									targetAmount: e.target.value,
								})
							}
						/>
					</div>
				</div>

				<div
					className="btn_ btn_orange"
					onClick={!loading ? handleSubmit : () => {}}
				>
					{loading ? (
						<div className="spinner-border" role="status"></div>
					) : (
						"Update Challenge"
					)}
				</div>
			</div>
		</div>
	);
};

export default EditChallenge;
