import React from "react";

export default function TimelineEvent({ startTime, endTime, events }) {
	return (
		<div className="timeline_container" style={{ overflow: "auto" }}>
			<div className="time_labels">
				{[...Array(Number(endTime + 1) - Number(startTime))].map((_, index) => {
					var hour_time = (index + startTime) % 12;
					hour_time = hour_time === 0 ? 12 : hour_time;

					var ampm = index + startTime >= 12 ? "PM" : "AM";
					return (
						<div className="time_label" key={index}>
							{hour_time}:00 {ampm}
						</div>
					);
				})}
			</div>

			<div className="timelines">
				{[...Array(Number(endTime + 1) - Number(startTime))].map((_, index) => {
					return <div className="lines" key={index}></div>;
				})}
				{events.map((this_event, index) => {
					return (
						<div
							className="timeline_event"
							key={index}
							style={{
								color: `${typeof this_event.color !== "undefined" ? this_event.color : "#fff"}`,
								backgroundColor: `${typeof this_event.bgColor !== "undefined" ? this_event.bgColor : "#FFE1C499"}`,
								top: `${
									Number(this_event.startTime) - Number(startTime) <= 0
										? 44
										: (Number(this_event.startTime) - Number(startTime)) * 44 +
											44
								}px`,
								height: `${typeof this_event.duration === "undefined" ? "43.4" : Number(this_event.duration) * 43.4}px`,
							}}
						>
							<p>{this_event.text}</p>
						</div>
					);
				})}
			</div>
		</div>
	);
}
