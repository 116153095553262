import { rewardService } from "../../services";
import { showToast } from "../../utils";
import * as types from "../types";
import { showErrorMessage } from "./auth.actions";

export const getDealTypesAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_DEAL_TYPES.REQUEST });
		try {
			const responseData = await rewardService.getDealTypes();
			dispatch({
				type: types.GET_DEAL_TYPES.SUCCESS,
				payload: responseData.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_DEAL_TYPES.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};

export const getDealsAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_DEALS.REQUEST });
		try {
			const responseData = await rewardService.getDeals();
			dispatch({
				type: types.GET_DEALS.SUCCESS,
				payload: responseData.data.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_DEALS.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const createDealAsync = (dealData, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.CREATE_DEAL.REQUEST });
		try {
			await rewardService.addDeal(dealData);
			showToast("Deal Created Successful", "success");
			dispatch({ type: types.CREATE_DEAL.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.CREATE_DEAL.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const updateDealAsync = (dealData, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.UPDATE_DEAL.REQUEST });
		try {
			await rewardService.updateDeal(dealData);
			showToast("Deal Updated Successful", "success");
			dispatch({ type: types.UPDATE_DEAL.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.UPDATE_DEAL.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const deleteDealAsync = (id, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.DELETE_DEAL.REQUEST });
		try {
			await rewardService.deleteDeal(id);
			showToast("Deal Deleted Successful", "success");
			dispatch({ type: types.DELETE_DEAL.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.DELETE_DEAL.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const updateDealStatusAsync = (data, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.UPDATE_DEAL_STATUS.REQUEST });
		try {
			const responseData = await rewardService.updateDealStatus(data);
			showToast("Deal Status Updated Successful", "success");
			dispatch({
				type: types.UPDATE_DEAL_STATUS.SUCCESS,
				payload: { ...responseData },
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.UPDATE_DEAL_STATUS.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};

export const getChallengePeriodsAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_CHALLENGE_PERIODS.REQUEST });
		try {
			const responseData = await rewardService.getChallengePeriods();
			dispatch({
				type: types.GET_CHALLENGE_PERIODS.SUCCESS,
				payload: responseData.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_CHALLENGE_PERIODS.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};

export const getChallengeTypesAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_CHALLENGE_TYPES.REQUEST });
		try {
			const responseData = await rewardService.getChallengeTypes();
			dispatch({
				type: types.GET_CHALLENGE_TYPES.SUCCESS,
				payload: responseData.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_CHALLENGE_TYPES.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};

export const getChallengesAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_CHALLENGES.REQUEST });
		try {
			const responseData = await rewardService.getChallenges();
			dispatch({
				type: types.GET_CHALLENGES.SUCCESS,
				payload: responseData.data.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_CHALLENGES.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const createChallengeAsync = (
	challengeData,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.CREATE_CHALLENGE.REQUEST });
		try {
			await rewardService.addChallenge(challengeData);
			showToast("Challenge Created Successful", "success");
			dispatch({ type: types.CREATE_CHALLENGE.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.CREATE_CHALLENGE.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const updateChallengeAsync = (
	challengeData,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.UPDATE_CHALLENGE.REQUEST });
		try {
			await rewardService.updateChallenge(challengeData);
			showToast("Challenge Updated Successful", "success");
			dispatch({ type: types.UPDATE_CHALLENGE.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.UPDATE_CHALLENGE.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const deleteChallengeAsync = (id, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.DELETE_CHALLENGE.REQUEST });
		try {
			await rewardService.deleteChallenge(id);
			showToast("Challenge Deleted Successful", "success");
			dispatch({ type: types.DELETE_CHALLENGE.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.DELETE_CHALLENGE.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const updateChallengeStatusAsync = (
	data,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.UPDATE_CHALLENGE_STATUS.REQUEST });
		try {
			const responseData = await rewardService.updateChallengeStatus(data);
			showToast("Challenge Status Updated Successful", "success");
			dispatch({
				type: types.UPDATE_CHALLENGE_STATUS.SUCCESS,
				payload: { ...responseData },
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.UPDATE_CHALLENGE_STATUS.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
