import { generateActions } from "../../utils";

export const GET_DEAL_TYPES = generateActions("GET_DEAL_TYPES");
export const GET_DEALS = generateActions("GET_DEALS");
export const CREATE_DEAL = generateActions("CREATE_DEAL");
export const UPDATE_DEAL = generateActions("UPDATE_DEAL");
export const DELETE_DEAL = generateActions("DELETE_DEAL");
export const UPDATE_DEAL_STATUS = generateActions("UPDATE_DEAL_STATUS");

export const GET_CHALLENGE_TYPES = generateActions("GET_CHALLENGE_TYPES");
export const GET_CHALLENGE_PERIODS = generateActions("GET_CHALLENGE_PERIODS");
export const GET_CHALLENGES = generateActions("GET_CHALLENGES");
export const CREATE_CHALLENGE = generateActions("CREATE_CHALLENGE");
export const UPDATE_CHALLENGE = generateActions("UPDATE_CHALLENGE");
export const DELETE_CHALLENGE = generateActions("DELETE_CHALLENGE");
export const UPDATE_CHALLENGE_STATUS = generateActions(
	"UPDATE_CHALLENGE_STATUS"
);
