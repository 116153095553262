import axios from "./axios";

export default class appointmentService {
	async addAppointment(data) {
		const response = await axios.post(`api/appointment/appointment`, data);
		return response;
	}

	async getAppointment() {
		const response = await axios.get(`api/appointment/appointment`);
		return response;
	}

	async getAppointments() {
		const response = await axios.get(`api/appointment/appointments`);
		return response;
	}

	async updateAppointment(data) {
		const response = await axios.put(`api/appointment/appointment`, data);
		return response;
	}

	async deleteAppointment(id) {
		const response = await axios.delete(`api/appointment/appointment?id=${id}`);
		return response;
	}
}
