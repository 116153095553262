import { generateActions } from "../../utils";

export const GET_USER_INFO = generateActions("GET_USER_INFO");
export const CHECK_OUT_DATA = generateActions("CHECK_OUT_DATA");
export const UPDATE_CART = generateActions("UPDATE_CART");
export const COMPLETE_CASH_ORDER = generateActions("COMPLETE_CASH_ORDER");
export const IMPORTED_PURCHASE_DETAILS = generateActions(
	"IMPORTED_PURCHASE_DETAILS"
);
export const GET_CASHIER_TRANSACTIONS = generateActions(
	"GET_CASHIER_TRANSACTIONS"
);
export const GET_CASHIER_TRANSACTION = generateActions(
	"GET_CASHIER_TRANSACTION"
);
