import React from "react";
import { BsThreeDots } from "react-icons/bs";

export default function DropDownMenu({
	handleClick,
	visibilities,
	index,
	children,
}) {
	return (
		<div className="position-relative">
			<div className="d-flex items-center" style={{ cursor: "pointer" }}>
				<BsThreeDots onClick={() => handleClick(index)} size={24} />
			</div>
			{visibilities[index] ? (
				<div
					className="position-absolute border border-muted px-3 w-32 bg-white"
					style={{ right: "0", top: "100%", zIndex: "2", width: "150px" }}
				>
					{children}
				</div>
			) : (
				""
			)}
		</div>
	);
}
