import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyInfoAsync, patchCompanyInfoAsync } from "../redux/actions";
import Input from "./Input";
import DragDropFile from "./DragDropFile";
import CurrencyFormat from "react-currency-format";

export default function Setup() {
	const dispatch = useDispatch();
	const { companyInfo, loading } = useSelector((state) => state.auth);
	useEffect(() => {
		dispatch(getCompanyInfoAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [setupData, setSetupData] = useState({
		appName: companyInfo.name,
		colorScheme: companyInfo.colorScheme,
		supportEmail: companyInfo.email,
		supportPhoneNumber: companyInfo.phoneNumber,
		referralRewardAmount: companyInfo.referralAmount,
		vat: companyInfo.vat,
		flatDeliveryFee: companyInfo.flatDeliveryFee,
	});
	const [companyLogo, setCompanyLogo] = useState("");

	const onInputchange = (event) => {
		setSetupData({
			...setupData,
			[event.target.name]: event.target.value,
		});
	};

	useEffect(() => {
		setSetupData({
			appName: companyInfo.name,
			colorScheme: companyInfo.colorScheme,
			supportEmail: companyInfo.email,
			supportPhoneNumber: companyInfo.phoneNumber,
			referralRewardAmount: companyInfo.referralAmount,
			vat: companyInfo.vat,
			flatDeliveryFee: companyInfo.flatDeliveryFee,
		});
		setCompanyLogo(companyInfo.coverImage);
	}, [companyInfo]);

	const handleSubmit = (e) => {
		e.preventDefault();
		const {
			appName,
			colorScheme,
			supportEmail,
			supportPhoneNumber,
			referralRewardAmount,
			vat,
			flatDeliveryFee,
		} = setupData;
		const postData = new FormData();
		postData.append("AppName", appName);
		postData.append("CompanyLogo", companyLogo);
		postData.append("ColorScheme", colorScheme);
		postData.append("SupportEmail", supportEmail);
		postData.append("SupportPhoneNumber", supportPhoneNumber);
		postData.append("ReferralRewardAmount", referralRewardAmount);
		postData.append("VAT", vat);
		postData.append("FlatDeliveryFee", flatDeliveryFee);
		dispatch(
			patchCompanyInfoAsync(postData, async (_) => {
				window.location.reload(false);
			})
		);
	};

	return (
		<div className="auth_form" style={{ boxShadow: "unset" }}>
			<div className="auth_form_container">
				<div className="fieldset">
					<label className="label">APP Logo</label>
					<DragDropFile
						image={companyInfo.coverImage}
						handleFile={setCompanyLogo}
					/>
				</div>
				<div className="fieldset">
					<label className="label">APP Name</label>
					<div className="input_box">
						<Input
							type="text"
							value={setupData.appName}
							name="appName"
							onChange={onInputchange}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Color Scheme</label>
					<div className="input_box">
						<Input
							type="text"
							value={setupData.colorScheme}
							name="colorScheme"
							onChange={onInputchange}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Support Email Address</label>
					<div className="input_box">
						<Input
							type="email"
							value={setupData.supportEmail}
							name="supportEmail"
							onChange={onInputchange}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Support Phone Number</label>
					<div className="input_box">
						<Input
							type="tel"
							value={setupData.supportPhoneNumber}
							name="supportPhoneNumber"
							onChange={onInputchange}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Referral Reward Amount</label>
					<div className="input_box">
						<CurrencyFormat
							value={setupData.referralRewardAmount}
							thousandSeparator={true}
							prefix={"₦"}
							onValueChange={(values) => {
								const { formattedValue, value } = values;
								// formattedValue = $2,223
								// value ie, 2223
								setSetupData({
									...setupData,
									referralRewardAmount: value,
								});
							}}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">VAT(%)</label>
					<div className="input_box">
						<Input
							type="number"
							value={setupData.vat}
							name="vat"
							onChange={onInputchange}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Flat Delivery Fee</label>
					<div className="input_box">
						<CurrencyFormat
							value={setupData.flatDeliveryFee}
							thousandSeparator={true}
							prefix={"₦"}
							onValueChange={(values) => {
								const { formattedValue, value } = values;
								setSetupData({
									...setupData,
									flatDeliveryFee: value,
								});
							}}
						/>
					</div>
				</div>
				<div
					className="btn_ btn_orange"
					onClick={!loading ? handleSubmit : () => {}}
				>
					{loading ? (
						<div className="spinner-border" role="status"></div>
					) : (
						"Save Changes"
					)}
				</div>
			</div>
		</div>
	);
}
