import { analyticService } from "../../services";
import * as types from "../types";
import { showErrorMessage } from "./auth.actions";

export const getGrowthAccountingAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_GROWTH_ACCOUNTING.REQUEST });
		try {
			const responseData = await analyticService.getGrowthAccounting();
			dispatch({
				type: types.GET_GROWTH_ACCOUNTING.SUCCESS,
				payload: responseData.data.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_GROWTH_ACCOUNTING.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
