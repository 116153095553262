import React, { useState, useEffect } from "react";

export default function SummaryBox(props) {
	const [value, setValue] = useState(0);
	const [currency, setCurrency] = useState("");
	useEffect(() => {
		if (props.value) {
			if (props.comma) {
				setValue(Number(props.value).toLocaleString());
			} else {
				setValue(props.value);
			}
		}
	}, [props.comma, props.value]);

	useEffect(() => {
		if (props.currency) {
			setCurrency(props.currency);
		}
	}, [props.currency]);
	return (
		<div className='min-w-[100px] h-[35px]'>
			<div className='!text-dmsans text-light-gray3 text-[10px]'>{props.title}</div>
			{typeof value !== "undefined" && typeof props.value !== "undefined" &&
				<div className='text-glow text-[16px] font-300 !text-dmsans'>{currency}{value}</div>
			}
    	</div>
	);
}
