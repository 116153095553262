import { generateActions } from "../../utils";

export const GET_DASHBOARD_DATA = generateActions("GET_DASHBOARD_DATA");
export const GET_REVENUE_DATA = generateActions("GET_REVENUE_DATA");
export const GET_TRANSACTION_DATA = generateActions("GET_TRANSACTION_DATA");
export const CREATE_WALLET = generateActions("CREATE_WALLET");
export const GET_BANK_CODES = generateActions("GET_BANK_CODES");
export const GET_BENEFICIARY_DETAILS = generateActions(
	"GET_BENEFICIARY_DETAILS"
);
export const TRIGGER_WITHDRAW = generateActions("TRIGGER_WITHDRAW");
export const WITHDRAW_DATA = generateActions("WITHDRAW_DATA");
export const UPDATE_DELIVERY_STATUS = generateActions("UPDATE_DELIVERY_STATUS");
export const GET_TRANSACTION_STATUS = generateActions("GET_TRANSACTION_STATUS");
export const ADMIN_UPDATE_ORDER = generateActions("ADMIN_UPDATE_ORDER");
export const ADMIN_CANCEL_ORDER = generateActions("ADMIN_CANCEL_ORDER");
export const ADMIN_COMPLETE_ORDER = generateActions("ADMIN_COMPLETE_ORDER");
