import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Input from "../components/Input";
import { resetPasswordAsync } from "../redux/actions";
import { useParams } from "react-router-dom";

export default function ChangePassword() {
	let { email, token } = useParams();
	const dispatch = useDispatch();
	let navigate = useNavigate();
	const [showPasswords, setShowPasswords] = useState({
		new: false,
		confirm: false,
	});
	const [userData, setUserData] = useState({
		email: "",
		token: "",
		Password: "",
		confirmPassword: "",
	});

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(
			resetPasswordAsync(userData, async (_) => {
				navigate("/");
			})
		);
	};
	useEffect(() => {
		setUserData({ ...userData, email: email, token: token });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [email, token]);
	return (
		<div className="auth_bg">
			<div className="auth_form">
				<div className="auth_form_container">
					<div className="logo"></div>
					<div className="auth_title">Change Password</div>
					<div className="fieldset">
						<label className="label">New Password</label>
						<div className="input_box">
							<Input
								type={showPasswords.new ? "text" : "password"}
								onChange={(e) =>
									setUserData({ ...userData, Password: e.target.value })
								}
							/>
							<span
								className="togglepassword"
								onClick={() =>
									setShowPasswords({
										...showPasswords,
										new: !showPasswords.new,
									})
								}
							>
								{showPasswords.new ? "Hide" : "Show"}
							</span>
						</div>
					</div>

					<div className="fieldset">
						<label className="label">Confirm New Password</label>
						<div className="input_box">
							<Input
								type={showPasswords.confirm ? "text" : "password"}
								onChange={(e) =>
									setUserData({ ...userData, confirmPassword: e.target.value })
								}
							/>
							<span
								className="togglepassword"
								onClick={() =>
									setShowPasswords({
										...showPasswords,
										confirm: !showPasswords.confirm,
									})
								}
							>
								{showPasswords.confirm ? "Hide" : "Show"}
							</span>
						</div>
					</div>

					<div className="btn_ btn_orange" onClick={handleSubmit}>
						CHANGE PASSWORD
					</div>
				</div>
			</div>
		</div>
	);
}
