import * as types from "../types";

const initialState = {
	loading: false,
	error: null,
	whatsapp_logs: [],
};

const MimicReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.GET_WHATSAPP_LOGS.REQUEST:
			return { ...state, loading: true };

		case types.GET_WHATSAPP_LOGS.SUCCESS:
			return { ...state, loading: false, whatsapp_logs: payload };

		case types.GET_WHATSAPP_LOGS.FAILURE:
			return { ...state, loading: false, error: payload };

		default:
			return state;
	}
};

export default MimicReducer;
