import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { chartColors } from './colors';
import { randomColor, transparentize } from '../../utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function ResponsiveBarChartComponent({data}) {
  const [chartData, setChartData] = useState({
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Sample Data',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  });
  const [chartOptions, setChartOptions] = useState({
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: data.title,
      },
    },
  });
  
  function transformData(data) {
    const datasets = data.groups?.map((plot, i) => {
      let tempCoordinates = [];
      plot.bars.map((item) => {
        tempCoordinates.push({
          x: item.tag,
          y: item.frequency,
        })
      });
      const random_Color = randomColor();
      
      return {
        label: plot.key,
        data: tempCoordinates,
        borderColor: chartColors[i] ? chartColors[i] : random_Color,
        backgroundColor: chartColors[i] ? transparentize(chartColors[i], 0.3) : random_Color,
        borderWidth: 2,
        borderRadius: 3,
      }
    });
    let dataSet = {
      labels: data.groups?.[0]?.bars?.map(({ tag }) => tag),
      datasets,
    }
    let options = {
      maintainAspectRatio: true,
      aspectRatio: 2,
      legend: {
        display: true,
      },
      responsive: true,
      plugins: {
        legend: {
          position: 'right',
        },
        title: {
          display: true,
          text: data.title,
          font: {
            family: 'Comic Sans MS',
            size: 22,
            weight: 'bold',
            lineHeight: 1.2,
          },
        },
        tooltip: {
          bodyFontSize: 16,
          bodyFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          titleFontSize: 18,
          // backgroundColor: '#fff',
          // titleFontColor: '#000',
          // bodyFontColor: '#005C01',
          bodySpacing: 10,
          titleSpacing: 9,
          titleMarginBottom: 5,
          caretPadding: 10,
          caretSize: 10,
          displayColors: true,
          // borderColor: '#eee',
          borderWidth: 1,
          xPadding: 5,
          yPadding: 5,
          titleAlign: 'center',
        },
      },
      scales: {
        x: {
          gridLines: {
            drawBorder: false,
          },
          stacked: true,
          title: {
            display: true,
            text: data.xAxisTitle,
            color: '#911',
            font: {
              family: 'Comic Sans MS',
              size: 20,
              weight: 'bold',
              lineHeight: 1.2,
            },
            padding: {top: 20, left: 0, right: 0, bottom: 0}
          },
        },
        y: {
          gridLines: {
            drawBorder: false,
          },
          stacked: true,
          title: {
            display: true,
            text: data.title,
            color: '#191',
            font: {
              family: 'Comic Sans MS',
              size: 20,
              weight: 'bold',
              lineHeight: 1.2,
            },
            padding: {top: 20, left: 0, right: 0, bottom: 10}
          },
          ticks: {
            beginAtZero: true,
            // stepSize: 100,
            // max: 500,
          },
        },
      },
    };
    setChartData(dataSet);
    setChartOptions(options)
  }

  useEffect(() => {
    transformData(data);
  }, [data]);

  return (
    <div className='responsive-chart-wrapper'>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
}

