import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getBankCodesAsync,
	getBeneficiaryDetailsAsync,
	setTriggerWithdrawAsync,
} from "../redux/actions";

const MakeTransfer = ({ handleContinue }) => {
	const { all_banks } = useSelector((state) => state.transaction);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getBankCodesAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const [accountName, setAccountName] = useState("");
	const [childData, setChildData] = useState({
		amount: "",
		receiverBankCode: "",
		receiverAccountNumber: "",
	});

	const handleChange = (event) => {
		setAccountName("");
		const { name, value } = event.target;
		setChildData({
			...childData,
			[name]: value,
		});
	};
	useEffect(() => {
		if (
			childData.receiverBankCode &&
			childData.receiverAccountNumber.length === 10
		) {
			let data = {
				bankCode: childData.receiverBankCode,
				accountNumber: childData.receiverAccountNumber,
			};
			dispatch(
				getBeneficiaryDetailsAsync(data, async (res) => {
					setAccountName(res.name);
					dispatch(setTriggerWithdrawAsync(childData));
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [childData]);

	const handleSubmit = (e) => {
		e.preventDefault();
		handleContinue();
	};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">Make Transfer</div>
			<p className="subtitle text-center">
				Send money from your wallet balance directly to your bank account
			</p>
			<div className="auth_form_container">
				<div className="fieldset">
					<label className="label">Amount</label>
					<div className="input_box">
						<input type="number" name="amount" onChange={handleChange} />
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Bank Name</label>
					<div className="input_box">
						<select name="receiverBankCode" onChange={handleChange}>
							<option disabled>Select Bank</option>
							{all_banks.map((row, index) => {
								return (
									<option value={row.code} key={index}>
										{row.name}
									</option>
								);
							})}
						</select>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Account Number</label>
					<div className="input_box">
						<input
							type="number"
							name="receiverAccountNumber"
							onChange={handleChange}
						/>
					</div>
				</div>
				<span className="accountName">{accountName}</span>

				<div
					className={`btn_ ${childData.amount && accountName ? "btn_orange" : "btn_inactive"}`}
					onClick={childData.amount && accountName ? handleSubmit : () => {}}
				>
					Continue
				</div>
			</div>
		</div>
	);
};

export default MakeTransfer;
