import { resourceService } from "../../services";
import * as types from "../types";
import { showErrorMessage } from "./auth.actions";
import { serviceService } from "../../services";

export const getServicesAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_SERVICE_SERVICES.REQUEST });
		try {
			const responseData = await serviceService.getServices();
			dispatch({
				type: types.GET_SERVICE_SERVICES.SUCCESS,
				payload: responseData,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_SERVICE_SERVICES.FAILURE, payload: err });
			errorCallback?.();
			// showErrorMessage(err);
		}
	};
};

export const getServiceCategoriesAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_SERVICE_CATEGORIES.REQUEST });
		try {
			const responseData = await serviceService.getCategories();
			dispatch({ type: "service_categories_success", payload: responseData });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_SERVICE_CATEGORIES.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};

export const getServicePersonnelAsync = (
	serviceId,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_SERVICE_SERVICE_PERSONNEL.REQUEST });
		try {
			const responseData = await serviceService.getServicePersonnel(serviceId);
			dispatch({
				type: types.GET_SERVICE_SERVICE_PERSONNEL.SUCCESS,
				payload: responseData,
			});
			successCallback?.();
		} catch (err) {
			dispatch({
				type: types.GET_SERVICE_SERVICE_PERSONNEL.FAILURE,
				payload: err,
			});
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};

export const addServiceAsync = (data, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.ADD_SERVICE_SERVICE.REQUEST });
		try {
			await serviceService.addService(data);
			dispatch({ type: types.ADD_SERVICE_SERVICE.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.ADD_SERVICE_SERVICE.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};

export const addServiceCategoryAsync = (
	data,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.ADD_SERVICE_CATEGORY.REQUEST });
		try {
			await serviceService.addCategory(data);
			dispatch({ type: types.ADD_SERVICE_CATEGORY.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.ADD_SERVICE_CATEGORY.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
