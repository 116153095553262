import * as types from "../types";

const initialState = {
	loading: false,
	error: null,
	services: null,
	categories: null,
	service: [],
	category: [],
	personnel: [],
};

const ResourceReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.ADD_SERVICE_SERVICE.REQUEST:
		case types.ADD_SERVICE_CATEGORY.REQUEST:
		case types.DELETE_SERVICE_SERVICE.REQUEST:
		case types.DELETE_SERVICE_CATEGORY.REQUEST:
		case types.UPDATE_SERVICE_SERVICE.REQUEST:
		case types.UPDATE_SERVICE_CATEGORY.REQUEST:
		case types.GET_SERVICE_SERVICES.REQUEST:
		case types.GET_SERVICE_SERVICE.REQUEST:
		case types.GET_SERVICE_SERVICE_PERSONNEL.REQUEST:
		case types.GET_SERVICE_CATEGORY.REQUEST:
		case types.GET_SERVICE_CATEGORIES.REQUEST:
			return { ...state, loading: true };

		case types.ADD_SERVICE_SERVICE.SUCCESS:
		case types.ADD_SERVICE_CATEGORY.SUCCESS:
		case types.DELETE_SERVICE_SERVICE.SUCCESS:
		case types.DELETE_SERVICE_CATEGORY.SUCCESS:
		case types.UPDATE_SERVICE_SERVICE.SUCCESS:
		case types.UPDATE_SERVICE_CATEGORY.SUCCESS:
			return { ...state, loading: false };

		case types.GET_SERVICE_SERVICES.SUCCESS: {
			return { ...state, loading: false, services: payload };
		}
		case "service_categories_success":
			return { ...state, loading: false, categories: payload };

		case types.GET_SERVICE_SERVICE.SUCCESS:
			return { ...state, loading: false, service: payload };

		case types.GET_SERVICE_CATEGORY.SUCCESS:
			return { ...state, loading: false, category: payload };

		case types.GET_SERVICE_SERVICE_PERSONNEL.SUCCESS:
			return { ...state, loading: false, personnel: payload };

		case types.ADD_RESOURCE.FAILURE:
		case types.GET_TABLES.FAILURE:
		case types.GET_PERSONNEL.FAILURE:
		case types.GET_RESOURCE.FAILURE:
		case types.GET_RESOURCES.FAILURE:
		case types.GET_SERVICE_CATEGORIES.FAILURE:
		case types.UPDATE_RESOURCE.FAILURE:
		case types.DELETE_RESOURCE.FAILURE:
			return { ...state, loading: false, error: payload };

		default:
			return state;
	}
};

export default ResourceReducer;
