import React, { useContext, useState, useEffect } from "react";
import { mainFunctions } from "../providers/MainProvider";
import { MdCalendarViewWeek } from "react-icons/md";
import CheckInCheckOut from "../components/CheckInCheckOut";
import { convertNumberToDateTime } from "../utils";
import DayTimeline from "../components/DayTimeline2";
import { addResourceAsync, getPersonnelAsync } from "../redux/actions";
import { useDispatch } from "react-redux";

const EditResourceAvailability = (props) => {
	const dispatch = useDispatch();
	const timeType = 48;
	const { setShowModal } = useContext(mainFunctions);

	const [resourceAvailability, setresourceAvailability] = useState({});

	const [totalTimeWeekend, setTotalTimeWeekend] = useState(0);
	const [totalTimeWeekday, setTotalTimeWeekday] = useState(0);
	const [timeAvailable, setTimeAvailable] = useState(0);
	const updateResourceAvailabilty = async (name, value) => {
		let r = { ...resourceAvailability };
		r[name] = value;
		await setresourceAvailability(r);
	};

	const toggleAllAvailabilityWeekday = async () => {
		let a = resourceAvailability.monday;
		await setresourceAvailability({
			...resourceAvailability,
			monday: !a,
			tuesday: !a,
			wednesday: !a,
			thursday: !a,
			friday: !a,
		});
	};

	const toggleAllAvailabilityWeekend = async () => {
		let a = resourceAvailability.saturday;
		await setresourceAvailability({
			...resourceAvailability,
			saturday: !a,
			sunday: !a,
		});
	};
	const editResource = async () => {
		let weekdaysCheckIn_ = Number(resourceAvailability.weekdaysCheckIn) / 2;
		let weekdaysCheckOut_ = Number(resourceAvailability.weekdaysCheckOut) / 2;
		let weekendCheckIn_ = Number(resourceAvailability.weekendCheckIn) / 2;
		let weekendCheckOut_ = Number(resourceAvailability.weekendCheckOut) / 2;
		let weekdaysBreakTimeStart =
			Number(resourceAvailability.weekDaysBreakTimeStart) / 2;
		let weekdaysBreakTimeEnd =
			Number(resourceAvailability.weekDaysBreakTimeEnd) / 2;
		let weekendBreakTimeStart =
			Number(resourceAvailability.weekendBreakTimeStart) / 2;
		let weekendBreakTimeEnd =
			Number(resourceAvailability.weekendBreakTimeEnd) / 2;
		let data;

		if (props.thisresource.discriminator === "TableResource") {
			data = `{'tableName':'${props.thisresource.tableName}','noOfSeats':'${props.thisresource.noOfSeats}','available':'${props.thisresource.available}','description':'${props.thisresource.description}','attachedPersonnel':'${props.thisresource.attachedPersonnel}','tableCost':'${props.thisresource.tableCost}','resourceAvailability':{'monday':${resourceAvailability.monday},'tuesday':${resourceAvailability.tuesday},'wednesday':${resourceAvailability.wednesday},'thursday':${resourceAvailability.thursday},'friday':${resourceAvailability.friday},'saturday':${resourceAvailability.saturday},'sunday':${resourceAvailability.sunday},'weekdaysCheckIn':${weekdaysCheckIn_},'weekdaysCheckOut':${weekdaysCheckOut_},'weekendCheckIn':${weekendCheckIn_},'weekendCheckOut':${weekendCheckOut_},'weekDaysBreakTimeStart': ${weekdaysBreakTimeStart},'weekDaysBreakTimeEnd':${weekdaysBreakTimeEnd},'weekendBreakTimeStart':${weekendBreakTimeStart},'weekendBreakTimeEnd':${weekendBreakTimeEnd}}}`;
		} else {
			data = `{'firstName':'${props.thisresource.firstName}','lastName':'${props.thisresource.lastName}','phoneNumber':'${props.thisresource.phoneNumber}','services':[{'serviceId':${props.thisresource.services}}],'resourceAvailability':{'monday':${resourceAvailability.monday},'tuesday':${resourceAvailability.tuesday},'wednesday':${resourceAvailability.wednesday},'thursday':${resourceAvailability.thursday},'friday':${resourceAvailability.friday},'saturday':${resourceAvailability.saturday},'sunday':${resourceAvailability.sunday},'weekdaysCheckIn':${weekdaysCheckIn_},'weekdaysCheckOut':${weekdaysCheckOut_},'weekendCheckIn':${weekendCheckIn_},'weekendCheckOut':${weekendCheckOut_},'weekDaysBreakTimeStart': ${weekdaysBreakTimeStart},'weekDaysBreakTimeEnd':${weekdaysBreakTimeEnd},'weekendBreakTimeStart':${weekendBreakTimeStart},'weekendBreakTimeEnd':${weekendBreakTimeEnd}},'walkIn':${props.thisresource.walkin},'homeService':${props.thisresource.homeservice}}`;
		}

		// dispatch(
		//     addResourceAsync(
		//         {
		//             resourceType: 1,
		//             data: data
		//         }
		//         , async (_) => {
		//         dispatch(
		//             getPersonnelAsync()
		//         );
		//         setShowModal(false);
		//     })
		// );
	};

	const savePersonnel = async () => {};

	const calcHours = () => {
		let tt = 0;
		resourceAvailability.monday ? (tt = tt + totalTimeWeekday) : (tt = tt);
		resourceAvailability.tuesday ? (tt = tt + totalTimeWeekday) : (tt = tt);
		resourceAvailability.wednesday ? (tt = tt + totalTimeWeekday) : (tt = tt);
		resourceAvailability.thursday ? (tt = tt + totalTimeWeekday) : (tt = tt);
		resourceAvailability.friday ? (tt = tt + totalTimeWeekday) : (tt = tt);
		resourceAvailability.saturday ? (tt = tt + totalTimeWeekend) : (tt = tt);
		resourceAvailability.sunday ? (tt = tt + totalTimeWeekend) : (tt = tt);

		setTimeAvailable(tt);
	};

	useEffect(() => {
		calcHours();
	}, [
		totalTimeWeekday,
		totalTimeWeekend,
		resourceAvailability.monday,
		resourceAvailability.tuesday,
		resourceAvailability.wednesday,
		resourceAvailability.thursday,
		resourceAvailability.friday,
		resourceAvailability.saturday,
		resourceAvailability.sunday,
	]);

	useEffect(() => {
		console.log(props.thisresource);
	}, [props]);

	useEffect(() => {
		console.log(
			"resourceAvailability",
			resourceAvailability,
			props.thisresource.resourceAvailability
		);

		if (
			typeof resourceAvailability.id === "undefined" &&
			typeof props.thisresource !== "undefined"
		) {
			console.log(
				"resourceAvailability",
				props.thisresource.resourceAvailability
			);
			setresourceAvailability(props.thisresource.resourceAvailability);
		}
	}, [props]);

	return (
		<div>
			<div className="title">Edit Resource Availability</div>
			<p className="subtitle text-center"></p>
			<div className="auth_form_container">
				<div className="fieldset">
					<label className="label">Weekdays Resource Time (hours)</label>
					<div className="input_box">
						<div>{timeAvailable} hours</div>
						<MdCalendarViewWeek size={22} />
					</div>
				</div>
				<div className="divider_box">
					<span className="text">Weekdays Availability</span>
					<div className="line"></div>
				</div>
				<div className="flex_field">
					<div className="combofield">
						<div
							className={`combobox
                            ${
															resourceAvailability.monday &&
															resourceAvailability.tuesday &&
															resourceAvailability.wednesday &&
															resourceAvailability.thursday &&
															resourceAvailability.friday
																? " active"
																: ""
														}`}
							onClick={async () => {
								await toggleAllAvailabilityWeekday();
							}}
						></div>
						<div className={`text`}>All Weekdays</div>
					</div>
					<div className="combofield">
						<div
							className={`combobox ${resourceAvailability.monday === true ? "active" : ""}`}
							onClick={() => {
								updateResourceAvailabilty(
									"monday",
									!resourceAvailability.monday
								);
							}}
						></div>
						<div className="text">Monday</div>
					</div>
					<div className="combofield">
						<div
							className={`combobox ${resourceAvailability.tuesday ? "active" : ""}`}
							onClick={() => {
								updateResourceAvailabilty(
									"tuesday",
									!resourceAvailability.tuesday
								);
							}}
						></div>
						<div className="text">Tuesday</div>
					</div>

					<div className="combofield">
						<div
							className={`combobox ${resourceAvailability.wednesday ? "active" : ""} `}
							onClick={() => {
								updateResourceAvailabilty(
									"wednesday",
									!resourceAvailability.wednesday
								);
							}}
						></div>
						<div className="text">Wednesday</div>
					</div>
					<div className="combofield">
						<div
							className={`combobox ${resourceAvailability.thursday ? "active" : ""}`}
							onClick={() => {
								updateResourceAvailabilty(
									"thursday",
									!resourceAvailability.thursday
								);
							}}
						></div>
						<div className="text">Thursday</div>
					</div>
					<div className="combofield">
						<div
							className={`combobox ${resourceAvailability.friday ? "active" : ""}`}
							onClick={() => {}}
						></div>
						<div className="text">Friday</div>
					</div>
				</div>

				<CheckInCheckOut
					setCheckIn={(time) =>
						updateResourceAvailabilty("weekdaysCheckIn", time)
					}
					setCheckOut={(time) =>
						updateResourceAvailabilty("weekdaysCheckOut", time)
					}
					setBreakStart={(time) =>
						updateResourceAvailabilty("weekDaysBreakTimeStart", time)
					}
					setBreakEnd={(time) =>
						updateResourceAvailabilty("weekDaysBreakTimeEnd", time)
					}
					setTotalTime={setTotalTimeWeekday}
					timeType={timeType}
				/>

				<DayTimeline
					checkInTime={resourceAvailability.weekdaysCheckIn}
					checkOutTime={resourceAvailability.weekdaysCheckOut}
					breakTimeStart={resourceAvailability.weekDaysBreakTimeStart}
					breakTimeEnd={resourceAvailability.weekDaysBreakTimeEnd}
					timeType={timeType}
				/>

				<div className="divider_box">
					<span className="text">Weekends Availability</span>
					<div className="line"></div>
				</div>

				<div className="flex_field">
					<div className="combofield">
						<div
							className={`combobox
                        ${
													resourceAvailability.saturday &&
													resourceAvailability.sunday
														? "active"
														: ""
												}
                        `}
							onClick={async () => {
								await toggleAllAvailabilityWeekend();
							}}
						></div>
						<div className="text">All Weekends</div>
					</div>
					<div className="combofield">
						<div
							className={`combobox ${resourceAvailability.saturday ? "active" : ""}`}
							onClick={() => {
								updateResourceAvailabilty(
									"saturday",
									!resourceAvailability.saturday
								);
							}}
						></div>
						<div className="text">Saturday</div>
					</div>
					<div className="combofield">
						<div
							className={`combobox ${resourceAvailability.sunday ? "active" : ""}`}
							onClick={() => {
								updateResourceAvailabilty(
									"sunday",
									!resourceAvailability.sunday
								);
							}}
						></div>
						<div className="text">Sunday</div>
					</div>
				</div>

				<CheckInCheckOut
					setCheckIn={(time) =>
						updateResourceAvailabilty("weekendCheckIn", time)
					}
					setCheckOut={(time) =>
						updateResourceAvailabilty("weekendCheckOut", time)
					}
					setBreakStart={(time) =>
						updateResourceAvailabilty("weekendBreakTimeStart", time)
					}
					setBreakEnd={(time) =>
						updateResourceAvailabilty("weekendBreakTimeEnd", time)
					}
					setTotalTime={setTotalTimeWeekend}
					timeType={timeType}
				/>
				<DayTimeline
					checkInTime={resourceAvailability.weekendCheckIn}
					checkOutTime={resourceAvailability.weekendCheckOut}
					breakTimeStart={resourceAvailability.weekendBreakTimeStart}
					breakTimeEnd={resourceAvailability.weekendBreakTimeEnd}
					timeType={timeType}
				/>

				<div
					className="btn_ btn_orange"
					onClick={() => {
						if (typeof props.thisresource !== "undefined") {
							editResource();
						} else {
							savePersonnel();
						}
					}}
				>
					{typeof props.thisresource !== "undefined" ? "Add User" : "Save User"}
				</div>
			</div>
		</div>
	);
};

export default EditResourceAvailability;
