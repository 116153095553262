import React, { useContext, useState, useEffect, useCallback } from "react";
import dateFormat from "dateformat";
import { useDispatch, useSelector } from "react-redux";
import ImageUploadPreview from "../components/ImageUploadPreview";
import { mainFunctions } from "../providers/MainProvider";
import {
	addAppointmentAsync,
	getAppointmentsAsync,
	getPersonnelAsync,
	getResourcesAsync,
	getTablesAsync,
	updateAppointmentAsync,
} from "../redux/actions";
import {
	convertNumberToDateTime,
	filterResourcesByServiceId,
	showToast,
} from "../utils";
import { Calendar } from "@natscale/react-calendar";
import "@natscale/react-calendar/dist/main.css";
import TimelineEvent from "../components/TimelineEvent";
import { tab } from "@testing-library/user-event/dist/tab";

const EditAppointment_AssignResource = (props) => {
	const { personnel } = useSelector((state) => state.resource);
	const { resources } = useSelector((state) => state.resource);
	const { tables } = useSelector((state) => state.resource);
	const [allPersonnel, setAllPersonnel] = useState([]);
	const [filteredPersonnel, setFilteredPersonnel] = useState([]);
	const [allTables, setAllTables] = useState([]);
	const [allResources, setAllResources] = useState([]);
	const [resourceId, setResourceId] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);
	const [appointmentDay, setAppointmentDay] = useState(null);
	const [appointmentTime, setAppointmentTime] = useState(null);
	const { appointmentData, setAppointmentData, onChangeValue } = props;
	const [resourceAvailability, setresourceAvailability] = useState([]);
	const [activeHours, setActiveHours] = useState({
		start: 5.5,
		end: 15,
	});

	const dispatch = useDispatch();

	const { setShowModal } = useContext(mainFunctions);
	const handleSubmit = async (e) => {};

	const [values, setValues] = useState([
		"2022-10-22",
		"2022-10-01",
		"2022-10-17",
		"2022-10-18",
	]);

	const [value, setValue] = useState(new Date());
	const [formattedvalue, setFormattedValue] = useState("");

	const onChangeCalendar = (val) => {
		setValue(val);
		let day_ = `${dateFormat(val, "yyyy-mm-d")}T00:00:48.638Z`;
		setAppointmentDay(day_);
		setSelectedDate(val);
	};
	const isHighlight = useCallback((date) => {
		return false;
	}, []);

	const isDisabled = (date) => {
		if (typeof resourceAvailability.monday !== "undefined") {
			let dd = date.getTime();
			const d = new Date();
			let now = d.getTime() - 86400000;

			if (now > dd) {
				return true;
			} else if (date.getDay() === 1 && resourceAvailability.monday) {
				return false;
			} else if (date.getDay() === 2 && resourceAvailability.tuesday) {
				return false;
			} else if (date.getDay() === 3 && resourceAvailability.wednesday) {
				return false;
			} else if (date.getDay() === 4 && resourceAvailability.thursday) {
				return false;
			} else if (date.getDay() === 5 && resourceAvailability.friday) {
				return false;
			} else if (date.getDay() === 6 && resourceAvailability.saturday) {
				return false;
			} else if (date.getDay() === 0 && resourceAvailability.sunday) {
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	};

	useEffect(() => {
		if (value !== null) {
			setFormattedValue(value.toISOString().slice(0, 10));
		}
	}, [value]);

	useEffect(() => {
		if (typeof personnel.data !== "undefined") {
			if (typeof personnel.data.data !== "undefined") {
				setAllPersonnel(personnel.data.data);
			}
		}
	}, [personnel]);

	useEffect(() => {
		if (
			typeof appointmentData.serviceId !== "undefined" &&
			appointmentData.serviceId !== null
		) {
			let fp = filterResourcesByServiceId(
				allPersonnel,
				appointmentData.serviceId
			);
			setFilteredPersonnel(fp);
		}
	}, [allPersonnel, appointmentData]);

	useEffect(() => {
		if (typeof tables.data !== "undefined") {
			if (typeof tables.data.data !== "undefined") {
				setAllTables(tables.data.data);
			}
		}
	}, [tables]);

	useEffect(() => {
		if (typeof resources.data !== "undefined") {
			if (typeof resources.data.data !== "undefined") {
				setAllResources(resources.data.data);
			}
		}
	}, [resources]);

	useEffect(() => {
		dispatch(getResourcesAsync());
	}, []);

	useEffect(() => {
		dispatch(getPersonnelAsync());
		dispatch(getTablesAsync());
	}, []);

	const submitAppointment = () => {
		if (props.appointmentData.resourceType === 1) {
			dispatch(
				updateAppointmentAsync(
					{
						resourceType: props.appointmentData.resourceType,
						resourceId: resourceId,
						customerName: props.appointmentData.customerName,
						numberOfGuest: 4,
						appointmentTime: appointmentTime,
						appointmentDay: appointmentDay,
						paymentMethod: props.appointmentData.paymentMethod,
						customerEmail: props.appointmentData.customerEmail,
						customerPhoneNumber: props.appointmentData.customerPhoneNumber,
						serviceId:
							props.appointmentData.serviceId !== null
								? props.appointmentData.serviceId
								: 4,
						id: props.appointmentData.id,
					},
					async (_) => {
						dispatch(getAppointmentsAsync());
						setShowModal(false);
					}
				)
			);
		} else {
			dispatch(
				updateAppointmentAsync(
					{
						resourceType: props.appointmentData.resourceType,
						customerName: props.appointmentData.customerName,
						appointmentTime: appointmentTime,
						appointmentDay: appointmentDay,
						numberOfGuest: 4,
						paymentMethod: props.appointmentData.paymentMethod,
						customerEmail: props.appointmentData.customerEmail,
						customerPhoneNumber: props.appointmentData.customerPhoneNumber,
						resourceId: resourceId,
						serviceId:
							props.appointmentData.serviceId !== null
								? props.appointmentData.serviceId
								: 4,
						id: props.appointmentData.id,
					},
					async (_) => {
						dispatch(getAppointmentsAsync());
						setShowModal(false);
					}
				)
			);
		}
	};

	useEffect(() => {
		let day_ = `${dateFormat(value, "yyyy-mm-dd")}T00:00:48.638Z`;
		setAppointmentDay(day_);
	}, [value]);

	useEffect(() => {
		if (typeof props.appointmentData !== "undefined") {
			setResourceId(props.appointmentData.resourceId);
		}
	}, [props]);

	useEffect(() => {
		filteredPersonnel.forEach((thisperson) => {
			if (resourceId === thisperson.id) {
				setresourceAvailability(thisperson.resourceAvailability);
				return true;
			}
		});
	}, [filteredPersonnel, resourceId]);

	useEffect(() => {
		if (selectedDate !== null) {
			let d = selectedDate.getDay();
			let m;
			if (d > 0 && d < 6) {
				m = {
					start: resourceAvailability.weekdaysCheckIn,
					end: resourceAvailability.weekdaysCheckOut,
					breakstart: resourceAvailability.weekDaysBreakTimeStart,
					breakend: resourceAvailability.weekDaysBreakTimeEnd,
				};
			} else {
				m = {
					start: resourceAvailability.weekendCheckIn,
					end: resourceAvailability.weekendCheckOut,
					breakstart: resourceAvailability.weekendBreakTimeStart,
					breakend: resourceAvailability.weekendBreakTimeEnd,
				};
			}
			setAppointmentTime(m.start);
			setActiveHours(m);
		}
	}, [resourceAvailability, selectedDate]);
	return (
		// <div className='w-100 h-100 d-flex flex-column align-items-center add_staff_modal'>
		<div className="d-flex flex-column align-items-center add_staff_modal">
			<div className="title">Assign Resources</div>
			<p className="subtitle text-center"></p>
			<div className="calendar_container auth_form_container">
				{filteredPersonnel.map((thisperson, i) => {
					return (
						<div className="resource_widget" key={i}>
							<div className="avatar"></div>
							<div className="details">
								<div className="resource_name">
									<div className="name">
										{thisperson.firstName} {thisperson.lastName}
									</div>
								</div>
							</div>
							<div className="action">
								<div
									className="radiofield"
									onClick={() => {
										setResourceId(thisperson.id);
									}}
								>
									<div
										className={`radiobox ${thisperson.id === resourceId ? "active" : ""}`}
									></div>
								</div>
							</div>
						</div>
					);
				})}

				{resourceId !== null && (
					<center>
						<Calendar
							isHighlight={isHighlight}
							isDisabled={isDisabled}
							value={value}
							onChange={onChangeCalendar}
							size={310}
						/>

						<div className="fieldset">
							<label className="label">Booking Time</label>
							<div className="input_box">
								<select
									value={appointmentTime}
									onChange={(e) => {
										setAppointmentTime(e.target.value);
									}}
								>
									<option disabled>Select a time for the resource</option>
									{[
										...Array(
											Number(activeHours.end) * 2 -
												Number(activeHours.start) * 2
										),
									].map((thistime, index) => {
										let t = Number(activeHours.start) * 2 + index;
										let v = convertNumberToDateTime(t, 48);
										return (
											<option value={t} key={t}>
												{v}
											</option>
										);
									})}
								</select>
							</div>
						</div>
					</center>
				)}
				<div>
					<div className="btn_ btn_orange" onClick={() => submitAppointment()}>
						Update Appointment
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditAppointment_AssignResource;
