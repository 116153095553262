import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mainFunctions } from "../providers/MainProvider";
import {
	addServiceCategory,
	addServiceCategoryAsync,
	getCategoriesAsync,
	getServiceCategoriesAsync,
} from "../redux/actions";

const AddStaffModal = () => {
	const dispatch = useDispatch();
	const { services, categories } = useSelector((state) => state.service);
	const [category, setCategory] = useState({
		name: "",
		description: "",
		resources: [0],
	});

	const { setShowModal } = useContext(mainFunctions);

	const [userId, setUserId] = useState("");
	const updateCategory = (name, value) => {
		setCategory({
			...category,
			[name]: value,
		});
	};
	const handleSubmit = () => {
		dispatch(
			addServiceCategoryAsync(category, async (_) => {
				dispatch(getServiceCategoriesAsync());
				setShowModal(false);
			})
		);
	};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">Add Category</div>
			<p className="subtitle text-center"></p>
			<div className="auth_form_container">
				<div className="fieldset">
					<label className="label">Category Name</label>
					<div className="input_box">
						<input
							type="text"
							onChange={(e) => updateCategory("name", e.target.value)}
						></input>
					</div>
				</div>

				<div className="fieldset">
					<label className="label">Description</label>
					<div className="input_box">
						<textarea
							onChange={(e) => updateCategory("description", e.target.value)}
						></textarea>
					</div>
				</div>

				<div
					className="btn_ btn_orange"
					onClick={() => {
						handleSubmit();
					}}
				>
					{" "}
					Add Category
				</div>
			</div>
		</div>
	);
};

export default AddStaffModal;
