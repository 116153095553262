import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { mainFunctions } from "../providers/MainProvider";
import {
	deleteResourceAsync,
	getPersonnelAsync,
	getResourcesAsync,
	getTablesAsync,
} from "../redux/actions";

const DeleteResources = (props) => {
	const dispatch = useDispatch();
	const { setShowModal } = useContext(mainFunctions);
	const handleSubmit = () => {
		dispatch(
			deleteResourceAsync(props.row.id, async (_) => {
				dispatch(getResourcesAsync());
				dispatch(getPersonnelAsync());
				dispatch(getTablesAsync());
				setShowModal(false);
				if (typeof props.goBack === "function") {
					props.goBack();
				}
			})
		);
	};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">Delete Resource</div>
			<p className="subtitle text-center">
				Are you sure you want to delete this resource?
			</p>
			<p className="subtitle text-center">
				<b></b>
			</p>
			<div className="auth_form_container">
				<div className="btn_ btn_orange" onClick={handleSubmit}>
					Delete Resource
				</div>
			</div>
		</div>
	);
};

export default DeleteResources;
