/* eslint-disable */
import axios from "axios";
import { _getTokenFromStorage } from "../utils";

const instance = axios.create({
	withCredentials: true,
	baseURL: process.env.REACT_APP_BACKEND_BASEURL,
	mode: "cors",
	headers: {
		"Content-Type": "application/json",
		"Access-Control-Allow-Methods": "*",
		"Access-Control-Allow-Origin": process.env.REACT_APP_BACKEND_BASEURL,
	},
});
instance.interceptors.request.use(
	(config) => {
		const token = _getTokenFromStorage("accessToken");
		// const token = "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjI5NzVhZWQzLWM4NzMtNDAzYy1hMGFjLWNhZGQ4MzE1YzIxMyIsIlJvbGUiOiJTdXBlckFkbWluaXN0cmF0b3IiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiaW5mb0ByaW5nbmJyaW5nLmNvbSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2VtYWlsYWRkcmVzcyI6ImluZm9AcmluZ25icmluZy5jb20iLCJVc2VyTmFtZSI6ImluZm9AcmluZ25icmluZy5jb20iLCJGaXJzdE5hbWUiOiJFcmljIiwiTGFzdE5hbWUiOiJLYXlzZXIiLCJleHAiOjE3MTM5NzAyNTYsImlzcyI6Iml6aWZpbi5jb20iLCJhdWQiOiJpemlmaW4uY29tIn0.awTl6Fat1oQm6GNtvYk7V5np00hbSvhALJpjY6d02Yw";
		if (token) {
			config.headers["Authorization"] = "Bearer " + token;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

export default instance;
