import React, { useContext, useEffect, useState } from "react";
import { mainFunctions } from "../../providers/MainProvider";

import Sidebar from "../../components/Sidebar";
import TopBar from "../../components/TopBar";
import SummaryBox from "../../components/SummaryBox";
import Title from "../../components/Title";
import { Link } from "react-router-dom";
import { useGuard } from "../../hooks/useGuard";
import { useDispatch, useSelector } from "react-redux";
import { getCustomersAsync } from "../../redux/actions";
import Tables from "../../components/Tables";
import { formatToCurrency, getDateTimeFormatUK } from "../../utils";
import { BsThreeDots } from "react-icons/bs";
import EditCustomerDetails from "../../modals/EditCustomerDetails";
import AnalyticsWrapper from "../../components/AnalyticsWrapper";
import Divider from "../../components/Divider";

export default function Customers() {
	useGuard();
	const dispatch = useDispatch();
	const { customers, totalCustomers, activeCustomerToday } = useSelector(
		(state) => state.user
	);
	useEffect(() => {
		dispatch(getCustomersAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { setShowModal, setModalData } = useContext(mainFunctions);

	const tableColumns = [
		{
			title: "ID",
			dataIndex: "id",
			sort: true,
		},
		{
			title: "Name",
			dataIndex: "name",
			sort: true,
		},
		// {
		//   title: "Email",
		//   dataIndex: "email",
		//   sort: true,
		// },
		{
			title: "Total Purchases",
			dataIndex: "totalPurchase",
			sort: true,
		},
		{
			title: "Purchase Value",
			dataIndex: "purchaseValue",
			sort: true,
		},
		{
			title: "Rewards Value",
			dataIndex: "rewardsValue",
			sort: true,
		},
		{
			title: "Last Visit",
			dataIndex: "lastVisit",
			sort: true,
			search: false,
		},
		{
			title: "",
			dataIndex: "option",
		},
		{
			title: "",
			dataIndex: "firstName",
			display: false,
		},
		{
			title: "",
			dataIndex: "lastName",
			display: false,
		},
		{
			title: "",
			dataIndex: "userName",
			display: false,
		},
	];

	const [filteredTableData, setFilteredTableData] = useState(customers);

	const [visibilities, setVisibilities] = useState(() =>
		filteredTableData.map((x) => false)
	);

	const handleClick = (index) => {
		const newVisibilities = [...visibilities];
		newVisibilities.map((thisVisibility, ind) => {
			index !== ind
				? (newVisibilities[ind] = false)
				: (newVisibilities[index] = !newVisibilities[index]);
			return 0;
		});
		setVisibilities(newVisibilities);
	};

	const dataSource =
		filteredTableData && filteredTableData.length > 0
			? filteredTableData.map((row, index) => {
					return {
						id: (
							<div>{row?.userId?.substring(0, row?.userId?.indexOf("-"))}</div>
						),
						name: (
							<div className="text-glow">
								<Link to={`/customers/${row.userId}`}>
									{row.firstName} {row.lastName}
								</Link>
							</div>
						),
						email: <div>{row.userName}</div>,
						totalPurchase: <div>{row.totalPurchases}</div>,
						purchaseValue: <div>₦{formatToCurrency(row.purchaseValue, 2)}</div>,
						rewardsValue: <div>₦{formatToCurrency(row.rewardValue, 2)}</div>,
						lastVisit: <div>{getDateTimeFormatUK(row.lastLogIn)}</div>,
						option: (
							<div className="">
								<div className="position-relative">
									<div
										className="d-flex items-center"
										style={{ cursor: "pointer" }}
									>
										<BsThreeDots onClick={() => handleClick(index)} size={24} />
									</div>
									{visibilities[index] ? (
										<div
											className="position-absolute border border-muted px-3 py-2 w-32 bg-dark-gray2 rounded-md"
											style={{
												right: "0",
												top: "100%",
												zIndex: "2",
												width: "150px",
											}}
										>
											<div
												onClick={async () => {
													setModalData(<EditCustomerDetails user={row} />);
													setShowModal(true);
												}}
												style={{ cursor: "pointer" }}
												className="d-flex text-left text-white hover:text-glow text-md "
											>
												Edit Details
											</div>
										</div>
									) : (
										""
									)}
								</div>
							</div>
						),
						firstName: row.firstName,
						lastName: row.lastName,
						userName: row.userName,
					};
				})
			: [];

	useEffect(() => {
		setFilteredTableData(customers);
		setVisibilities(() => customers.map((x) => false));
	}, [customers]);
	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar title="Customers" />
				<div className="mainbar-container">
					<Title title="Overview" />
					<AnalyticsWrapper>
						<SummaryBox
							title={"Total No of Customers"}
							value={totalCustomers}
						/>
						{/* <SummaryBox 
                title={"Active No of Customers"} 
                value={activeCustomerPerDay}/> */}
						<Divider/>
						<SummaryBox
							title={"Active Customers Today"}
							value={activeCustomerToday}
						/>
					</AnalyticsWrapper>

					<Tables
						title="All Customers"
						dataSource={dataSource}
						columns={tableColumns}
						handleSearch={true}
						showPagination={true}
						showPageSize={true}
						source={customers}
						setFilteredTableData={setFilteredTableData}
					/>
				</div>
			</div>
		</div>
	);
}
