import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { mainFunctions } from "../providers/MainProvider";
const SetupCompany = () => {
	const navigate = useNavigate();
	const { setShowModal } = useContext(mainFunctions);

	const handleRedirect = () => {
		setShowModal(false);
		return navigate("/account");
	};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">Application Setup</div>
			<p className="subtitle text-center">
				You are required to Setup your Merchant Application to continue.
			</p>
			<div className="auth_form_container">
				<div className="btn_ btn_orange" onClick={handleRedirect}>
					Account Setup
				</div>
			</div>
		</div>
	);
};

export default SetupCompany;
