import React, { useState, useCallback } from "react";
import dateFormat from "dateformat";
import { Calendar } from "@natscale/react-calendar";
import "@natscale/react-calendar/dist/main.css";

const resources = [
	{
		resource: "Precious Jameson",
		booking_time: "10:00am",
		service: "hairdressing",
	},
	{
		resource: "Precious Jameson",
		booking_time: "10:00am",
		service: "hairdressing",
	},
];

const CalendarViewAppointments = () => {
	const [value, setValue] = useState(new Date());
	const onChangeCalendar = useCallback(
		(val) => {
			setValue(val);
		},
		[setValue]
	);

	const isHighlight = useCallback((date) => {
		// highlight any data that is divisible by 5
		if (date.getDate() % 5 === 0) {
			return true;
		}
	}, []);

	const isDisabled = useCallback((date) => {
		// disable wednesdays and any date that is divisible by 5
		if (date.getDay() === 3 || date.getDate() % 5 === 0) {
			return true;
		}
	}, []);

	return (
		// <div className='w-100 h-100 d-flex flex-column align-items-center add_staff_modal'>
		<div className="d-flex flex-column align-items-center add_staff_modal">
			<div className="title">Calender View</div>
			<p className="subtitle text-center"></p>
			<div className="calendar_container auth_form_container">
				<div className="fieldset">
					<label className="label">Resources</label>
					<div className="input_box">
						<select>
							<option>Please Select</option>
							<option>Saburi</option>
							<option>Neville</option>
						</select>
					</div>
				</div>

				<div className="selected_options">
					<div className="selected_option">
						Saburi
						<span className="close"></span>
					</div>
					<div className="selected_option">
						Neville
						<span className="close"></span>
					</div>
				</div>
				<center>
					<Calendar
						isHighlight={isHighlight}
						isDisabled={isDisabled}
						value={value}
						onChange={onChangeCalendar}
						size={310}
					/>
				</center>
				<div>
					<h4>Appointments for {dateFormat(value, "ddd, dS mmm, yyyy")}</h4>
					<div className="table mt-2">
						<table>
							<thead>
								<tr>
									<th></th>
									<th scope="column">Resource</th>
									<th scope="column">Booking Time</th>
									<th scope="column">Service</th>
								</tr>
							</thead>
							<tbody>
								{resources.map((this_resource, index) => {
									return (
										<tr key={index}>
											<th scope="row">{index + 1}</th>
											<td>{this_resource.resource}</td>
											<td>{this_resource.booking_time}</td>
											<td>{this_resource.service}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CalendarViewAppointments;
