import React from "react";
import { formatToCurrency } from "../utils";

const PurchaseDetailsModal = ({
	confirmPurchase,
	deletePurchase,
	checkPaymentStatus,
	data,
}) => {
	const getTotal = () => {
		let total = 0;
		if (data) total = data.checkoutData.totalAmount + data.checkoutData.vat;
		return total;
	};
	const handleDelete = (e) => {
		e.preventDefault();
		deletePurchase(data);
	};
	const handlePaymentStatus = (e) => {
		e.preventDefault();
		checkPaymentStatus(data);
	};
	return (
		<div className="purchase_details_modal w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">Purchase Details</div>
			<p className="subtitle text-center"></p>
			<div className="pt-3 pb-0 w-100">
				<div className="total_amount rounded mx-5 p-2">
					<div className="total_title">Total Amount</div>
					<div className="total_value">₦{formatToCurrency(getTotal(), 2)}</div>
				</div>
				<div className="d-flex justify-content-center mt-4">
					{data.checkoutData.byetCode && (
						<img
							src={`data:image/png;base64,${data.checkoutData.byetCode}`}
							alt="barcode"
						/>
					)}
				</div>
				<div className="total_amount rounded p-2">
					<div className="total_title text-center">Purchase Code</div>
					<div className="total_value text-center">
						{data.checkoutData.purchaseCode}
					</div>
				</div>
				<div className="mt-5 w-100 d-flex align-items-center flex-column">
					<div
						className="btn_ btn_orange px-5 mb-2"
						style={{ width: "300px" }}
						onClick={handlePaymentStatus}
					>
						Check Payment Status
					</div>
					<div
						className="btn_ btn_green px-5"
						style={{ width: "300px" }}
						onClick={() => confirmPurchase(data)}
					>
						Confirm Manual Payment
					</div>
					{/* <p 
            className='text-danger cursor-pointer mt-3'
            onClick={handleDelete}
          >Payment Not Made</p> */}
				</div>
			</div>
		</div>
	);
};

export default PurchaseDetailsModal;
