import React, { useState, useEffect } from "react";
import "../assets/timeLine.css";
export default function DayTimeline({
	checkInTime,
	checkOutTime,
	breakTimeStart,
	breakTimeEnd,
	timeType,
}) {
	const [timetype, setTimetype] = useState(24);
	const [activeStart, setActiveStart] = useState(0);
	const [activeLength, setActiveLength] = useState(0);
	const [breakStart, setbreakStart] = useState(0);
	const [breakLength, setbreakLength] = useState(0);

	const calcLength = (Timein, Timeout) => {
		return ((Number(Timeout) - Number(Timein)) / Number(timeType)) * 100;
	};

	const calcStart = (Timein) => {
		return (Number(Timein) / Number(timeType)) * 100;
	};
	useEffect(() => {
		if (
			checkInTime !== null &&
			checkOutTime !== null &&
			breakTimeStart !== null &&
			breakTimeEnd !== null
		) {
			setActiveLength(calcLength(checkInTime, checkOutTime));

			setActiveStart(calcStart(checkInTime));

			setbreakLength(calcLength(breakTimeStart, breakTimeEnd));
			setbreakStart(calcStart(breakTimeStart));
			if (typeof timeType !== "undefined") {
				setTimetype(timeType);
			}
		}
	}, [checkInTime, checkOutTime, breakTimeStart, breakTimeEnd, timeType]);

	return (
		<div>
			<div className="daytime_wrapper">
				<div
					className="active_hours"
					style={{ width: `${activeLength}%`, left: `${activeStart}%` }}
				></div>
				<div
					className="break_hours"
					style={{ width: `${breakLength}%`, left: `${breakStart}%` }}
				></div>
			</div>
		</div>
	);
}
