import React, { useEffect, useReducer } from 'react';
import './SetupChecklist.scss';
import cloneDeep from 'lodash.clonedeep';
import { MiniChecklist } from './MiniChecklist';
import { CollapsedChecklist } from './CollapsedChecklist';
import { createLocalStorageStateHook } from 'use-local-storage-state';
import { CSSTransition } from 'react-transition-group';
import { OpenChecklist } from './OpenChecklist';

export const useInteractionHistory = createLocalStorageStateHook(
  'onboarding-checklist-state-key',
  {
    hasOpenedChecklist: false,
    hasDismissedReminder: false,
  }
);

export function OnboardingChecklist({ 
  collapsed,
  companyInfo,
  filteredUsers,
  subStores,
  categories,
  products
}) {
  const [state, dispatch] = useReducer(reducer, null, initState);
  const [interactionHistory, setInteractionHistory] = useInteractionHistory();
  const { hasOpenedChecklist, hasDismissedReminder } = interactionHistory;

  useEffect(() => {
    if (state.open && !interactionHistory.hasOpenedChecklist) {
      setInteractionHistory({
        ...interactionHistory,
        hasOpenedChecklist: true,
      });
    }
  }, [state.open, interactionHistory, setInteractionHistory]);

  const dismissReminder = () => {
    setInteractionHistory({
      ...interactionHistory,
      hasDismissedReminder: true,
    });
  };

  const openChecklist = () => {
    dispatch({
      type: 'OPEN_CHECKLIST',
    });
  };

  const closeChecklist = () => {
    dispatch({
      type: 'CLOSE_CHECKLIST',
    });
  };

  const completeTask = (taskId) => {
    dispatch({
      type: 'COMPLETE_TASK',
      task: taskId,
    });
  };

  const openTask = (id) => {
    dispatch({
      type: 'OPEN_DISCLOSURE',
      id,
    });
  };

  useEffect(() => {
    if(companyInfo.name && companyInfo.coverImage){
      completeTask('appSetup');
    };
    if(filteredUsers.length > 0){
      completeTask('inviteAdmin');
    };
    if(subStores.length > 0){
      completeTask('createStore');
    };
    if(categories.length > 0){
      completeTask('addCategory');
    };
    if(products.length > 0){
      completeTask('addProduct');
    };
  },[companyInfo, filteredUsers, subStores, categories, products])

  const tasks = Array.from(state.taskMap.values());
  const incompleteTasks = tasks.filter((t) => !t.isComplete);

  const showOpen = state.open;
  const showCollapsed = !showOpen && collapsed;
  const showMinimized = !showOpen && !collapsed;

  return (
    <>
      {showCollapsed && (
        <CollapsedChecklist
          badgeNumber={incompleteTasks.length}
          showReminder={!hasDismissedReminder && !hasOpenedChecklist}
          onClick={openChecklist}
          onDismiss={dismissReminder}
        />
      )}
      <CSSTransition
        mountOnEnter
        unmountOnExit
        in={showMinimized}
        timeout={300}
        classNames={{
          enter: 'miniChecklistEnter',
          enterActive: 'miniChecklistEnterActive',
          exit: 'miniChecklistExit',
          exitActive: 'miniChecklistExitActive',
        }}
      >
        {<MiniChecklist openChecklist={openChecklist} tasks={tasks} />}
      </CSSTransition>
      <CSSTransition
        mountOnEnter
        unmountOnExit
        in={showOpen}
        timeout={400}
        classNames={{
          enter: 'openChecklistEnter',
          enterActive: 'openChecklistEnterActive',
          exit: 'openChecklistExit',
          exitActive: 'openChecklistExitActive',
        }}
      >
        <OpenChecklist
          taskMap={state.taskMap}
          openTaskId={state.openTask}
          currentTask={state.currentTaskId}
          completeTask={completeTask}
          closeChecklist={closeChecklist}
          openTask={openTask}
        />
      </CSSTransition>
    </>
  );
}

function initState() {
  return {
    open: true,
    currentTask: 'createAccount',
    openTask: 'createAccount',
    taskMap: new Map([
      [
        'createAccount',
        {
          id: 'createAccount',
          title: 'Create an account',
          description: '',
          isComplete: true,
          tooltipLabel:
            '',
        },
      ],
      [
        'appSetup',
        {
          id: 'appSetup',
          title: 'Setup EW App',
          description:
            'Input app info like logo, name and support details',
          isComplete: false,
          tooltipLabel:
            'Input app info like logo, name and support details.',
        },
      ],
      [
        'inviteAdmin',
        {
          id: 'inviteAdmin',
          title: 'Invite a Store Admin',
          description: "Invite an admin to manage a store",
          isComplete: false,
          tooltipLabel:
            'Invite an admin to manage a store.',
        },
      ],
      [
        'createStore',
        {
          id: 'createStore',
          title: 'Create a Store',
          description:
            'Create a Store and assign an admin user to manage it',
          isComplete: false,
          tooltipLabel:
            'Create a Store and assign an admin user to manage it.',
        },
      ],
      [
        'addCategory',
        {
          id: 'addCategory',
          title: 'Add a Product Category',
          description:
            'Input Info like title',
          isComplete: false,
          tooltipLabel:
            'Add product categories to start creating your products.',
        },
      ],
      [
        'addProduct',
        {
          id: 'addProduct',
          title: 'Add a new Product',
          description:
            'Input Info like Product images, title, unit price etc.',
          isComplete: false,
          tooltipLabel:
            'Input Info like Product images, title, unit price etc.',
        },
      ],
    ]),
  };
}

function reducer(prevState, action) {
  const state = cloneDeep(prevState);
  switch (action.type) {
    case 'OPEN_CHECKLIST': {
      state.open = true;
      return state;
    }
    case 'CLOSE_CHECKLIST': {
      state.open = false;
      return state;
    }
    case 'OPEN_DISCLOSURE': {
      state.openTask = action.id;
      return state;
    }
    case 'COMPLETE_TASK': {
      const { task: id } = action;
      const task = state.taskMap.get(id);
      if (!task) return prevState;
      task.isComplete = true;
      const tasks = Array.from(state.taskMap.values());
      const firstIncomplete = tasks.find((t) => !t.isComplete);
      state.currentTask = firstIncomplete?.id || null;
      state.openTask = state.currentTask;
      return state;
    }
    default:
      return prevState;
  }
}
