import axios from "./axios";

export default class UserService {
	async getUsers() {
		const response = await axios.get(`api/auth/admin-users`);

		return response;
	}
	async createUser(userData) {
		const response = await axios.post(`api/auth/create-user`, userData);

		return response;
	}
	async updateUser(userData) {
		const response = await axios.patch(`api/auth/update-user`, userData);

		return response;
	}
	async updateUserStatus(data) {
		const response = await axios.post(
			`api/auth/subuser-activation/?userId=${data.userId}&activated=${data.activated}`
		);

		return response;
	}
	async getCashiers() {
		const response = await axios.get(`api/auth/cashier`);

		return response;
	}
	async assignCashier(userId) {
		const response = await axios.patch(
			`api/auth/aasign-stores?userId=${userId}`
		);

		return response;
	}
	async getCustomers(storeId) {
		const response = await axios.get(
			`api/customer/customers${storeId ? "?storeId=" + storeId : ""}`
		);

		return response;
	}
	async getCustomerDetails(userId) {
		const response = await axios.get(
			`api/customer/customer-info?userId=${userId}`
		);

		return response;
	}
}
