import React from "react";
import cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import colorLib from "@kurkle/color";

const isBrowser = typeof window !== "undefined";
const host = global.window?.location.host;
const domainParts = host?.split(".");
domainParts?.shift();
const domain = "." + domainParts?.join(".");

export const validText = (value) => {
	if (value === null) {
		return false;
	} else if (value.length > 2) {
		return true;
	} else {
		return false;
	}
};

export const validPhone = (value) => {
	if (value === null) {
		return false;
	} else if (value.length > 12) {
		return true;
	} else {
		return false;
	}
};

export const validEmail = (value) => {
	if (value === null || typeof value === "undefined") {
		return false;
	} else if (
		/^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/.test(value)
	) {
		//eslint-disable-line
		return true;
	} else {
		return false;
	}
};

export const calculateWeeklyhours = (row) => {
	let rA = row.resourceAvailability;
	let totalTimeWeekday = 0;
	let totalTimeWeekend = 0;
	let tt = 0;
	if (typeof rA === "object") {
		totalTimeWeekday =
			Number(rA.weekdaysCheckOut) -
			Number(rA.weekdaysCheckIn) -
			(Number(rA.weekDaysBreakTimeEnd) - Number(rA.weekDaysBreakTimeStart));
		totalTimeWeekend =
			Number(rA.weekendCheckOut) -
			Number(rA.weekendCheckIn) -
			(Number(rA.weekendBreakTimeEnd) - Number(rA.weekendBreakTimeStart));
		tt = 0;
		if (rA.monday) {
			tt = tt + totalTimeWeekday;
		}
		if (rA.tuesday) {
			tt = tt + totalTimeWeekday;
		}
		if (rA.wednesday) {
			tt = tt + totalTimeWeekday;
		}
		if (rA.thursday) {
			tt = tt + totalTimeWeekday;
		}
		if (rA.friday) {
			tt = tt + totalTimeWeekday;
		}
		if (rA.sunday) {
			tt = tt + totalTimeWeekend;
		}
		if (rA.saturday) {
			tt = tt + totalTimeWeekend;
		}
	}
	return tt;
};
export const filterResourcesByServiceId = (resource = [], serviceId = 0) => {
	let fr = resource.filter((thisresource, index) => {
		let returnBoolean = false;
		if (Number(serviceId) > 0) {
			if (typeof thisresource.services === "object") {
				for (var i = 0; i < thisresource.services.length; i++) {
					if (
						Number(thisresource.services[i].serviceId) === Number(serviceId)
					) {
						returnBoolean = true;
						break;
					} else {
						continue;
					}
				}
			}
		}
		return returnBoolean;
	});

	return fr;
};

export const convertNumberToDateTime = (value, timeType = 24) => {
	let thistime = value / (timeType / 24);
	let hour_time = Math.floor(thistime % 12);
	let minute_time = Math.floor(((thistime % 12) - hour_time) * 60);
	minute_time = minute_time < 10 ? "0" + minute_time : minute_time;
	hour_time = hour_time === 0 ? 12 : hour_time;
	hour_time = hour_time < 10 ? "0" + hour_time : hour_time;
	let ampm = thistime >= 12 ? "PM" : "AM";
	return `${hour_time}:${minute_time} ${ampm}`;
};

export const getDecodedAccessToken = (token) => {
	try {
		return jwt_decode(token);
	} catch (Error) {
		return null;
	}
};

export const _getTokenFromSession = (key) =>
	global.window?.sessionStorage.getItem(key);
export const _removeTokenFromSession = (key) =>
	global.window?.sessionStorage.removeItem(key);
export const _setTokenToSession = (token, name) =>
	global.window?.sessionStorage.setItem(name, token);

export const _getTokenFromStorage = (key) => isBrowser && cookies.get(key);

export const _setTokenToStorage = (key, value, expiresAt) =>
	isBrowser &&
	cookies.set(key, value, {
		expires: expiresAt,
		domain: domain,
		sameSite: "Lax",
	});

export const _removeTokenFromStorage = (key) =>
	isBrowser && cookies.remove(key, { domain: domain });

export const generateActions = (action) => {
	action = action.toUpperCase();
	return {
		REQUEST: `${action}_REQUEST`,
		SUCCESS: `${action}_SUCCESS`,
		FAILURE: `${action}_FAILURE`,
	};
};

export const showToast = (message, type) => {
	toast.dismiss();
	switch (type.toLowerCase()) {
		case "success":
			toast.success(message);
			break;
		case "info":
			toast.info(message);
			break;
		case "loading":
			toast.loading(message);
			break;
		case "warn":
			toast.warn(message);
			break;
		case "error":
			toast.error(message);
			break;
		default:
			toast.info(message);
			break;
	}
};

export const isElement = (element) => {
	return React.isValidElement(element);
};

export const formatToCurrency = (num, p) => {
	let num_ = num
		? Number(num)
				.toFixed(p)
				.replace(/\d(?=(\d{3})+\.)/g, "$&,")
		: num;
	return num_;
};

export const getDateTimeFormatUK = (value) => {
	let date_ob = new Date(value);
	let a = date_ob.getHours().toString().length === 1 ? "AM" : "PM";
	return `${date_ob.toLocaleString("en-UK")} ${a}`;
};

export const getDateTime = (value) => {
	let date_ob = new Date(value);
	let a = date_ob.getHours().toString().length === 1 ? "AM" : "PM";
	return `${date_ob.toDateString()} 
  ${date_ob.getHours().toString()}:${date_ob.getMinutes() < 10 ? "0" : ""}${date_ob.getMinutes()}  ${a}`;
};

export const getDate = (value) => {
	let date_ob = new Date(value);
	// return date_ob.toLocaleDateString();
	let day = date_ob.getDate();
	let month = date_ob.toLocaleString("default", { month: "short" });
	let year = date_ob.getFullYear();
	return `${day} ${month}, ${year}`;
};

export function getManager(users) {
	const manager = users.find((user) => user.role === "Administrator");
	return manager;
}

export function calcDate(date2) {
	const date1 = new Date();
	const tempDate = new Date(date2);
	const diff = Math.floor(date1.getTime() - tempDate.getTime());
	const day = 1000 * 60 * 60 * 24;

	const days = Math.floor(diff / day);
	// const months = Math.floor(days/31);
	// const years = Math.floor(months/12);

	let message = days > 1 ? days + " days" : "1 day";
	// message += months>0 ? months + " months " : "";
	// message += years>0 ? years + " years" : "";
	return message;
}

export function refreshPage() {
	window.location.reload(false);
}

const Months = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"June",
	"July",
	"Aug",
	"Sept",
	"Oct",
	"Nov",
	"Dec",
];

export function getMonth(num) {
	return Months[num - 1];
}

export const formatSales = (data) => {
	let tempData = [
		{
			name: "Jan",
			amount: 0,
		},
		{
			name: "Feb",
			amount: 0,
		},
		{
			name: "Mar",
			amount: 0,
		},
		{
			name: "Apr",
			amount: 0,
		},
		{
			name: "May",
			amount: 0,
		},
		{
			name: "June",
			amount: 0,
		},
		{
			name: "July",
			amount: 0,
		},
		{
			name: "Aug",
			amount: 0,
		},
		{
			name: "Sept",
			amount: 0,
		},
		{
			name: "Oct",
			amount: 0,
		},
		{
			name: "Nov",
			amount: 0,
		},
		{
			name: "Dec",
			amount: 0,
		},
	];
	if (data) {
		data.sort(function (a, b) {
			return a.name - b.name;
		});
		data.forEach((item) => {
			let foundIndex = tempData.findIndex(
				(x) => x.name === getMonth(item.name)
			);
			tempData[foundIndex] = {
				name: getMonth(item.name),
				amount: item.amount,
			};
		});
	}

	return tempData;
};

export const formatTransactions = (data) => {
	let tempData = [
		{
			name: "Jan",
			noOfTransaction: 0,
		},
		{
			name: "Feb",
			noOfTransaction: 0,
		},
		{
			name: "Mar",
			noOfTransaction: 0,
		},
		{
			name: "Apr",
			noOfTransaction: 0,
		},
		{
			name: "May",
			noOfTransaction: 0,
		},
		{
			name: "June",
			noOfTransaction: 0,
		},
		{
			name: "July",
			noOfTransaction: 0,
		},
		{
			name: "Aug",
			noOfTransaction: 0,
		},
		{
			name: "Sept",
			noOfTransaction: 0,
		},
		{
			name: "Oct",
			noOfTransaction: 0,
		},
		{
			name: "Nov",
			noOfTransaction: 0,
		},
		{
			name: "Dec",
			noOfTransaction: 0,
		},
	];
	if (data) {
		data.sort(function (a, b) {
			return a.name - b.name;
		});
		// eslint-disable-next-line array-callback-return
		data.map((item) => {
			let foundIndex = tempData.findIndex(
				(x) => x.name === getMonth(item.name)
			);
			tempData[foundIndex] = {
				name: getMonth(item.name),
				noOfTransaction: item.noOfTransaction,
			};
		});
	}

	return tempData;
};

export function transparentize(value, opacity) {
	var alpha = opacity === undefined ? 0.5 : 1 - opacity;
	return colorLib(value).alpha(alpha).rgbString();
}
export const randomColor = () => {
	let rgb = [];
	for (let i = 0; i < 3; i++) rgb.push(Math.floor(Math.random() * 255));

	return "rgb(" + rgb.join(",") + ")";
};
