import React, { useContext, useEffect, useState } from "react";
import { mainFunctions } from "../../providers/MainProvider";

import Sidebar from "../../components/Sidebar";
import TopBar from "../../components/TopBar";
import ProductDeals from "./tables/ProductDeals";
import Challenges from "./components/Challenges";
import AddDeal from "../../modals/AddDeal";
import { useDispatch, useSelector } from "react-redux";
import {
	getChallengesAsync,
	getDealsAsync,
	getGrowthAccountingAsync,
} from "../../redux/actions";
import AddChallenge from "../../modals/AddChallenge";
import ResponsiveLineChartComponent from "../../components/charts/ResponsiveLinechartComponent";
import { useGuard } from "../../hooks/useGuard";
import ResponsiveBarChartComponent from "../../components/charts/ResponsiveBarchartComponent";

export default function GrowthAccounting() {
	useGuard();
	const dispatch = useDispatch();
	const { growthAccounting } = useSelector((state) => state.analytic);
	useEffect(() => {
		dispatch(getGrowthAccountingAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	let data1 = {
		xAxisTitle: "2022 Quarters",
		yAxisTitle: "Ratio",
		plots: [
			{
				key: "Gross_Retention",
				coordinates: [
					{
						xTag: "Q1",
						yValue: 0,
					},
					{
						xTag: "Q2",
						yValue: 0.494328762,
					},
					{
						xTag: "Q3",
						yValue: 0.345814978,
					},
					{
						xTag: "Q4",
						yValue: 0.010125606,
					},
				],
			},
			{
				key: "Net_Churn",
				coordinates: [
					{
						xTag: "Q1",
						yValue: 0,
					},
					{
						xTag: "Q2",
						yValue: 0.178263078,
					},
					{
						xTag: "Q3",
						yValue: -0.638472834,
					},
					{
						xTag: "Q4",
						yValue: -0.976373585,
					},
				],
			},
			{
				key: "Quick_Ratio",
				coordinates: [
					{
						xTag: "Q1",
						yValue: 0,
					},
					{
						xTag: "Q2",
						yValue: 3.582189488,
					},
					{
						xTag: "Q3",
						yValue: 0.801474972,
					},
					{
						xTag: "Q4",
						yValue: 0.866070839,
					},
				],
			},
		],
		title: "Growth Accounting Ratio",
		dataType: "LineChartData",
	};
	let data2 = {
		xAxisTitle: "Period",
		yAxisTitle: "Life Time Value (Millions in Naira)",
		plots: [
			{
				key: "2021-09",
				coordinates: [
					{
						xTag: "0",
						yValue: 1,
					},
					{
						xTag: "1",
						yValue: 2.5,
					},
					{
						xTag: "2",
						yValue: 7.7,
					},
					{
						xTag: "3",
						yValue: 14.7,
					},
					{
						xTag: "4",
						yValue: 14.7,
					},
					{
						xTag: "5",
						yValue: 14.7,
					},
					{
						xTag: "6",
						yValue: 14.7,
					},
					{
						xTag: "7",
						yValue: 14.7,
					},
					{
						xTag: "8",
						yValue: 14.7,
					},
					{
						xTag: "9",
						yValue: 14.7,
					},
					{
						xTag: "10",
						yValue: 14.7,
					},
				],
			},
			{
				key: "2021-10",
				coordinates: [
					{
						xTag: "0",
						yValue: 34.4,
					},
					{
						xTag: "1",
						yValue: 91.475,
					},
					{
						xTag: "2",
						yValue: 112.475,
					},
					{
						xTag: "3",
						yValue: 112.475,
					},
					{
						xTag: "4",
						yValue: 112.475,
					},
					{
						xTag: "5",
						yValue: 112.475,
					},
					{
						xTag: "6",
						yValue: 112.475,
					},
					{
						xTag: "7",
						yValue: 112.475,
					},
					{
						xTag: "8",
						yValue: 112.475,
					},
					{
						xTag: "9",
						yValue: 112.475,
					},
				],
			},
			{
				key: "2021-11",
				coordinates: [
					{
						xTag: "0",
						yValue: 48.5,
					},
					{
						xTag: "1",
						yValue: 48.5,
					},
					{
						xTag: "2",
						yValue: 108.5,
					},
					{
						xTag: "3",
						yValue: 194.5,
					},
					{
						xTag: "4",
						yValue: 194.5,
					},
					{
						xTag: "5",
						yValue: 294.5,
					},
					{
						xTag: "6",
						yValue: 294.5,
					},
					{
						xTag: "7",
						yValue: 294.5,
					},
					{
						xTag: "8",
						yValue: 294.5,
					},
				],
			},
			{
				key: "2021-12",
				coordinates: [
					{
						xTag: "0",
						yValue: 33.41,
					},
					{
						xTag: "1",
						yValue: 40.41,
					},
					{
						xTag: "2",
						yValue: 47.01,
					},
					{
						xTag: "3",
						yValue: 50.01,
					},
					{
						xTag: "4",
						yValue: 50.01,
					},
					{
						xTag: "5",
						yValue: 50.01,
					},
					{
						xTag: "6",
						yValue: 50.01,
					},
					{
						xTag: "7",
						yValue: 50.01,
					},
				],
			},
			{
				key: "2022-01",
				coordinates: [
					{
						xTag: "0",
						yValue: 43.94,
					},
					{
						xTag: "1",
						yValue: 76.64,
					},
					{
						xTag: "2",
						yValue: 80.54,
					},
					{
						xTag: "3",
						yValue: 80.54,
					},
					{
						xTag: "4",
						yValue: 80.54,
					},
					{
						xTag: "5",
						yValue: 80.54,
					},
					{
						xTag: "6",
						yValue: 80.54,
					},
				],
			},
			{
				key: "2022-02",
				coordinates: [
					{
						xTag: "0",
						yValue: 42.85,
					},
					{
						xTag: "1",
						yValue: 49.45,
					},
					{
						xTag: "2",
						yValue: 54.05,
					},
					{
						xTag: "3",
						yValue: 59.05,
					},
					{
						xTag: "4",
						yValue: 63.05,
					},
					{
						xTag: "5",
						yValue: 63.05,
					},
				],
			},
			{
				key: "2022-03",
				coordinates: [
					{
						xTag: "0",
						yValue: 38.1785,
					},
					{
						xTag: "1",
						yValue: 59.1785,
					},
					{
						xTag: "2",
						yValue: 59.1785,
					},
					{
						xTag: "3",
						yValue: 59.1785,
					},
					{
						xTag: "4",
						yValue: 59.1785,
					},
				],
			},
			{
				key: "2022-04",
				coordinates: [
					{
						xTag: "0",
						yValue: 111.00005,
					},
					{
						xTag: "1",
						yValue: 111.00005,
					},
					{
						xTag: "2",
						yValue: 111.00005,
					},
				],
			},
			{
				key: "2022-05",
				coordinates: [
					{
						xTag: "0",
						yValue: 3,
					},
					{
						xTag: "1",
						yValue: 3,
					},
					{
						xTag: "2",
						yValue: 3,
					},
					{
						xTag: "3",
						yValue: 6,
					},
				],
			},
			{
				key: "2022-06",
				coordinates: [
					{
						xTag: "0",
						yValue: 150,
					},
					{
						xTag: "1",
						yValue: 150,
					},
				],
			},
			{
				key: "2022-07",
				coordinates: [
					{
						xTag: "0",
						yValue: 100,
					},
				],
			},
			{
				key: "average",
				coordinates: [
					{
						xTag: "0",
						yValue: 11.71,
					},
					{
						xTag: "1",
						yValue: 102.57,
					},
					{
						xTag: "2",
						yValue: 196.94,
					},
					{
						xTag: "3",
						yValue: 46.36,
					},
					{
						xTag: "4",
						yValue: 74.75,
					},
					{
						xTag: "5",
						yValue: 55.25,
					},
					{
						xTag: "6",
						yValue: 54.98,
					},
					{
						xTag: "7",
						yValue: 111,
					},
					{
						xTag: "8",
						yValue: 3.75,
					},
					{
						xTag: "9",
						yValue: 150,
					},
					{
						xTag: "10",
						yValue: 100,
					},
				],
			},
		],
		title: "Life Time Value",
		dataType: "LineChartData",
	};
	let data3 = {
		xAxisTitle: "Period",
		yAxisTitle: "Revenue Retention (%)",
		plots: [
			{
				key: "2021-09",
				coordinates: [
					{
						xTag: "0",
						yValue: 100,
					},
					{
						xTag: "1",
						yValue: 150,
					},
					{
						xTag: "2",
						yValue: 520,
					},
					{
						xTag: "3",
						yValue: 700,
					},
					{
						xTag: "4",
						yValue: -100,
					},
					{
						xTag: "5",
						yValue: -100,
					},
					{
						xTag: "6",
						yValue: -100,
					},
					{
						xTag: "7",
						yValue: -100,
					},
					{
						xTag: "8",
						yValue: -100,
					},
					{
						xTag: "9",
						yValue: -100,
					},
					{
						xTag: "10",
						yValue: -100,
					},
				],
			},
			{
				key: "2021-10",
				coordinates: [
					{
						xTag: "0",
						yValue: 100,
					},
					{
						xTag: "1",
						yValue: 165.9156977,
					},
					{
						xTag: "2",
						yValue: 61.04651163,
					},
					{
						xTag: "3",
						yValue: -100,
					},
					{
						xTag: "4",
						yValue: -100,
					},
					{
						xTag: "5",
						yValue: -100,
					},
					{
						xTag: "6",
						yValue: -100,
					},
					{
						xTag: "7",
						yValue: -100,
					},
					{
						xTag: "8",
						yValue: -100,
					},
					{
						xTag: "9",
						yValue: -100,
					},
					{
						xTag: "10",
						yValue: -100,
					},
				],
			},
			{
				key: "2021-11",
				coordinates: [
					{
						xTag: "0",
						yValue: 100,
					},
					{
						xTag: "1",
						yValue: -100,
					},
					{
						xTag: "2",
						yValue: 123.711340206186,
					},
					{
						xTag: "3",
						yValue: 177.3195876,
					},
					{
						xTag: "4",
						yValue: -100,
					},
					{
						xTag: "5",
						yValue: 206.185567,
					},
					{
						xTag: "6",
						yValue: -100,
					},
					{
						xTag: "7",
						yValue: -100,
					},
					{
						xTag: "8",
						yValue: -100,
					},
					{
						xTag: "9",
						yValue: -100,
					},
					{
						xTag: "10",
						yValue: -100,
					},
				],
			},
			{
				key: "2021-12",
				coordinates: [
					{
						xTag: "0",
						yValue: 100,
					},
					{
						xTag: "1",
						yValue: 20.95181084,
					},
					{
						xTag: "2",
						yValue: 19.7545645,
					},
					{
						xTag: "3",
						yValue: 8.979347501,
					},
					{
						xTag: "4",
						yValue: -100,
					},
					{
						xTag: "5",
						yValue: -100,
					},
					{
						xTag: "6",
						yValue: -100,
					},
					{
						xTag: "7",
						yValue: -100,
					},
					{
						xTag: "8",
						yValue: -100,
					},
					{
						xTag: "9",
						yValue: -100,
					},
					{
						xTag: "10",
						yValue: -100,
					},
				],
			},
			{
				key: "2022-01",
				coordinates: [
					{
						xTag: "0",
						yValue: 100,
					},
					{
						xTag: "1",
						yValue: 74.41966318,
					},
					{
						xTag: "2",
						yValue: 8.875739645,
					},
					{
						xTag: "3",
						yValue: -100,
					},
					{
						xTag: "4",
						yValue: -100,
					},
					{
						xTag: "5",
						yValue: -100,
					},
					{
						xTag: "6",
						yValue: -100,
					},
					{
						xTag: "7",
						yValue: -100,
					},
					{
						xTag: "8",
						yValue: -100,
					},
					{
						xTag: "9",
						yValue: -100,
					},
					{
						xTag: "10",
						yValue: -100,
					},
				],
			},
			{
				key: "2022-02",
				coordinates: [
					{
						xTag: "0",
						yValue: 100,
					},
					{
						xTag: "1",
						yValue: 15.40256709,
					},
					{
						xTag: "2",
						yValue: 10.73512252,
					},
					{
						xTag: "3",
						yValue: 11.66861144,
					},
					{
						xTag: "4",
						yValue: 9.334889148,
					},
					{
						xTag: "5",
						yValue: -100,
					},
					{
						xTag: "6",
						yValue: -100,
					},
					{
						xTag: "7",
						yValue: -100,
					},
					{
						xTag: "8",
						yValue: -100,
					},
					{
						xTag: "9",
						yValue: -100,
					},
					{
						xTag: "10",
						yValue: -100,
					},
				],
			},
			{
				key: "2022-03",
				coordinates: [
					{
						xTag: "0",
						yValue: 100,
					},
					{
						xTag: "1",
						yValue: 55.0047801773249,
					},
					{
						xTag: "2",
						yValue: -100,
					},
					{
						xTag: "3",
						yValue: -100,
					},
					{
						xTag: "4",
						yValue: -100,
					},
					{
						xTag: "5",
						yValue: -100,
					},
					{
						xTag: "6",
						yValue: -100,
					},
					{
						xTag: "7",
						yValue: -100,
					},
					{
						xTag: "8",
						yValue: -100,
					},
					{
						xTag: "9",
						yValue: -100,
					},
					{
						xTag: "10",
						yValue: -100,
					},
				],
			},
			{
				key: "2022-04",
				coordinates: [
					{
						xTag: "0",
						yValue: 100,
					},
					{
						xTag: "1",
						yValue: -100,
					},
					{
						xTag: "2",
						yValue: -100,
					},
					{
						xTag: "3",
						yValue: -100,
					},
					{
						xTag: "4",
						yValue: -100,
					},
					{
						xTag: "5",
						yValue: -100,
					},
					{
						xTag: "6",
						yValue: -100,
					},
					{
						xTag: "7",
						yValue: -100,
					},
					{
						xTag: "8",
						yValue: -100,
					},
					{
						xTag: "9",
						yValue: -100,
					},
					{
						xTag: "10",
						yValue: -100,
					},
				],
			},
			{
				key: "2022-05",
				coordinates: [
					{
						xTag: "0",
						yValue: 100,
					},
					{
						xTag: "1",
						yValue: -100,
					},
					{
						xTag: "2",
						yValue: -100,
					},
					{
						xTag: "3",
						yValue: -100,
					},
					{
						xTag: "4",
						yValue: -100,
					},
					{
						xTag: "5",
						yValue: -100,
					},
					{
						xTag: "6",
						yValue: -100,
					},
					{
						xTag: "7",
						yValue: -100,
					},
					{
						xTag: "8",
						yValue: -100,
					},
					{
						xTag: "9",
						yValue: -100,
					},
					{
						xTag: "10",
						yValue: -100,
					},
				],
			},
			{
				key: "2022-06",
				coordinates: [
					{
						xTag: "0",
						yValue: 100,
					},
					{
						xTag: "1",
						yValue: -100,
					},
					{
						xTag: "2",
						yValue: -100,
					},
					{
						xTag: "3",
						yValue: -100,
					},
					{
						xTag: "4",
						yValue: -100,
					},
					{
						xTag: "5",
						yValue: -100,
					},
					{
						xTag: "6",
						yValue: -100,
					},
					{
						xTag: "7",
						yValue: -100,
					},
					{
						xTag: "8",
						yValue: -100,
					},
					{
						xTag: "9",
						yValue: -100,
					},
					{
						xTag: "10",
						yValue: -100,
					},
				],
			},
			{
				key: "2022-07",
				coordinates: [
					{
						xTag: "0",
						yValue: 100,
					},
					{
						xTag: "1",
						yValue: -100,
					},
					{
						xTag: "2",
						yValue: -100,
					},
					{
						xTag: "3",
						yValue: -100,
					},
					{
						xTag: "4",
						yValue: -100,
					},
					{
						xTag: "5",
						yValue: -100,
					},
					{
						xTag: "6",
						yValue: -100,
					},
					{
						xTag: "7",
						yValue: -100,
					},
					{
						xTag: "8",
						yValue: -100,
					},
					{
						xTag: "9",
						yValue: -100,
					},
					{
						xTag: "10",
						yValue: -100,
					},
				],
			},
			{
				key: "average",
				coordinates: [
					{
						xTag: "0",
						yValue: 100,
					},
					{
						xTag: "1",
						yValue: -1.66413464,
					},
					{
						xTag: "2",
						yValue: 22.19302532,
					},
					{
						xTag: "3",
						yValue: 36.17886787,
					},
					{
						xTag: "4",
						yValue: -90.06046462,
					},
					{
						xTag: "5",
						yValue: -72.16494845,
					},
					{
						xTag: "6",
						yValue: -100,
					},
					{
						xTag: "7",
						yValue: -100,
					},
					{
						xTag: "8",
						yValue: -100,
					},
					{
						xTag: "9",
						yValue: -100,
					},
					{
						xTag: "10",
						yValue: -100,
					},
				],
			},
		],
		title: "Revenue Retention",
		dataType: "LineChartData",
	};
	let data4 = {
		xAxisTitle: "Date",
		yAxisTitle: "Approved Loan Amount (Millions)",
		groups: [
			{
				key: "Expansion",
				bars: [
					{
						tag: "Q1",
						frequency: 0,
					},
					{
						tag: "Q2",
						frequency: 101,
					},
					{
						tag: "Q3",
						frequency: 5.35,
					},
					{
						tag: "Q4",
						frequency: 4,
					},
				],
			},
			{
				key: "Ressurection",
				bars: [
					{
						tag: "Q1",
						frequency: 0,
					},
					{
						tag: "Q2",
						frequency: 0,
					},
					{
						tag: "Q3",
						frequency: 0,
					},
					{
						tag: "Q4",
						frequency: 4,
					},
				],
			},
			{
				key: "Returning",
				bars: [
					{
						tag: "Q1",
						frequency: 0,
					},
					{
						tag: "Q2",
						frequency: 73,
					},
					{
						tag: "Q3",
						frequency: 117.75,
					},
					{
						tag: "Q4",
						frequency: 3,
					},
				],
			},
			{
				key: "New",
				bars: [
					{
						tag: "Q1",
						frequency: 147.675,
					},
					{
						tag: "Q2",
						frequency: 166.5,
					},
					{
						tag: "Q3",
						frequency: 173.17855,
					},
					{
						tag: "Q4",
						frequency: 250,
					},
				],
			},
			{
				key: "Contraction",
				bars: [
					{
						tag: "Q1",
						frequency: 0,
					},
					{
						tag: "Q2",
						frequency: -71.175,
					},
					{
						tag: "Q3",
						frequency: -64.74,
					},
					{
						tag: "Q4",
						frequency: 0,
					},
				],
			},
			{
				key: "Churn",
				bars: [
					{
						tag: "Q1",
						frequency: 0,
					},
					{
						tag: "Q2",
						frequency: -3.5,
					},
					{
						tag: "Q3",
						frequency: -158.01,
					},
					{
						tag: "Q4",
						frequency: -293.27855,
					},
				],
			},
		],
		title: "Growth Accounting",
		dataType: "StackedBarChartData",
	};

	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar title="Growth Accounting" />
				<div className="mainbar-container">
					{growthAccounting.map((item, idx) => {
						if (item.dataType === "LineChartData") {
							return (
								<div key={idx}>
									<ResponsiveLineChartComponent data={item} />
								</div>
							);
						} else if (item.dataType === "StackedBarChartData") {
							return (
								<div key={idx}>
									<ResponsiveBarChartComponent data={item} />
								</div>
							);
						}
						return null;
					})}
					{/* <ResponsiveLineChartComponent data={data1} />
              <ResponsiveLineChartComponent data={data2} />
              <ResponsiveLineChartComponent data={data3} />
              <ResponsiveBarChartComponent data={data4} /> */}
				</div>
			</div>
		</div>
	);
}
