import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageUploadPreview from "../components/ImageUploadPreview";
import { mainFunctions } from "../providers/MainProvider";
import {
	createProductAsync,
	getCategoriesAsync,
	getProductsAsync,
	getServicesAsync,
} from "../redux/actions";
import { showToast } from "../utils";
import EditAppointment_AssignResource from "./EditAppointment_AssignResource";

const EditAppointment = (props) => {
	const dispatch = useDispatch();
	const { services, loading } = useSelector((state) => state.service);
	const [allServices, setAllServices] = useState([]);
	const { setShowModal, setModalData } = useContext(mainFunctions);

	const [appointmentData, setAppointmentData] = useState({});

	const onChangeValue = (name, value) => {
		let v = { ...appointmentData };
		v[name] = value;
		setAppointmentData(v);
	};
	const handleSubmit = async () => {
		await setModalData(
			<EditAppointment_AssignResource
				appointmentData={appointmentData}
				setAppointmentData={setAppointmentData}
				onChangeValue={onChangeValue}
			/>
		);
		await setShowModal(true);
	};

	useEffect(() => {
		dispatch(getServicesAsync());
	}, []);

	useEffect(() => {
		console.log(appointmentData);
	}, [appointmentData]);

	useEffect(() => {
		console.log(props.row);
		if (JSON.stringify(appointmentData) === "{}") {
			setAppointmentData(props.row);
		}
	}, [props]);

	useEffect(() => {
		if (typeof services !== "undefined" && services !== null) {
			if (typeof services.data !== "undefined") {
				if (typeof services.data.data !== "undefined") {
					setAllServices(services.data.data);
				}
			}
		}
	}, [services]);
	return (
		// <div className='w-100 h-100 d-flex flex-column align-items-center add_staff_modal'>
		<div className="d-flex flex-column align-items-center add_staff_modal">
			<div className="title">New Appointment</div>
			<p className="subtitle text-center"></p>
			<div className="auth_form_container">
				{/* <div className='fieldset'> */}
				{/* <label className='label'>Resource Type</label> */}
				{/* <div className='flex_field'>
                        <div className='radiofield' 
                        onClick={()=>{
                           onChangeValue('resourceType', 0) 
                        }}
                        >
                            <div className={`radiobox ${appointmentData.resourceType === 0 ? 'active': ''}`}></div>
                            <div className='text'>Table</div>
                        </div>

                        <div className='radiofield'
                        onClick={()=>{
                            onChangeValue('resourceType', 1) 
                        }}
                        >
                            <div className={`radiobox ${appointmentData.resourceType === 1 ? 'active': ''}`}></div>
                            <div className='text'>Personnel</div>
                        </div>
                    </div> */}
				{/* </div> */}

				<div className="fieldset">
					<label className="label">Customer Name</label>
					<div className="input_box">
						<input
							type="text"
							className="form-control"
							name="customerName"
							defaultValue={appointmentData.customerName}
							onChange={(e) => {
								onChangeValue("customerName", e.target.value);
							}}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Customer Email</label>
					<div className="input_box">
						<input
							type="text"
							className="form-control"
							name="customerEmail"
							defaultValue={appointmentData.customerEmail}
							onChange={(e) => {
								onChangeValue("customerEmail", e.target.value);
							}}
						/>
					</div>
				</div>

				<div className="fieldset">
					<label className="label">Customer Phone Number</label>
					<div className="input_box">
						<input
							type="text"
							name="customerPhoneNumber"
							className="form-control"
							defaultValue={appointmentData.customerPhoneNumber}
							onChange={(e) => {
								onChangeValue("customerPhoneNumber", e.target.value);
							}}
						/>
					</div>
				</div>

				{appointmentData.resourceType === 1 && (
					<div className="fieldset">
						<label className="label">Service</label>
						<div className="input_box">
							{typeof services === "object" && (
								<select
									onChange={(e) => {
										onChangeValue("serviceId", e.target.value);
									}}
									defaultValue={appointmentData.serviceId}
								>
									<option disabled value={""}>
										Select a service
									</option>
									{allServices.map((thisservice, index) => {
										return (
											<option value={thisservice.id} key={index}>
												{thisservice.name}
											</option>
										);
									})}
								</select>
							)}
						</div>
					</div>
				)}

				<div className="btn_ btn_orange" onClick={() => handleSubmit()}>
					Continue
				</div>
			</div>
		</div>
	);
};

export default EditAppointment;
