import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { mainFunctions } from "../providers/MainProvider";
import { getStoresAsync, updateStoreStatusAsync } from "../redux/actions";

const DeactivateStore = ({ store }) => {
	const dispatch = useDispatch();
	const { setShowModal } = useContext(mainFunctions);
	const handleSubmit = (e) => {
		e.preventDefault();
		let storeData = {
			id: store.id,
			status: !store.active,
		};
		dispatch(
			updateStoreStatusAsync(storeData, async (_) => {
				dispatch(getStoresAsync());
				setShowModal(false);
			})
		);
	};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">
				{store.Active ? "Deactivate Store" : "Activate Store"}
			</div>
			<p className="subtitle text-center">
				Are you sure you want to {store.active ? "deactivate" : "activate"} this
				store?
			</p>
			<p className="subtitle text-center">
				<b>{store.location}</b>
			</p>
			<div className="auth_form_container">
				<div className="btn_ btn_orange" onClick={handleSubmit}>
					{store.active ? "Deactivate Store" : "Activate Store"}
				</div>
			</div>
		</div>
	);
};

export default DeactivateStore;
