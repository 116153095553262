import * as types from "../types";

const initialState = {
	loading: false,
	error: null,
	resources: [],
	personnel: [],
	tables: [],
	resource: [],
};

const ResourceReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.ADD_RESOURCE.REQUEST:
		case types.GET_TABLES.REQUEST:
		case types.GET_PERSONNEL.REQUEST:
		case types.UPDATE_RESOURCE.REQUEST:
		case types.DELETE_RESOURCE.REQUEST:
		case types.GET_RESOURCE.REQUEST:
		case types.GET_RESOURCES.REQUEST:
			return { ...state, loading: true };

		case types.ADD_RESOURCE.SUCCESS:
		case types.UPDATE_RESOURCE.SUCCESS:
		case types.DELETE_RESOURCE.SUCCESS:
			return { ...state, loading: false };

		case types.GET_PERSONNEL.SUCCESS:
			return { ...state, loading: false, personnel: payload };

		case types.GET_TABLES.SUCCESS:
			return { ...state, loading: false, tables: payload };

		case types.GET_RESOURCE.SUCCESS:
			return { ...state, loading: false, resource: payload };

		case types.GET_RESOURCES.SUCCESS:
			return { ...state, loading: false, resources: payload };

		case types.ADD_RESOURCE.FAILURE:
		case types.GET_TABLES.FAILURE:
		case types.GET_PERSONNEL.FAILURE:
		case types.GET_RESOURCE.FAILURE:
		case types.GET_RESOURCES.FAILURE:
		case types.UPDATE_RESOURCE.FAILURE:
		case types.DELETE_RESOURCE.FAILURE:
			return { ...state, loading: false, error: payload };

		default:
			return state;
	}
};

export default ResourceReducer;
