import {
	_getTokenFromStorage,
	_removeTokenFromStorage,
	_setTokenToStorage,
} from "../utils";

export default class StorageService {
	#milliSecondsInDays = 86400000;

	saveAuthData(authData) {
		// this.#saveItemIfProvided("access_token", authData.accessToken, Number(authData.expires_in) / this.#milliSecondsInDays);
		const expirationTime = new Date(authData.expires_in * 1000);
		this.#saveItemIfProvided(
			"accessToken",
			authData.accessToken,
			expirationTime
		);
		this.#saveItemIfProvided("UserName", authData.UserName, expirationTime);
		this.#saveItemIfProvided("Role", authData.Role, expirationTime);
		this.#saveItemIfProvided("StoreId", authData.StoreId, expirationTime);
		this.#saveItemIfProvided("SubStoreId", authData.SubStoreId, expirationTime);
	}

	#saveItemIfProvided(key, value, expiresAt) {
		if (value && expiresAt) {
			_setTokenToStorage(key, value, expiresAt);
		} else if (value) {
			_setTokenToStorage(key, value);
		}
	}

	clearAuthData() {
		_removeTokenFromStorage("accessToken");
		_removeTokenFromStorage("UserName");
		_removeTokenFromStorage("Role");
		_removeTokenFromStorage("StoreId");
		_removeTokenFromStorage("SubStoreId");
		_removeTokenFromStorage("name");
	}

	clearCookieData() {
		_removeTokenFromStorage("accessToken");
		_removeTokenFromStorage("UserName");
		_removeTokenFromStorage("Role");
		_removeTokenFromStorage("StoreId");
		_removeTokenFromStorage("SubStoreId");
		_removeTokenFromStorage("name");
		_removeTokenFromStorage("izi-nav-user");
		_removeTokenFromStorage("izi-kyc-user");
		_removeTokenFromStorage("izi-bnpl-user");
	}

	set(key, value) {
		this.#saveItemIfProvided(key, value);
	}

	get(key) {
		return _getTokenFromStorage(key);
	}

	remove(key) {
		return _removeTokenFromStorage(key);
	}

	getAuthData() {
		return {
			// accessToken: "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjI5NzVhZWQzLWM4NzMtNDAzYy1hMGFjLWNhZGQ4MzE1YzIxMyIsIlJvbGUiOiJTdXBlckFkbWluaXN0cmF0b3IiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiaW5mb0ByaW5nbmJyaW5nLmNvbSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2VtYWlsYWRkcmVzcyI6ImluZm9AcmluZ25icmluZy5jb20iLCJVc2VyTmFtZSI6ImluZm9AcmluZ25icmluZy5jb20iLCJGaXJzdE5hbWUiOiJFcmljIiwiTGFzdE5hbWUiOiJLYXlzZXIiLCJleHAiOjE3MTM5NzAyNTYsImlzcyI6Iml6aWZpbi5jb20iLCJhdWQiOiJpemlmaW4uY29tIn0.awTl6Fat1oQm6GNtvYk7V5np00hbSvhALJpjY6d02Yw",
			accessToken: _getTokenFromStorage("accessToken"),
			userName: _getTokenFromStorage("UserName"),
			Role: _getTokenFromStorage("Role"),
			// Role: "SuperAdministrator",
			StoreId: _getTokenFromStorage("StoreId"),
			SubStoreId: _getTokenFromStorage("SubStoreId"),
			name: _getTokenFromStorage("name"),
		};
	}
}
