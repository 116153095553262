import axios from "./axios";

export default class TransactionService {
	async getDashboardData(data) {
		const response = await axios.get(
			`api/dashboard/dashboard?startDate=${data.startDate}&endDate=${data.endDate}`
		);

		return response;
	}
	async getAdminDashboardData(data) {
		const response = await axios.get(
			`api/dashboard/admin-dashboard?startDate=${data.startDate}&endDate=${data.endDate}`
		);

		return response;
	}
	async getRevenueData(data) {
		const response = await axios.get(
			`api/dashboard/revenue?startDate=${data.startDate}&endDate=${data.endDate}`
		);

		return response;
	}
	async getTransactionData(id) {
		const response = await axios.get(
			`api/transaction/customer-order-detail?orderId=${id}`
		);

		return response;
	}
	async getBankCodes() {
		const response = await axios.get(`api/wallet/bank-code`);

		return response;
	}
	async getBeneficiaryDetails(data) {
		const response = await axios.get(
			`api/wallet/beneficiary-account-details?receiverAccountNumber=${data.accountNumber}&receiverBankCode=${data.bankCode}`
		);

		return response;
	}
	async triggerWithdraw(data) {
		const response = await axios.post(`api/wallet/withdraw`, data);

		return response;
	}
	async updateDeliveryStatus(data) {
		const response = await axios.patch(`api/transaction/delivery`, data);

		return response;
	}
	async getTransactionStatus(purchaseCode) {
		const response = await axios.get(
			`api/transaction/transaction-status?purchaseCode=${purchaseCode}`
		);

		return response;
	}
	async adminUpdateOrder(data) {
		const response = await axios.patch(`api/purchase/update-order`, data);

		return response;
	}
	async adminCompleteOrder(data) {
		const response = await axios.put(
			`api/purchase/order-fulfilled-status?purchaseCode=${data}`
		);

		return response;
	}
	async adminCancelOrder(data) {
		const response = await axios.put(
			`api/purchase/cancel-order?purchaseCode=${data}`
		);

		return response;
	}
}
