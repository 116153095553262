import React, { useState, useEffect, useCallback } from "react";
import dateFormat from "dateformat";
import { Calendar } from "@natscale/react-calendar";
import "@natscale/react-calendar/dist/main.css";
import TimelineEvent from "../components/TimelineEvent";

const CalendarViewResources = (props) => {
	const [value, setValue] = useState(new Date());
	const [appointmentDay, setAppointmentDay] = useState(
		dateFormat(value, "yyyy-mm-d")
	);
	const [selectedDate, setSelectedDate] = useState(value);
	const [activeHours, setActiveHours] = useState({
		start: 0,
		end: 0,
		breakstart: 0,
		breakend: 0,
	});

	const isHighlight = useCallback((date) => {
		return false;
	}, []);

	const isDisabled = (date) => {
		if (typeof props.availability.monday !== "undefined") {
			let dd = date.getTime();
			const d = new Date();
			let now = d.getTime() - 86400000;

			if (now > dd) {
				return true;
			} else if (date.getDay() === 1 && props.availability.monday) {
				return false;
			} else if (date.getDay() === 2 && props.availability.tuesday) {
				return false;
			} else if (date.getDay() === 3 && props.availability.wednesday) {
				return false;
			} else if (date.getDay() === 4 && props.availability.thursday) {
				return false;
			} else if (date.getDay() === 5 && props.availability.friday) {
				return false;
			} else if (date.getDay() === 6 && props.availability.saturday) {
				return false;
			} else if (date.getDay() === 0 && props.availability.sunday) {
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	};

	const onChangeCalendar = (val) => {
		setValue(val);
		let day_ = `${dateFormat(val, "yyyy-mm-d")}`;
		setAppointmentDay(day_);
		setSelectedDate(val);
	};

	useEffect(() => {
		if (selectedDate !== null) {
			let d = selectedDate.getDay();
			let m;
			if (d > 0 && d < 6) {
				m = {
					start: props.availability.weekdaysCheckIn,
					end: props.availability.weekdaysCheckOut,
					breakstart: props.availability.weekDaysBreakTimeStart,
					breakend: props.availability.weekDaysBreakTimeEnd,
				};
			} else {
				m = {
					start: props.availability.weekendCheckIn,
					end: props.availability.weekendCheckOut,
					breakstart: props.availability.weekendBreakTimeStart,
					breakend: props.availability.weekendBreakTimeEnd,
				};
			}
			setActiveHours(m);
		}
	}, [selectedDate, props.availability]);

	return (
		// <div className='w-100 h-100 d-flex flex-column align-items-center add_staff_modal'>
		<div className="d-flex flex-column align-items-center add_staff_modal">
			<div className="title">Calender View</div>
			<p className="subtitle text-center"></p>
			<div className="calendar_container">
				<center>
					<Calendar
						isHighlight={isHighlight}
						isDisabled={isDisabled}
						value={value}
						onChange={onChangeCalendar}
						size={310}
					/>
				</center>
				<div>
					<h4>Resource Availability for {appointmentDay}</h4>

					<TimelineEvent
						startTime={activeHours.start}
						endTime={Number(activeHours.end) + 1}
						events={[
							{
								startTime: Number(activeHours.start),
								duration: Number(activeHours.end) - Number(activeHours.start),
								text: "Active Time",
								bgColor: "#1abc9c",
								color: "#2c3e50",
							},
							{
								startTime: Number(activeHours.breakstart),
								duration:
									Number(activeHours.breakend) - Number(activeHours.breakstart),
								text: "Break Time",
								bgColor: "#c0392b",
								color: "#F5F5F5",
							},
						]}
					/>
				</div>
			</div>
		</div>
	);
};

export default CalendarViewResources;
