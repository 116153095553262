import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { mainFunctions } from "../providers/MainProvider";
import { getProductsAsync, updateProductStatusAsync } from "../redux/actions";

const SetStock = ({ product }) => {
	const dispatch = useDispatch();
	const { setShowModal } = useContext(mainFunctions);
	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(
			updateProductStatusAsync(product.id, async (_) => {
				dispatch(getProductsAsync());
				setShowModal(false);
			})
		);
	};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">
				{!product.isOutOfStock ? "Make Unavailable" : "Make Available"}
			</div>
			<p className="subtitle text-center">
				Are you sure you want to{" "}
				{!product.isOutOfStock ? "set to OUT of stock" : "set to IN stock"}?
			</p>
			<p className="subtitle text-center">
				<b>{product.name}</b>
			</p>
			<div className="auth_form_container">
				<div className="btn_ btn_orange" onClick={handleSubmit}>
					{!product.isOutOfStock ? "Unavailable" : "Available"}
				</div>
			</div>
		</div>
	);
};

export default SetStock;
