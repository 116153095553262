import React, { useContext, useState } from "react";
import { useGuard } from "../../hooks/useGuard";
import { mainFunctions } from "../../providers/MainProvider";
import Sidebar from "../../components/Sidebar";
import TopBar from "../../components/TopBar";
import CreateAppointment from "../../modals/CreateAppointment";
import TabTitle from "../../components/TabTitle";
import AllAppointments from "../../components/AllAppointments";
import TableAppointments from "../../components/TableAppointments";
import PersonnelAppointments from "../../components/PersonnelAppointments";

export default function Appointments() {
	const { setShowModal, setModalData } = useContext(mainFunctions);

	const TABLES_APPOINTMENT_TAB = {
		id: "tables",
		title: "Tables",
		data: {},
	};

	const PERSONNEL_APPOINTMENT_TAB = {
		id: "personnel",
		title: "Personnel",
		data: {},
	};

	const ALL_APPOINTMENT_TAB = {
		id: "all",
		title: "All",
		data: {},
	};

	const [activeTab, setActiveTab] = useState(ALL_APPOINTMENT_TAB);
	useGuard();
	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar
					title="Appointments"
					button={{
						title: "Create Appointment",
					}}
					buttonAction={async () => {
						await setModalData(<CreateAppointment />);
						await setShowModal(true);
					}}
				/>
				<div className="mainbar-container">
					<div className="col-sm-12">
						<TabTitle
							pages={[
								ALL_APPOINTMENT_TAB,
								TABLES_APPOINTMENT_TAB,
								PERSONNEL_APPOINTMENT_TAB,
							]}
							active={activeTab}
							setActive={setActiveTab}
							underline={true}
						/>
						{activeTab.id === "all" && <AllAppointments />}
						{activeTab.id === "tables" && <TableAppointments />}
						{activeTab.id === "personnel" && <PersonnelAppointments />}
					</div>
				</div>
			</div>
		</div>
	);
}
