import { generateActions } from "../../utils";

export const LOGIN = generateActions("LOGIN");
export const SSOLOGIN = generateActions("SSOLOGIN");
export const CHANGE_PASSWORD = generateActions("CHANGE_PASSWORD");
export const FORGOT_PASSWORD = generateActions("FORGOT_PASSWORD");
export const RESET_PASSWORD = generateActions("RESET_PASSWORD");
export const FETCH_ALL_ROLES = generateActions("FETCH_ALL_ROLES");
export const FETCH_COMPANY_INFO = generateActions("FETCH_COMPANY_INFO");
export const UPDATE_COMPANY_INFO = generateActions("UPDATE_COMPANY_INFO");
export const SEND_ADMIN_SUPPORT = generateActions("SEND_ADMIN_SUPPORT");
