import React, { useState } from "react";

const UsePurchaseCodeModal = ({ importPurchase }) => {
	const [purchaseCode, setPurchaseCode] = useState("");
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">Use Customer Purchase Code</div>
			<p className="subtitle text-center"></p>
			<div className="auth_form_container pt-3 pb-0">
				<div className="fieldset pb-5">
					<label className="label">Purchase Code</label>
					<div className="input_box mb-5">
						<input
							type="text"
							value={purchaseCode}
							onChange={(e) => setPurchaseCode(e.target.value)}
						/>
					</div>
				</div>

				<div
					className="btn_ btn_green mt-5"
					onClick={() => importPurchase(purchaseCode)}
				>
					Preview Purchase
				</div>
			</div>
		</div>
	);
};

export default UsePurchaseCodeModal;
