import React, { useContext, useState, useEffect } from "react";
import { calculateWeeklyhours, formatToCurrency } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import { getTablesAsync } from "../../redux/actions";
import { mainFunctions } from "../../providers/MainProvider";
import { useGuard } from "../../hooks/useGuard";
import Sidebar from "../../components/Sidebar";
import TopBar from "../../components/TopBar";
import SummaryBox from "../../components/SummaryBox";
import Tables from "../../components/Tables";
import AddNewTable from "../../modals/AddNewTable.js";
import FilterTable from "../../modals/FilterTable";
import EditResource from "../../modals/EditResource";
import DeleteResources from "../../modals/DeleteResources";
import AnalyticsWrapper from "../../components/AnalyticsWrapper.js";

export default function StoreTables() {
	let navigate = useNavigate();
	useGuard();
	const dispatch = useDispatch();
	const [allTables, setAllTables] = useState([]);
	const { tables } = useSelector((state) => state.resource);

	const tableStatusOptions = [
		{ title: "All", value: "" },
		{ title: "booked", value: "Booked" },
		{ title: "active", value: "Active" },
		{ title: "used", value: "Used" },
	];
	const [tableStatus, setTableStatus] = useState(tableStatusOptions[0]);

	const totalTables = 29;

	const { setShowModal, setModalData } = useContext(mainFunctions);

	const tableColumns = [
		{
			title: "ID",
			dataIndex: "id",
		},
		{
			title: "Table Name",
			dataIndex: "tableName",
		},
		{
			title: "Seats",
			dataIndex: "noOfSeats",
		},
		{
			title: "Attached Personnel",
			dataIndex: "attachedPersonnel",
		},
		{
			title: "Available Hours",
			dataIndex: "hours",
		},
		{
			title: "Table Cost",
			dataIndex: "tableCost",
		},
		{
			title: "Status",
			dataIndex: "available",
		},
		{
			title: "",
			dataIndex: "option",
			search: "false",
		},
	];

	const [filteredTableData, setFilteredTableData] = useState(allTables);

	const [visibilities, setVisibilities] = React.useState([]);

	const handleClick = (index) => {
		const newVisibilities = [...visibilities];
		newVisibilities.map((thisVisibility, ind) => {
			index !== ind
				? (newVisibilities[ind] = false)
				: (newVisibilities[index] = !newVisibilities[index]);
			return 0;
		});
		setVisibilities(newVisibilities);
	};

	const dataSource =
		filteredTableData && filteredTableData.length > 0
			? filteredTableData.map((row, index) => {
					return {
						id: <div>ID: {row.id}</div>,
						tableName: <div>{row.tableName}</div>,
						noOfSeats: <div>{row.noOfSeats}</div>,
						attachedPersonnel: <div>{row.attachedPersonnel}</div>,
						tableCost: <div>₦{formatToCurrency(row.tableCost)}</div>,
						hours: <div>{calculateWeeklyhours(row)} hrs/w</div>,
						available: <div>{row.available ? "Available" : "Unavailable"}</div>,
						option: (
							<div className="">
								<div className="position-relative">
									<div
										className="d-flex items-center"
										style={{ cursor: "pointer" }}
									>
										<BsThreeDots onClick={() => handleClick(index)} size={24} />
									</div>
									{visibilities[index] ? (
										<div
											className="position-absolute border border-muted px-3 w-32 bg-white"
											style={{
												right: "0",
												top: "100%",
												zIndex: "2",
												width: "150px",
											}}
										>
											<div
												onClick={async () => {
													navigate(`/resource/${row.id}`, {
														state: {
															row: row,
															type: "tables",
														},
													});
												}}
												style={{ cursor: "pointer" }}
												className="d-flex text-left py-3 border-bottom border-muted hover:text-blue-dark text-small"
											>
												View
											</div>
											<div
												onClick={async () => {
													setModalData(<EditResource row={row} />);
													setShowModal(true);
												}}
												style={{ cursor: "pointer" }}
												className="d-flex text-left py-3 border-bottom border-muted status-success hover:text-blue-dark text-small"
											>
												Edit Table
											</div>
											<div
												onClick={() => {
													setModalData(<DeleteResources row={row} />);
													setShowModal(true);
												}}
												style={{ cursor: "pointer" }}
												className="d-flex text-left py-3 status-failed hover:text-blue-dark text-small"
											>
												Remove Table
											</div>
										</div>
									) : (
										""
									)}
								</div>
							</div>
						),
					};
				})
			: [];

	useEffect(() => {
		setFilteredTableData(allTables);
	}, [allTables]);

	useEffect(() => {
		dispatch(getTablesAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (typeof tables.data !== "undefined") {
			if (typeof tables.data.data !== "undefined") {
				setAllTables(tables.data.data);
			}
		}
	}, [tables]);

	useEffect(() => {
		if (visibilities.length === 0) {
			let e = filteredTableData.map((x) => false);
			setVisibilities(e);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteredTableData]);
	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar
					title="Tables"
					button={{
						title: "Add a Table",
					}}
					buttonAction={async () => {
						await setModalData(<AddNewTable />);
						await setShowModal(true);
					}}
				/>
				<div className="mainbar-container">
					<AnalyticsWrapper>
						<SummaryBox
							title={"Total Tables"}
							value={allTables.length}
							comma={false}
						/>
					</AnalyticsWrapper>
					<div className="col-sm-12">
						<Tables
							title="All Tables"
							dataSource={dataSource}
							columns={tableColumns}
							setFilteredTableData={setFilteredTableData}
							source={allTables}
							//Filters Props
							handleStatusFilter={false}
							// handleDateFilter={true}
							tableStatusOptions={tableStatusOptions}
							tableStatus={tableStatus}
							setTableStatus={setTableStatus}
							//search prop
							handleSearch={true}
							// pagination props
							showPagination={true}
							showPageSize={true}
							// extrabutton={{
							//   title: "Filter Table",
							//   iconClassName: "filtertable",
							//   action:
							//     async () => {
							//       await setModalData(
							//         <FilterTable />
							//       )
							//       await setShowModal(true);
							//     }
							// }
							// }
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
