import * as types from "../types";

const initialState = {
	loading: false,
	error: null,
	dealTypes: [],
	deals: [],
	challenges: [],
	challengeTypes: [],
	challengePeriods: [],
};

const StoreReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.GET_DEAL_TYPES.REQUEST:
		case types.GET_CHALLENGE_PERIODS.REQUEST:
		case types.GET_DEALS.REQUEST:
		case types.CREATE_DEAL.REQUEST:
		case types.UPDATE_DEAL.REQUEST:
		case types.DELETE_DEAL.REQUEST:
		case types.UPDATE_DEAL_STATUS.REQUEST:
		case types.GET_CHALLENGE_TYPES.REQUEST:
		case types.GET_CHALLENGES.REQUEST:
		case types.CREATE_CHALLENGE.REQUEST:
		case types.UPDATE_CHALLENGE.REQUEST:
		case types.DELETE_CHALLENGE.REQUEST:
		case types.UPDATE_CHALLENGE_STATUS.REQUEST:
			return { ...state, loading: true };

		case types.GET_CHALLENGE_PERIODS.SUCCESS:
			return { ...state, loading: false, challengePeriods: [...payload] };
		case types.GET_DEAL_TYPES.SUCCESS:
			return { ...state, loading: false, dealTypes: [...payload] };
		case types.GET_DEALS.SUCCESS:
			return { ...state, loading: false, deals: payload };
		case types.GET_CHALLENGE_TYPES.SUCCESS:
			return { ...state, loading: false, challengeTypes: [...payload] };
		case types.GET_CHALLENGES.SUCCESS:
			return { ...state, loading: false, challenges: payload };
		case types.CREATE_DEAL.SUCCESS:
		case types.UPDATE_DEAL.SUCCESS:
		case types.DELETE_DEAL.SUCCESS:
		case types.UPDATE_DEAL_STATUS.SUCCESS:
		case types.CREATE_CHALLENGE.SUCCESS:
		case types.UPDATE_CHALLENGE.SUCCESS:
		case types.DELETE_CHALLENGE.SUCCESS:
		case types.UPDATE_CHALLENGE_STATUS.SUCCESS:
			return { ...state, loading: false };

		case types.GET_DEAL_TYPES.FAILURE:
		case types.GET_CHALLENGE_PERIODS.FAILURE:
		case types.GET_DEALS.FAILURE:
		case types.CREATE_DEAL.FAILURE:
		case types.UPDATE_DEAL.FAILURE:
		case types.DELETE_DEAL.FAILURE:
		case types.UPDATE_DEAL_STATUS.FAILURE:
		case types.GET_CHALLENGE_TYPES.FAILURE:
		case types.GET_CHALLENGES.FAILURE:
		case types.CREATE_CHALLENGE.FAILURE:
		case types.UPDATE_CHALLENGE.FAILURE:
		case types.DELETE_CHALLENGE.FAILURE:
		case types.UPDATE_CHALLENGE_STATUS.FAILURE:
			return { ...state, loading: false, error: payload };
		default:
			return state;
	}
};

export default StoreReducer;
