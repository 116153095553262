import React, { useState, useEffect } from "react";
import Tables from "./Tables";
// import AddUser from '../modals/AddUser';
// import EditUser from '../modals/EditUser';
import DeactivateUser from "../modals/DeactivateUser";

import { BsThreeDots } from "react-icons/bs";

export default function UserRoles({
	users,
	setModalPage,
	setModalData,
	setShowModal,
	// EDIT_USER_MODAL,
	DEACTIVATE_USER_MODAL,
}) {
	const tableColumns = [
		{
			title: "Store ID",
			dataIndex: "id",
			sort: false,
		},
		{
			title: "User Name",
			dataIndex: "name",
			sort: false,
		},
		{
			title: "Email Address",
			dataIndex: "email",
			sort: true,
		},
		{
			title: "Role",
			dataIndex: "role",
			sort: false,
		},
		{
			title: "Store Allocated",
			dataIndex: "store",
			sort: false,
		},
		{
			title: "",
			dataIndex: "option",
		},
	];

	const [filteredTableData, setFilteredTableData] = useState(users);
	useEffect(() => {
		setFilteredTableData(users);
	}, [users]);
	const [visibilities, setVisibilities] = React.useState(() =>
		filteredTableData.map((x) => false)
	);
	const dataSource =
		filteredTableData && filteredTableData.length > 0
			? filteredTableData.map((row, index) => {
					return {
						id: <div>ID: {row.subStoreId}</div>,
						name: (
							<div>
								{row.firstName} {row.lastName}
							</div>
						),
						email: <div>{row.userName}</div>,
						role: <div>{row.roleName}</div>,
						store: <div>{row.storeName}</div>,
						option: (
							<div className="">
								<div className="position-relative">
									<div
										className="d-flex items-center"
										style={{ cursor: "pointer" }}
									>
										<BsThreeDots onClick={() => handleClick(index)} size={24} />
									</div>
									{visibilities[index] ? (
										<div
											className="position-absolute border border-muted px-3 w-32 bg-white"
											style={{
												right: "0",
												top: "100%",
												zIndex: "2",
												width: "150px",
											}}
										>
											{/* <div
                          onClick={async () => {
                            handleClick(index)
                            await setModalPage(EDIT_USER_MODAL)
                            await setModalData(<EditUser user={row} />);
                            setShowModal(true);
                          }}
                          style={{cursor: "pointer"}}
                          className="d-flex text-left py-3 border-bottom border-muted status-success hover:text-blue-dark text-small"
                        >
                          Edit User
                        </div> */}
											<div
												onClick={async () => {
													handleClick(index);
													await setModalPage(DEACTIVATE_USER_MODAL);
													await setModalData(<DeactivateUser user={row} />);
													setShowModal(true);
												}}
												style={{ cursor: "pointer" }}
												className={`d-flex text-left py-3 status-${row.status ? "failed" : "success"} hover:text-blue-dark text-small`}
											>
												{row.status ? "Deactivate " : "Activate "} User
											</div>
										</div>
									) : (
										""
									)}
								</div>
							</div>
						),
					};
				})
			: [];

	const handleClick = (index) => {
		const newVisibilities = [...visibilities];
		newVisibilities[index] = !newVisibilities[index];
		setVisibilities(newVisibilities);
	};

	return (
		<div>
			<Tables
				title="User and Roles"
				dataSource={dataSource}
				columns={tableColumns}
				handleSearch={false}
				showPagination={true}
				showPageSize={true}
				source={users}
				setFilteredTableData={setFilteredTableData}
			/>
		</div>
	);
}
