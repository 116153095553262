import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageUploadPreview from "../components/ImageUploadPreview";
import { mainFunctions } from "../providers/MainProvider";
import {
	getCategoriesAsync,
	getProductsAsync,
	updateProductAsync,
} from "../redux/actions";
import { showToast } from "../utils";

const EditProduct = ({ product }) => {
	const { setShowModal } = useContext(mainFunctions);
	const dispatch = useDispatch();
	const { categories, loading } = useSelector((state) => state.product);
	useEffect(() => {
		dispatch(getCategoriesAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const [oldImages, setOldImages] = useState(product.images);
	const [newImages, setNewImages] = useState([]);
	const [data, setData] = useState({
		id: product.id,
		productName: product.name,
		unitPrice: product.unitPrice,
		categoryId: product.productCategoryId,
		description: product.description,
	});
	useEffect(() => {
		setData({
			...data,
			newImages: newImages,
			oldImages: oldImages,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [oldImages, newImages]);
	const handleChange = (event) => {
		const min = 0;
		const max = 100000000;
		const value = Math.max(min, Math.min(max, Number(event.target.value)));
		setData({ ...data, unitPrice: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const { productName, unitPrice, categoryId, description } = data;
		if (!productName || !unitPrice || !categoryId || !description) {
			return showToast("Fill out all the fields", "error");
		}
		const productData = new FormData();
		productData.append("id", data.id);
		productData.append("ProductName", data.productName);
		productData.append("UnitPrice", data.unitPrice);
		productData.append("CategoryId", data.categoryId);
		productData.append("Description", data.description);
		[...data.oldImages].forEach((image) => {
			productData.append("oldImages", image);
		});
		[...data.newImages].forEach((image) => {
			productData.append("Images", image);
		});
		dispatch(
			updateProductAsync(productData, async (_) => {
				dispatch(getProductsAsync());
				setShowModal(false);
			})
		);
	};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">Edit Product</div>
			<p className="subtitle text-center"></p>
			<div className="auth_form_container">
				<ImageUploadPreview
					tempImages={newImages}
					setTempImages={setNewImages}
					oldImages={oldImages}
					setOldImages={setOldImages}
				/>
				<div className="fieldset">
					<label className="label">Product Name</label>
					<div className="input_box">
						<input
							type="text"
							value={data.productName}
							onChange={(e) =>
								setData({ ...data, productName: e.target.value })
							}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Unit Price</label>
					<div className="input_box">
						<input
							type="number"
							value={data.unitPrice}
							onChange={handleChange}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Category</label>
					<div className="input_box">
						<select
							defaultValue={data.categoryId}
							onChange={(e) => setData({ ...data, categoryId: e.target.value })}
						>
							<option selected disabled value="">
								Select a Category
							</option>
							{categories.map((category) => (
								<option key={category.id} value={category.id}>
									{category.name}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Description</label>
					<div className="input_box">
						<input
							value={data.description}
							onChange={(e) =>
								setData({ ...data, description: e.target.value })
							}
						/>
					</div>
				</div>

				<div
					className="btn_ btn_orange"
					onClick={!loading ? handleSubmit : () => {}}
				>
					{loading ? (
						<div className="spinner-border" role="status"></div>
					) : (
						"Update Product"
					)}
				</div>
			</div>
		</div>
	);
};

export default EditProduct;
