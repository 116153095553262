import { combineReducers } from "redux";
import auth from "./auth.reducers";
import suspense from "./suspense.reducers";
import store from "./store.reducers";
import user from "./user.reducers";
import transaction from "./transaction.reducers";
import product from "./product.reducers";
import resource from "./resource.reducers";
import cashier from "./cashier.reducers";
import appointment from "./appointment.reducers";
import service from "./service.reducers";
import reward from "./reward.reducers";
import analytic from "./analytic.reducers";
import mimic from "./mimic.reducers";

const rootReducer = combineReducers({
	auth,
	suspense,
	store,
	user,
	transaction,
	product,
	cashier,
	resource,
	appointment,
	service,
	reward,
	analytic,
	mimic,
});

export default rootReducer;
