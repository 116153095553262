import React, { useContext, useEffect, useState } from "react";
import SummaryBox from "../../components/SummaryBox";
import Title from "../../components/Title";
import TopBar from "../../components/TopBar";
import LineChartComponent from "../../components/charts/LineChartComponent";
import Sidebar from "../../components/Sidebar";
import Tables from "../../components/Tables";
import {
	formatSales,
	formatToCurrency,
	getDateTimeFormatUK,
} from "../../utils";
import { useGuard } from "../../hooks/useGuard";
import { useDispatch, useSelector } from "react-redux";
import { getAdminDashboardDataAsync } from "../../redux/actions";
import { mainFunctions } from "../../providers/MainProvider";
import PageFilters from "../../components/PageFilters";
import AnalyticsWrapper from "../../components/AnalyticsWrapper";
import Divider from "../../components/Divider";

export default function Dashboard() {
	useGuard();
	const dispatch = useDispatch();
	const { dateFilter } = useContext(mainFunctions);
	const { dashboardData } = useSelector((state) => state.transaction);
	const [year, setYear] = useState("2024");
	useEffect(() => {
		let activeDateFilter = {
			startDate: "",
			endDate: "",
		};
		if (dateFilter?.id) {
			if (dateFilter.id === "today") {
				const currentDate = new Date();
				setYear(currentDate.getFullYear());
				const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
				const day = currentDate.getDate().toString().padStart(2, "0");

				const formattedDate = `${year}-${month}-${day}`;
				activeDateFilter = {
					startDate: formattedDate,
					endDate: formattedDate,
				};
			} else if (dateFilter.id === "thisweek") {
				const currentDate = new Date();
				const currentDay = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
				const startDate = new Date(currentDate);
				startDate.setDate(currentDate.getDate() - currentDay); // Start of the week (Sunday)
				const startYear = startDate.getFullYear();
				const startMonth = (startDate.getMonth() + 1)
					.toString()
					.padStart(2, "0");
				const startDay = startDate.getDate().toString().padStart(2, "0");

				const formattedStartDate = `${startYear}-${startMonth}-${startDay}`;

				const endDate = new Date(startDate);
				endDate.setDate(startDate.getDate() + 6); // End of the week (Saturday)
				const endYear = endDate.getFullYear();
				const endMonth = (endDate.getMonth() + 1).toString().padStart(2, "0");
				const endDay = endDate.getDate().toString().padStart(2, "0");

				const formattedEndDate = `${endYear}-${endMonth}-${endDay}`;
				activeDateFilter = {
					startDate: formattedStartDate,
					endDate: formattedEndDate,
				};
			} else if (dateFilter.id === "thismonth") {
				const currentDate = new Date();

				const startDate = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth(),
					1
				);
				const startYear = startDate.getFullYear();
				const startMonth = (startDate.getMonth() + 1)
					.toString()
					.padStart(2, "0");
				const startDay = startDate.getDate().toString().padStart(2, "0");

				const formattedStartDate = `${startYear}-${startMonth}-${startDay}`;

				const endDate = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth() + 1,
					0
				);
				const endYear = endDate.getFullYear();
				const endMonth = (endDate.getMonth() + 1).toString().padStart(2, "0");
				const endDay = endDate.getDate().toString().padStart(2, "0");

				const formattedEndDate = `${endYear}-${endMonth}-${endDay}`;
				activeDateFilter = {
					startDate: formattedStartDate,
					endDate: formattedEndDate,
				};
			} else if (dateFilter.id === "thisyear") {
				const currentDate = new Date();

				const startDate = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth(),
					1
				);
				const startYear = startDate.getFullYear();

				const formattedStartDate = `${startYear}-01-01`;

				const endDate = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth() + 1,
					0
				);
				const endYear = endDate.getFullYear();

				const formattedEndDate = `${endYear}-12-31`;
				activeDateFilter = {
					startDate: formattedStartDate,
					endDate: formattedEndDate,
				};
			}
		}
		dispatch(getAdminDashboardDataAsync(activeDateFilter));
	}, [dateFilter, dispatch]);

	const tableColumns = [
		{
			title: "Trans ID",
			dataIndex: "id",
			sort: false,
		},
		{
			title: "Purchase",
			dataIndex: "purchase",
			sort: false,
		},
		{
			title: "Customer Name",
			dataIndex: "customerName",
			sort: false,
		},
		{
			title: "Purchase Value",
			dataIndex: "purchaseValue",
			sort: false,
		},
		{
			title: "Rewards Value",
			dataIndex: "rewardsValue",
			sort: false,
		},
		{
			title: "Date",
			dataIndex: "date",
			sort: false,
		},
		{
			title: "Status",
			dataIndex: "status",
			sort: false,
		},
	];

	let salesData = formatSales(dashboardData.transactionsOverview);

	const [filteredTableData, setFilteredTableData] = useState(
		dashboardData.transactions
	);

	const dataSource =
		filteredTableData && filteredTableData.length > 0
			? filteredTableData.map((row) => {
					return {
						id: row.transactionId,
						purchase: <div>{row.purchase}</div>,
						customerName: <div>{row.customerName}</div>,
						purchaseValue: <div>₦{formatToCurrency(row.purchaseValue, 2)}</div>,
						rewardsValue: <div>₦{formatToCurrency(row.rewardValue, 2)}</div>,
						date: <div>{getDateTimeFormatUK(row.date)}</div>,
						status: (
							<div
								className={`status-${
									row.status === "Success"
										? "success"
										: row.status === "Successful"
											? "success"
											: row.status === "Pending"
												? "pending"
												: "failed"
								}`}
							>
								{row.status}
							</div>
						),
					};
				})
			: [];

	useEffect(() => {
		setFilteredTableData(dashboardData.transactions);
	}, [dashboardData]);

	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar title="Dashboard" />
				<div className="mainbar-container">
					<PageFilters showStoreFilter={false} />
					<Title title="Overview" />
					<AnalyticsWrapper>
						<SummaryBox
							title="Total Stores"
							value={dashboardData.totalStores}
							comma={true}
						/>
						<Divider />
						 {typeof dashboardData.totalRevenue !== "undefined" &&
						<>
						<SummaryBox 
						title="Revenue Made" 
						value={`₦${formatToCurrency(dashboardData.totalRevenue, 2)}`}
						/>
						<Divider />
						</>
						}
						<SummaryBox
							title="Overall Active Customers"
							value={dashboardData.overallActiveCustomers}
							comma={true}
						/>
						{/* <Divider />
						<SummaryBox
							title={"Wallet Balance"}
							value={`₦${formatToCurrency(dashboardData.walletBalance, 2)}`}
						/> */}
					</AnalyticsWrapper>
					<Title title={"Transaction Overview"} />
					<div className="col-12">
						{typeof year !== "undefined" && (
							<LineChartComponent
								data={salesData}
								dataKey={"amount"}
								yTitle={"Amount"}
								xTitle={`Period - ${year}`}
							/>
						)}
					</div>

					<Tables
						title="Recent Transactions"
						//basic props
						columns={tableColumns}
						dataSource={dataSource}
						handleSearch={false}
						source={dashboardData.transactions}
						setFilteredTableData={setFilteredTableData}
					/>
				</div>
			</div>
		</div>
	);
}
