import axios from "./axios";

export default class StoreService {
	async getAllStores() {
		const response = await axios.get(`api/store/stores`);

		return response;
	}
	async createStore(storeData) {
		const response = await axios.post(`api/sub-store/sub-store`, storeData);

		return response;
	}

	
	async updateStore(storeData) {
		const response = await axios.patch(`api/sub-store/sub-store`, storeData);

		return response;
	}
	async updateStoreStatus(data) {
		const response = await axios.patch(
			`api/sub-store/activateDeactivate-sub-store`,
			data
		);

		return response;
	}
	async sendSupportMessage(data) {
		const response = await axios.post(`api/auth/admin-support`, data);

		return response;
	}
}
