import React, { useContext, useEffect, useState } from "react";
import { mainFunctions } from "../../providers/MainProvider";

import Sidebar from "../../components/Sidebar";
import TopBar from "../../components/TopBar";
import ProductDeals from "./tables/ProductDeals";
import Challenges from "./components/Challenges";
import AddDeal from "../../modals/AddDeal";
import { useDispatch, useSelector } from "react-redux";
import { getChallengesAsync, getDealsAsync } from "../../redux/actions";
import AddChallenge from "../../modals/AddChallenge";

export default function Rewards() {
	const {
		setModalPage,
		setModalData,
		setShowModal,
		ADD_DEAL_MODAL,
		ADD_CHALLENGE_MODAL,
	} = useContext(mainFunctions);
	const dispatch = useDispatch();
	const CHALLENGE_PAGE = "challenges";
	const DEALS_PAGE = "deals";
	const [rewardPage, setRewardPage] = useState(DEALS_PAGE);
	const { deals, challenges, loading } = useSelector((state) => state.reward);
	useEffect(() => {
		dispatch(getDealsAsync());
		dispatch(getChallengesAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar title="Rewards" />
				<div className="mainbar-container">
					<div style={{ minHeight: "80px" }}>
						<div className="page-filter">
							{rewardPage === DEALS_PAGE ? (
								<div
									className="btn_ btn_green mb-3"
									onClick={async () => {
										await setModalPage(ADD_DEAL_MODAL);
										await setModalData(<AddDeal />);
										setShowModal(true);
									}}
								>
									NEW DEAL
								</div>
							) : (
								<div
									className="btn_ btn_green mb-3"
									onClick={async () => {
										await setModalPage(ADD_CHALLENGE_MODAL);
										await setModalData(<AddChallenge />);
										setShowModal(true);
									}}
								>
									ADD CHALLENGE
								</div>
							)}
						</div>
						<div>
							<select
								className="select_ top_btn"
								onChange={(e) => setRewardPage(e.target.value)}
								value={rewardPage}
							>
								<option value={DEALS_PAGE}>Product Deals</option>
								<option value={CHALLENGE_PAGE}>Challenges</option>
							</select>
						</div>
					</div>
					{rewardPage === DEALS_PAGE ? (
						<ProductDeals deals={deals} />
					) : (
						<Challenges challenges={challenges} loading={loading} />
					)}
				</div>
			</div>
		</div>
	);
}
