import React from "react";
import "./CollapsedChecklist.scss";

export function CollapsedChecklist({ badgeNumber, onClick }) {
	return (
		<div className={"collapsedWrapper"}>
			<button className={"collapsedButton"} onClick={onClick}>
				<span className={"collapsedButtonStar"}>
					<svg viewBox="0 0 24 24" fill="none">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M12 3a1 1 0 01.897.557l2.24 4.537 5.008.733a1 1 0 01.553 1.705l-3.623 3.53.855 4.985a1 1 0 01-1.451 1.054L12 17.746 7.521 20.1a1 1 0 01-1.45-1.054l.855-4.986-3.624-3.529a1 1 0 01.553-1.705l5.009-.733 2.24-4.537A1 1 0 0112 3z"
							fill="currentColor"
						></path>
					</svg>
					{badgeNumber && badgeNumber > 0 && (
						<span className={"collapsedButtonBadge"}>{badgeNumber}</span>
					)}
				</span>
			</button>
		</div>
	);
}
