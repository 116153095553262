import React from "react";
import "./MiniChecklist.scss";
import cn from "classnames";
import {
	Text,
	SvgStar,
	TextButton,
	SvgChevronsRight,
	Tooltip,
} from "@loomhq/lens";

const Icon = <SvgChevronsRight />;

export function MiniChecklist({ openChecklist, tasks }) {
	const numberComplete = tasks.filter((t) => t.isComplete).length;
	const percentage = Math.round(
		(numberComplete / tasks.length) * 100
	).toString();

	return (
		<div className={"miniChecklist"}>
			<div className="flex flex-column justify-between items-center">
				{/* <p className={'miniChecklistTitle'}>Get started with iziMerchant</p> */}
				<TextButton icon={Icon} onClick={() => openChecklist?.()}>
					<Text fontWeight="bold">Get started with iziMerchant</Text>
				</TextButton>
			</div>
			<div className={"progressBar"}>
				<ol className={"taskList"}>
					{tasks.map((task, index) => (
						<li
							key={task.id}
							// className={cn($.taskItem, task.isComplete && $.taskItemComplete)}
						>
							<Tooltip content={task.title}>
								<div
									tabIndex="0"
									className={cn(
										"taskItem",
										task.isComplete && "taskItemComplete"
									)}
								/>
								{index === tasks.length - 1 && (
									<div className={"starIcon"}>
										<SvgStar />
									</div>
								)}
							</Tooltip>
						</li>
					))}
				</ol>
			</div>
			<p className={"message"}>
				<Text size="small">{percentage}% – You are close to completion!</Text>
			</p>
		</div>
	);
}
