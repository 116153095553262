import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mainFunctions } from "../providers/MainProvider";
import {
	getAllRolesAsync,
	getUsersAsync,
	updateUserAsync,
} from "../redux/actions";
import { showToast } from "../utils";

const EditUser = ({ user }) => {
	const { all_roles } = useSelector((state) => state.auth);
	const { setShowModal } = useContext(mainFunctions);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getAllRolesAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const [userData, setUserData] = useState({
		userId: user.userId,
		emailAddress: user.userName,
		firstName: user.firstName,
		lastName: user.lastName,
		role: user.roleName,
		phoneNumber: user.phoneNumber ? user.phoneNumber : "",
	});

	const handleSubmit = (e) => {
		e.preventDefault();
		const { emailAddress, firstName, lastName, role, phoneNumber } = userData;
		if (!emailAddress || !firstName || !lastName || !role || !phoneNumber) {
			return showToast("Fill out all the fields", "error");
		}
		dispatch(
			updateUserAsync(userData, async (_) => {
				dispatch(getUsersAsync());
				setShowModal(false);
			})
		);
	};

	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">Edit User</div>
			<p className="subtitle text-center"></p>
			<div className="auth_form_container">
				<div className="fieldset">
					<label className="label">First Name</label>
					<div className="input_box">
						<input
							type="text"
							required={true}
							value={userData.firstName}
							onChange={(e) =>
								setUserData({ ...userData, firstName: e.target.value })
							}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Last Name</label>
					<div className="input_box">
						<input
							type="text"
							required={true}
							value={userData.lastName}
							onChange={(e) =>
								setUserData({ ...userData, lastName: e.target.value })
							}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Email</label>
					<div className="input_box">
						<input
							type="email"
							required={true}
							value={userData.emailAddress}
							onChange={(e) =>
								setUserData({ ...userData, emailAddress: e.target.value })
							}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Phone Number</label>
					<div className="input_box">
						<input
							type="text"
							required={true}
							value={userData.phoneNumber}
							onChange={(e) =>
								setUserData({ ...userData, phoneNumber: e.target.value })
							}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">User Role</label>
					<div className="input_box">
						<select
							value={userData.role}
							required={true}
							onChange={(e) =>
								setUserData({ ...userData, role: e.target.value })
							}
						>
							{all_roles.map((row, index) => {
								return (
									<option value={row} key={index}>
										{row}
									</option>
								);
							})}
						</select>
					</div>
				</div>

				<div className="btn_ btn_orange" onClick={handleSubmit}>
					Update USER
				</div>
			</div>
		</div>
	);
};

export default EditUser;
