import { appointmentService } from "../../services";
import * as types from "../types";
import { showErrorMessage } from "./auth.actions";

export const addAppointmentAsync = (data, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.ADD_APPOINTMENT.REQUEST });
		try {
			await appointmentService.addAppointment(data);
			dispatch({ type: types.ADD_APPOINTMENT.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.ADD_APPOINTMENT.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};

export const getAppointmentAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_APPOINTMENT.REQUEST });
		try {
			const responseData = await appointmentService.getAppointment();
			dispatch({ type: types.GET_APPOINTMENT.SUCCESS });
			successCallback?.(responseData);
		} catch (err) {
			dispatch({ type: types.GET_APPOINTMENT.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};

export const getAppointmentsAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_APPOINTMENTS.REQUEST });
		try {
			const responseData = await appointmentService.getAppointments();
			dispatch({ type: types.GET_APPOINTMENTS.SUCCESS, payload: responseData });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_APPOINTMENTS.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};

export const getTableAvailability = (id, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_TABLE_AVAILABILITY.REQUEST });
		try {
			const responseData = await appointmentService.getTableAvailability(id);
			dispatch({
				type: types.GET_TABLE_AVAILABILITY.SUCCESS,
				payload: responseData,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_TABLE_AVAILABILITY.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};

export const updateAppointmentAsync = (
	data,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.UPDATE_APPOINTMENT.REQUEST });
		try {
			const responseData = await appointmentService.updateAppointment(data);
			dispatch({
				type: types.UPDATE_APPOINTMENT.SUCCESS,
				payload: responseData,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.UPDATE_APPOINTMENT.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};

export const deleteAppointmentAsync = (id, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.DELETE_APPOINTMENT.REQUEST });
		try {
			await appointmentService.deleteAppointment(id);
			dispatch({ type: types.DELETE_APPOINTMENT.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.DELETE_APPOINTMENT.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
