import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "../components/Select";
import { mainFunctions } from "../providers/MainProvider";
import {
	getStoresAsync,
	getUsersAsync,
	updateStoreAsync,
} from "../redux/actions";
import { getManager, refreshPage } from "../utils";
import CurrencyFormat from "react-currency-format";

const EditStore = ({ store }) => {
	const dispatch = useDispatch();
	const { users } = useSelector((state) => state.user);
	const { setShowModal } = useContext(mainFunctions);

	let filteredUsers = users.filter((user) => {
		return user.roleName === "Administrator" && user.subStoreId === 0;
	});
	const [storeData, setStoreData] = useState({
		id: store.id,
		name: store.name ? store.name : "",
		location: store.location,
		assigneeId:
			store.users.length > 0 && getManager(store.users) !== undefined
				? getManager(store.users)?.id
				: "",
		vat: store.vat,
		flatDeliveryFee: store.flatDeliveryFee,
	});
	let adminInfo =
		store.users.length > 0 && getManager(store.users) !== undefined
			? getManager(store.users)
			: {};

	useEffect(() => {
		dispatch(getUsersAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(
			updateStoreAsync(storeData, async (_) => {
				dispatch(getStoresAsync());
				setShowModal(false);
				refreshPage();
			})
		);
	};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">Edit Store</div>
			<p className="subtitle text-center"></p>
			<div className="auth_form_container">
				<div className="fieldset">
					<label className="label">Store Name</label>
					<div className="input_box">
						<input
							type="text"
							required={true}
							value={storeData.name}
							onChange={(e) =>
								setStoreData({ ...storeData, name: e.target.value })
							}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Store Location</label>
					<div className="input_box">
						<input
							type="text"
							required={true}
							value={storeData.location}
							onChange={(e) =>
								setStoreData({ ...storeData, location: e.target.value })
							}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Store Manager</label>
					<div className="input_box">
						<Select
							required={true}
							value={storeData.assigneeId}
							onChange={(e) =>
								setStoreData({ ...storeData, assigneeId: e.target.value })
							}
						>
							<option disabled value={""}>
								Select a Manager
							</option>
							{adminInfo.id && (
								<option value={adminInfo.id}>
									{adminInfo.firstName} {adminInfo.lastName}
								</option>
							)}
							{filteredUsers.map((row, index) => {
								return (
									<option key={index} value={row.userId}>
										{row.firstName} {row.lastName}
									</option>
								);
							})}
						</Select>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">VAT(%)</label>
					<div className="input_box">
						<input
							type="number"
							value={storeData.vat}
							required={true}
							onChange={(e) =>
								setStoreData({ ...storeData, vat: e.target.value })
							}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Flat Delivery Fee</label>
					<div className="input_box">
						<CurrencyFormat
							value={storeData.flatDeliveryFee}
							thousandSeparator={true}
							prefix={"₦"}
							onValueChange={(values) => {
								const { value } = values;
								setStoreData({
									...storeData,
									flatDeliveryFee: value,
								});
							}}
						/>
					</div>
				</div>

				<div className="btn_ btn_orange" onClick={handleSubmit}>
					Edit Store
				</div>
			</div>
		</div>
	);
};

export default EditStore;
