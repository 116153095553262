import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageUploadPreview from "../components/ImageUploadPreview";
import { mainFunctions } from "../providers/MainProvider";
import {
	createProductAsync,
	getCategoriesAsync,
	getProductsAsync,
	getServicesAsync,
} from "../redux/actions";
import { showToast } from "../utils";
import CalendarViewAssignResourcePersonnel from "./CalendarViewAssignResourcePersonnel";
import CalendarViewAssignResourceTable from "./CalendarViewAssignResourceTable";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { validText, validPhone, validEmail } from "../utils";

const CreateAppointment = () => {
	const dispatch = useDispatch();
	const { services, loading } = useSelector((state) => state.service);
	const [allServices, setAllServices] = useState([]);
	const { setShowModal, setModalData } = useContext(mainFunctions);

	const validAppointment = () => {
		let v =
			appointmentData.resourceType !== null &&
			validText(appointmentData.customerName) &&
			validEmail(appointmentData.customerEmail) &&
			validPhone(appointmentData.customerPhoneNumber) &&
			appointmentData.paymentMethod !== null;
		if (appointmentData.resourceType === 1) {
			v = v && appointmentData.serviceId !== null;
		}
		return v;
	};

	const [appointmentData, setAppointmentData] = useState({
		resourceType: null,
		resourceId: null,
		firstname: null,
		lastname: null,
		customerName: null,
		numberOfGuest: null,
		appointmentTime: null,
		appointmentDay: null,
		paymentStatus: null,
		paymentMethod: null,
		customerEmail: null,
		customerPhoneNumber: null,
		serviceId: null,
	});

	const onChangeValue = (name, value) => {
		let v = { ...appointmentData };
		v[name] = value;
		setAppointmentData(v);
	};
	const handleSubmit = async () => {
		if (appointmentData.resourceType === 0) {
			await setModalData(
				<CalendarViewAssignResourceTable
					appointmentData={appointmentData}
					setAppointmentData={setAppointmentData}
					onChangeValue={onChangeValue}
				/>
			);
		} else {
			await setModalData(
				<CalendarViewAssignResourcePersonnel
					appointmentData={appointmentData}
					setAppointmentData={setAppointmentData}
					onChangeValue={onChangeValue}
				/>
			);
		}
		await setShowModal(true);
	};

	useEffect(() => {
		dispatch(getServicesAsync());
	}, []);

	useEffect(() => {
		if (typeof services !== "undefined" && services !== null) {
			if (typeof services.data !== "undefined") {
				if (typeof services.data.data !== "undefined") {
					setAllServices(services.data.data);
				}
			}
		}
	}, [services]);

	// useEffect(() => {
	//     onChangeValue('customerName', `${appointmentData.firstname} ${appointmentData.lastname}`)
	// }, [appointmentData.firstname, appointmentData.lastname])

	return (
		<div className="d-flex flex-column align-items-center add_staff_modal">
			<div className="title">New Appointment</div>
			<p className="subtitle text-center"></p>
			<div className="auth_form_container">
				<div className="fieldset">
					<label className="label">
						Resource Type
						<div
							className={`label_icon ${appointmentData.resourceType === null ? "important" : "check"}`}
						></div>
					</label>
					<div className="flex_field">
						<div
							className="radiofield"
							onClick={() => {
								onChangeValue("resourceType", 0);
							}}
						>
							<div
								className={`radiobox ${appointmentData.resourceType === 0 ? "active" : ""}`}
							></div>
							<div className="text">Table</div>
						</div>

						<div
							className="radiofield"
							onClick={() => {
								onChangeValue("resourceType", 1);
							}}
						>
							<div
								className={`radiobox ${appointmentData.resourceType === 1 ? "active" : ""}`}
							></div>
							<div className="text">Personnel</div>
						</div>
					</div>
				</div>

				<div className="fieldset">
					<label className="label">
						Customer Name
						<div
							className={`label_icon ${!validText(appointmentData.customerName) ? "important" : "check"}`}
						></div>
					</label>
					<div className="input_box">
						<input
							type="text"
							className="form-control"
							name="customerName"
							onChange={(e) => {
								onChangeValue("customerName", e.target.value);
							}}
						/>
					</div>
				</div>

				{/* <div className='fieldset'>
                    <label className='label'>Firstname
                        <div className={`label_icon ${!validText(appointmentData.firstname) ? 'important' : 'check'}`}></div>
                    </label>
                    <div className='input_box'>
                        <input
                            type="text"
                            className='form-control'
                            name='firstname'
                            onChange={(e) => {
                                onChangeValue('firstname', e.target.value)
                            }}
                        />
                    </div>
                </div>


                <div className='fieldset'>
                    <label className='label'>Lastname
                        <div className={`label_icon ${!validText(appointmentData.lastname) ? 'important' : 'check'}`}></div>
                    </label>
                    <div className='input_box'>
                        <input
                            type="text"
                            className='form-control'
                            name='lastname'
                            onChange={(e) => {
                                onChangeValue('lastname', e.target.value)
                            }}
                        />
                    </div>
                </div> */}

				<div className="fieldset">
					<label className="label">
						Customer Email
						<div
							className={`label_icon ${!validEmail(appointmentData.customerEmail) ? "important" : "check"}`}
						></div>
					</label>
					<div className="input_box">
						<input
							type="text"
							className="form-control"
							name="customerEmail"
							onChange={(e) => {
								onChangeValue("customerEmail", e.target.value);
							}}
						/>
					</div>
				</div>

				<div className="fieldset">
					<label className="label">
						Customer Phone Number
						<div
							className={`label_icon ${!validPhone(appointmentData.customerPhoneNumber) ? "important" : "check"}`}
						></div>
					</label>
					<div className="input_box">
						<PhoneInput
							country={"ng"}
							onlyCountries={["ng"]}
							value={appointmentData.customerPhoneNumber}
							onChange={(phone) => onChangeValue("customerPhoneNumber", phone)}
						/>
					</div>
				</div>

				<div className="fieldset">
					<label className="label">
						Payment Method
						<div
							className={`label_icon ${appointmentData.paymentMethod === null ? "important" : "check"}`}
						></div>
					</label>
					<div className="flex_field">
						<div
							className="radiofield"
							onClick={() => {
								onChangeValue("paymentMethod", 1);
							}}
						>
							<div
								className={`radiobox ${appointmentData.paymentMethod === 1 ? "active" : ""}`}
							></div>
							<div className="text">Engagement Wallet</div>
						</div>

						<div
							className="radiofield"
							onClick={() => {
								onChangeValue("paymentMethod", 2);
							}}
						>
							<div
								className={`radiobox ${appointmentData.paymentMethod === 2 ? "active" : ""}`}
							></div>
							<div className="text">Offline Payment</div>
						</div>
					</div>
				</div>

				{appointmentData.resourceType === 1 && (
					<div className="fieldset">
						<label className="label">
							Service
							<div
								className={`label_icon ${appointmentData.serviceId === null ? "important" : "check"}`}
							></div>
						</label>
						<div className="input_box">
							{typeof services === "object" && (
								<select
									onChange={(e) => {
										onChangeValue("serviceId", e.target.value);
									}}
									defaultValue={""}
								>
									<option disabled value={""}>
										Select a service
									</option>
									{allServices.map((thisservice, index) => {
										return (
											<option value={thisservice.id} key={index}>
												{thisservice.name}
											</option>
										);
									})}
								</select>
							)}
						</div>
					</div>
				)}

				<div
					className={`btn_
                    ${validAppointment() ? "btn_orange" : "btn_dumb"}
                `}
					onClick={() => {
						if (validAppointment()) {
							handleSubmit();
						}
					}}
				>
					Continue
				</div>
			</div>
		</div>
	);
};

export default CreateAppointment;
