import { generateActions } from "../../utils";

export const GET_SERVICE_SERVICES = generateActions("GET_SERVICES");
export const GET_SERVICE_SERVICE = generateActions("GET_SERVICE");
export const GET_SERVICE_SERVICE_PERSONNEL = generateActions(
	"GET_SERVICES_PERSONNEL"
);
export const GET_SERVICE_CATEGORY = generateActions("GET_CATEGORY");
export const GET_SERVICE_CATEGORIES = generateActions("GET_CATEGORIES");

export const ADD_SERVICE_SERVICE = generateActions("ADD_SERVICE");
export const ADD_SERVICE_CATEGORY = generateActions("ADD_CATEGORY");

export const UPDATE_SERVICE_SERVICE = generateActions("UPDATE_SERVICE");
export const UPDATE_SERVICE_CATEGORY = generateActions("UPDATE_CATEGORY");

export const DELETE_SERVICE_SERVICE = generateActions("DELETE_SERVICE");
export const DELETE_SERVICE_CATEGORY = generateActions("DELETE_CATEGORY");
