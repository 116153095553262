import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { mainFunctions } from "../providers/MainProvider";
import { getDealsAsync, updateDealStatusAsync } from "../redux/actions";

const DeactivateDeal = ({ deal }) => {
	const dispatch = useDispatch();
	const { setShowModal } = useContext(mainFunctions);
	const handleSubmit = (e) => {
		e.preventDefault();
		let dealData = {
			id: deal.id,
			status: deal.isDeal ? "false" : "true",
		};
		dispatch(
			updateDealStatusAsync(dealData, async (_) => {
				dispatch(getDealsAsync());
				setShowModal(false);
			})
		);
	};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title"></div>
			<p className="subtitle text-center">
				Are you sure you want to {deal.isDeal ? "deactivate" : "activate"} this
				deal?
			</p>
			<p className="subtitle text-center">
				<b>
					{deal.type} {deal.deals}
					{" on "}
					{deal.product.name}
				</b>
			</p>
			<div className="auth_form_container">
				<div className="btn_ btn_orange" onClick={handleSubmit}>
					{deal.isDeal ? "Deactivate" : "Activate"} Deal
				</div>
			</div>
		</div>
	);
};

export default DeactivateDeal;
