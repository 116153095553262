import React from 'react'

export default function AnalyticsWrapper(props) {
  return (
    <div
    className='bg-dark-gray2
          flex flex-start flex-wrap gap-[1px] p-[22px] p-[20px] 
          rounded-[5px] border-[1px] border-light-gray1'
    >
        {props.children}
    </div>
  )
}
