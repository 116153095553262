import React, { useEffect } from "react";
import TopBar from "../../components/TopBar";
import DetailsBox from "../../components/DetailsBox";
import { formatToCurrency, getDate } from "../../utils";
import Title from "../../components/Title";
import {
	getCashierTransactionAsync,
	updateDeliveryStatusAsync,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function CashierTransactionDetails() {
	const dispatch = useDispatch();
	const { id } = useParams();
	const { transaction } = useSelector((state) => state.cashier);
	useEffect(() => {
		if (id) {
			dispatch(getCashierTransactionAsync(id));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);
	const handleSubmit = () => {
		let data = {
			transactionId: +id,
			deliveryStatus: "Dispatched",
		};
		dispatch(
			updateDeliveryStatusAsync(data, async (_) => {
				dispatch(getCashierTransactionAsync(id));
			})
		);
	};

	return (
		<div className="body">
			<div className="cashier-main w-100">
				<TopBar title="Transaction Details" small={true} />
				<div className="full-mainbar-container">
					<div className="row pt-5 border-bottom">
						<DetailsBox title="Customer ID:" value={transaction.customerId} />
						<DetailsBox
							title="Customer Name:"
							value={transaction.customerName}
						/>
						<DetailsBox
							title="Email Address:"
							value={transaction.customerEmail}
						/>
					</div>
					<div
						className={`row ${transaction.deliveryStatus && "border-bottom"}`}
					>
						<DetailsBox
							title="Last Visit:"
							value={getDate(transaction.lastVisit)}
						/>
						<DetailsBox
							title="Mode of Payment"
							value={transaction.modeOfPayment}
						/>
						<DetailsBox
							title="Transaction Status"
							value={transaction.status}
							cssClass={`status-${transaction.status === "Pending" ? "pending" : "success"}`}
						/>
						<DetailsBox
							title="Store of Purchase"
							value={transaction.storeOfPurchase}
						/>
					</div>
					{transaction.deliveryStatus && (
						<div className="row">
							<DetailsBox
								title="Delivery Status"
								value={transaction.deliveryStatus}
								cssClass={`status-${transaction.deliveryStatus === "Processing" ? "pending" : "success"}`}
							/>
							<DetailsBox
								title="Delivery Address"
								value={transaction.deliveryAddress}
							/>
							{transaction.deliveryStatus === "Processing" && (
								<div
									className="btn_ btn_green mb-3 top_btn"
									onClick={() => {
										handleSubmit();
									}}
								>
									READY FOR DISPATCH
								</div>
							)}
						</div>
					)}

					<Title title="All Items Purchased" />

					<div className="list_box">
						{transaction.purchasedItem?.map((row, index) => {
							return (
								<div className="list_item" key={index}>
									<div className="title">{row.purchase}</div>
									<div className="value">
										₦{formatToCurrency(row.amount, 2)} x {row.quantity}
									</div>
								</div>
							);
						})}
					</div>
					<div className="list_box_total">
						<div className="title">Total Purchase Value</div>
						<div className="value">
							₦{formatToCurrency(transaction.totalPurchasedValue, 2)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
