import CashierService from "./cashierService";
import RewardService from "./rewardService";
import IdentityService from "./identityService";
import ProductService from "./productService";
import ResourceService from "./resourceService";
import StorageService from "./storageService";
import StoreService from "./storeService";
import TransactionService from "./transactionService";
import UserService from "./userService";
import AppointmentService from "./appointmentService";
import ServiceService from "./serviceService";
import MimicService from "./mimicService";
import AnalyticService from "./analyticService";

export const storageService = new StorageService();

export const identityService = new IdentityService();

export const storeService = new StoreService();

export const userService = new UserService();

export const transactionService = new TransactionService();

export const productService = new ProductService();

export const rewardService = new RewardService();

export const cashierService = new CashierService();

export const resourceService = new ResourceService();

export const appointmentService = new AppointmentService();

export const serviceService = new ServiceService();

export const analyticService = new AnalyticService();

export const mimicService = new MimicService();
