import React, { useState, useContext, useEffect } from "react";
import { mainFunctions } from "../../../providers/MainProvider";
import Tables from "../../../components/Tables";
import { formatToCurrency } from "../../../utils";
import { BsThreeDots } from "react-icons/bs";
import EditDeal from "../../../modals/EditDeal";
import RemoveDeal from "../../../modals/RemoveDeal";
import DeactivateDeal from "../../../modals/DeactivateDeal";

export default function ProductDeals({ deals }) {
	const {
		setModalPage,
		setModalData,
		setShowModal,
		DEACTIVATE_DEAL_MODAL,
		REMOVE_DEALS_MODAL,
	} = useContext(mainFunctions);

	const [searchKey, setSearchKey] = useState("");

	const tableColumns = [
		{
			title: "Product ID",
			dataIndex: "productId",
		},
		{
			title: "Product Name",
			dataIndex: "name",
		},
		{
			title: "Unit Price",
			dataIndex: "unitPrice",
		},
		{
			title: "Discount",
			dataIndex: "deals",
		},
		{
			title: "Status",
			dataIndex: "isDeal",
		},
		{
			title: "",
			dataIndex: "option",
		},
	];

	const [filteredTableData, setFilteredTableData] = useState(deals);
	useEffect(() => {
		setFilteredTableData(deals);
	}, [deals]);
	useEffect(() => {
		setVisibilities(() => filteredTableData.map((x) => false));
	}, [filteredTableData]);

	const [visibilities, setVisibilities] = React.useState(() =>
		filteredTableData.map((x) => false)
	);

	const handleClick = (index) => {
		const newVisibilities = [...visibilities];
		newVisibilities.map((thisVisibility, ind) => {
			index !== ind
				? (newVisibilities[ind] = false)
				: (newVisibilities[index] = !newVisibilities[index]);
			return 0;
		});
		setVisibilities(newVisibilities);
	};

	const dataSource =
		filteredTableData && filteredTableData.length > 0
			? filteredTableData.map((row, index) => {
					return {
						productId: <div>ID: {row.id}</div>,
						name: (
							<div>
								{row.product.coverImage && (
									<span style={{ marginRight: "11px" }}>
										<img
											src={row.product.coverImage}
											style={{
												width: "32px",
												height: "32px",
												objectFit: "cover",
											}}
											alt="img"
										/>
									</span>
								)}
								<span>{row.product.name}</span>
							</div>
						),
						unitPrice: <div>₦{formatToCurrency(row.product.unitPrice, 2)}</div>,
						deals: <div>{row.deals}</div>,
						isDeal: (
							<div
								className={`d-flex text-left py-3 text-small
                ${row.isDeal ? "status-success" : "status-failed"}
                `}
							>
								{row.isDeal ? "Active" : "Inactive"}
							</div>
						),
						option: (
							<div className="">
								<div className="position-relative">
									<div
										className="d-flex items-center"
										style={{ cursor: "pointer" }}
									>
										<BsThreeDots onClick={() => handleClick(index)} size={24} />
									</div>
									{visibilities[index] ? (
										<div
											className="position-absolute border border-muted px-3 w-32 bg-white"
											style={{
												right: "0",
												top: "100%",
												zIndex: "2",
												width: "150px",
											}}
										>
											<div
												onClick={() => {
													setModalPage(DEACTIVATE_DEAL_MODAL);
													setModalData(<DeactivateDeal deal={row} />);
													setShowModal(true);
												}}
												style={{ cursor: "pointer" }}
												className={`d-flex text-left py-3 
                        hover:text-blue-dark text-small
                        ${row.isDeal ? "status-failed" : "status-success"}
                        `}
											>
												{row.isDeal ? "Deactivate Deal" : "Activate Deal"}
											</div>
											<div
												onClick={async () => {
													await setModalPage(REMOVE_DEALS_MODAL);
													await setModalData(<RemoveDeal deal={row} />);
													setShowModal(true);
												}}
												style={{ cursor: "pointer" }}
												className="d-flex text-left py-3 status-failed hover:text-blue-dark text-small"
											>
												Remove Deal
											</div>
										</div>
									) : (
										""
									)}
								</div>
							</div>
						),
					};
				})
			: [];

	return (
		<div>
			<Tables
				title="Product Deals"
				dataSource={dataSource}
				columns={tableColumns}
				handleSearch={setSearchKey}
				showPagination={true}
				showPageSize={true}
				source={deals}
				setFilteredTableData={setFilteredTableData}
			/>
		</div>
	);
}
