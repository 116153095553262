import { useEffect, useRef, useState } from "react";

function DragDropFile({ image = "", handleFile }) {
	const fileTypes = ["JPEG", "PNG", "GIF"];
	const [file, setFile] = useState("");
	const [dragActive, setDragActive] = useState(false);
	const inputRef = useRef(null);

	useEffect(() => {
		setFile(image);
	}, [image]);

	// handle drag events
	const handleDrag = function (e) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActive(true);
		} else if (e.type === "dragleave") {
			setDragActive(false);
		}
	};

	// triggers when file is dropped
	const handleDrop = function (e) {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			let fileObj = [];
			fileObj.push(e.dataTransfer.files);
			setFile(URL.createObjectURL(fileObj[0][0]));
			handleFile(e.dataTransfer.files[0]);
		}
	};

	// triggers when file is selected with click
	const handleChange = function (e) {
		e.preventDefault();
		if (e.target.files && e.target.files[0]) {
			let fileObj = [];
			fileObj.push(e.target.files);
			setFile(URL.createObjectURL(fileObj[0][0]));
			handleFile(e.target.files[0]);
		}
	};

	// triggers the input when the button is clicked
	const onButtonClick = () => {
		inputRef.current.click();
	};

	return (
		<>
			<div id="form-file-upload" onDragEnter={handleDrag}>
				<input
					ref={inputRef}
					type="file"
					types={fileTypes}
					id="input-file-upload"
					onChange={handleChange}
				/>
				<label
					id="label-file-upload"
					htmlFor="input-file-upload"
					className={dragActive ? "drag-active" : ""}
				>
					<div>
						<p>Drag and drop your file here or</p>
						<button className="upload-button" onClick={onButtonClick}>
							Upload a file
						</button>
					</div>
				</label>
				{dragActive && (
					<div
						id="drag-file-element"
						onDragEnter={handleDrag}
						onDragLeave={handleDrag}
						onDragOver={handleDrag}
						onDrop={handleDrop}
					></div>
				)}
			</div>

			{file && (
				<div className="preview-image-container">
					<img src={file} className="img" alt="..." />
				</div>
			)}
		</>
	);
}

export default DragDropFile;
