import React, { useContext, useEffect, useState } from "react";
import ActivePurchases from "../../components/ActivePurchases";
import EmptyPurchases from "../../components/EmptyPurchases";
import Tables from "../../components/Tables";
import TopBar from "../../components/TopBar";
import UsePurchaseCodeModal from "../../modals/UsePurchaseCode";
import { formatToCurrency, showToast } from "../../utils";
import { mainFunctions } from "../../providers/MainProvider";
import ShoppingBag from "../../assets/icons/shopping_bag.svg";
import PurchaseDetailsModal from "../../modals/PurchaseDetails";
import { useGuard } from "../../hooks/useGuard";
import { useDispatch, useSelector } from "react-redux";
import {
	getCheckoutDataAsync,
	getCompleteCashOrderAsync,
	getImportPurchaseDetailsAsync,
	getProductsAsync,
	getTransactionStatusAsync,
	getUserInfoAsync,
	updateCartAsync,
} from "../../redux/actions";

export default function Dashboard() {
	useGuard();
	const dispatch = useDispatch();
	const { products } = useSelector((state) => state.product);
	const { loading, userInfo } = useSelector((state) => state.cashier);
	useEffect(() => {
		dispatch(getUserInfoAsync());
		dispatch(getProductsAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const {
		setShowModal,
		setModalPage,
		USE_PURCHASECODE_MODAL,
		PURCHASE_DETAILS_MODAL,
		setModalData,
	} = useContext(mainFunctions);
	const [purchases, setPurchases] = useState([]);
	const [activeTab, setActiveTab] = useState("");

	const tableColumns = [
		{
			title: "Product ID",
			dataIndex: "id",
		},
		{
			title: "Product Name",
			dataIndex: "name",
			search: true,
		},
		{
			title: "Unit Price",
			dataIndex: "price",
			search: false,
		},
		{
			title: "",
			dataIndex: "action",
		},
	];
	let [filteredTableData, setFilteredTableData] = useState(products);

	const dataSource =
		filteredTableData && filteredTableData.length > 0
			? filteredTableData.map((row) => {
					return {
						id: row.id,
						name: (
							<div className="flex items-center">
								{row.coverImage && (
									<span style={{ marginRight: "11px" }}>
										<img
											src={row.coverImage}
											className="w-8 h-8 object-cover rounded-sm"
											alt="img"
										/>
									</span>
								)}
								<span>{row.name}</span>
							</div>
						),
						price: <div>₦{formatToCurrency(row.unitPrice, 2)}</div>,
						action: (
							<div
								className="bg-dark-gray2 flex w-max p-2 border !border-glow rounded-circle cursor-pointer"
								onClick={() => addPurchaseItem(row)}
							>
								<img src={ShoppingBag} alt="img" />
							</div>
						),
					};
				})
			: [];

	const checkPaymentStatus = (data) => {
		dispatch(
			getTransactionStatusAsync(data.checkoutData.purchaseCode, (res) => {
				if (res.data.paymentStatus === "Pending") {
					showToast("Pending Invoice", "info");
				} else {
					showToast("Paid Invoice", "success");
					let temp = [...purchases];
					const index = temp.indexOf(data);
					if (index > -1) temp.splice(index, 1);
					setPurchases(temp);
					if (temp.length > 0) {
						setActiveTab(temp[0]);
					}
					setShowModal(false);
				}
			})
		);
	};

	const addPurchaseItem = async (item) => {
		let temp = [...purchases];
		const index = temp.indexOf(activeTab);
		if (index > -1) {
			temp[index].items.push({
				id: item.id,
				productName: item.name,
				price: item.unitPrice,
				image: item.coverImage,
				quantity: 1,
			});
			temp[index].status = "updated";
			temp[index].cartStatus = "updated";
			setPurchases(temp);
			return;
		}
		const result = await addPurchase();
		let tempPurchases = [...result];
		setActiveTab(tempPurchases[0]);
		tempPurchases[0].items.push({
			id: item.id,
			productName: item.name,
			price: item.unitPrice,
			image: item.coverImage,
			quantity: 1,
		});
		setPurchases(tempPurchases);
	};
	const addPurchase = () => {
		return new Promise((resolve) => {
			let temp = [...purchases];
			let lastPurchase = temp.slice(-1).pop();
			if (temp.length < 5) {
				temp.push({
					id: lastPurchase ? lastPurchase.id + 1 : 1,
					items: [],
					status: "updated",
					cartStatus: "updated",
					checkoutData: {},
				});
			} else {
				//show error here to delete a purchase first
			}
			setPurchases(temp);
			resolve(temp);
		});
	};
	const updateCart = () => {
		let postData = {
			cartReq: [],
			subStoreId: userInfo.subStoreId,
		};
		if (activeTab.checkoutData.purchaseCode) {
			postData = {
				...postData,
				purchaseCode: activeTab.checkoutData.purchaseCode,
			};
		}
		activeTab.items.map((item) =>
			postData.cartReq.push({
				quantity: item.quantity,
				productId: item.id,
			})
		);
		dispatch(
			updateCartAsync(postData, (res) => {
				let temp = [...purchases];
				const purchaseIndex = temp.indexOf(activeTab);
				if (!loading) {
					temp[purchaseIndex].cartStatus = "fetched";
					temp[purchaseIndex].checkoutData = res;
					setPurchases(temp);
					setActiveTab(temp[purchaseIndex]);
				}
			})
		);
		return;
	};
	const handleProceed = () => {
		let postData = {
			cartReq: [],
			subStoreId: userInfo.subStoreId,
		};
		if (activeTab.checkoutData.purchaseCode) {
			postData = {
				...postData,
				purchaseCode: activeTab.checkoutData.purchaseCode,
			};
		}
		activeTab.items.map((item) =>
			postData.cartReq.push({
				quantity: item.quantity,
				productId: item.id,
			})
		);
		if (activeTab.status === "updated") {
			dispatch(
				getCheckoutDataAsync(postData, (res) => {
					let temp = [...purchases];
					const purchaseIndex = temp.indexOf(activeTab);
					temp[purchaseIndex].status = "fetched";
					setModalPage(PURCHASE_DETAILS_MODAL);
					if (!loading) {
						temp[purchaseIndex].checkoutData = res;
						setPurchases(temp);
						setActiveTab(temp[purchaseIndex]);
						setModalData(
							<PurchaseDetailsModal
								confirmPurchase={confirmPurchase}
								data={activeTab}
								deletePurchase={deletePurchase}
								checkPaymentStatus={checkPaymentStatus}
							/>
						);
					}
					setShowModal(true);
				})
			);
		} else {
			setModalPage(PURCHASE_DETAILS_MODAL);
			setModalData(
				<PurchaseDetailsModal
					confirmPurchase={confirmPurchase}
					data={activeTab}
					deletePurchase={deletePurchase}
					checkPaymentStatus={checkPaymentStatus}
				/>
			);
			setShowModal(true);
		}
		return;
	};
	const confirmPurchase = (data) => {
		let submitData = {
			purchaseCode: data.checkoutData.purchaseCode,
			paymentOptionId: 1,
		};
		dispatch(
			getCompleteCashOrderAsync(submitData, (res) => {
				let temp = [...purchases];
				const index = temp.indexOf(data);
				if (index > -1) temp.splice(index, 1);
				setPurchases(temp);
				if (temp.length > 0) {
					setActiveTab(temp[0]);
				}
				setShowModal(false);
			})
		);
	};
	const deletePurchase = (data) => {
		let temp = [...purchases];
		const index = temp.indexOf(data);
		if (index > -1) temp.splice(index, 1);
		setPurchases(temp);
		if (temp.length > 0) {
			setActiveTab(temp[0]);
		}
		setShowModal(false);
	};
	const deletePurchaseItem = (item) => {
		let temp = [...purchases];
		const purchaseIndex = temp.indexOf(activeTab);
		const itemIndex = temp[purchaseIndex].items.indexOf(item);
		if (itemIndex > -1) {
			temp[purchaseIndex].status = "updated";
			temp[purchaseIndex].cartStatus = "updated";
			temp[purchaseIndex].items.splice(itemIndex, 1);
		}
		setPurchases(temp);
	};
	const showUsePurchaseCodeModal = () => {
		setModalPage(USE_PURCHASECODE_MODAL);
		setModalData(<UsePurchaseCodeModal importPurchase={importPurchase} />);
		setShowModal(true);
	};
	const importPurchase = async (data) => {
		let temp = [...purchases];
		if (temp.length < 5) {
			dispatch(
				getImportPurchaseDetailsAsync(data, async (res) => {
					const result = await addPurchase();
					let lastPurchase = result.slice(-1).pop();
					const purchaseIndex = result.indexOf(lastPurchase);
					res.data?.purchases.map((item) =>
						result[purchaseIndex].items.push({
							id: item.products.id,
							productName: item.products.name,
							price: item.products.unitPrice,
							image: item.products.coverImage,
							quantity: item.quantity,
						})
					);
					result[purchaseIndex].status = "fetched";
					result[purchaseIndex].cartStatus = "fetched";
					result[purchaseIndex].checkoutData = res.data;
					setPurchases(result);
					setActiveTab(result[purchaseIndex]);
				})
			);
			setShowModal(false);
		} else {
			//show error to delete a cart first
		}
	};
	const increaseQty = (item) => {
		let temp = [...purchases];
		const purchaseIndex = temp.indexOf(activeTab);
		const itemIndex = temp[purchaseIndex].items.indexOf(item);
		if (itemIndex > -1) {
			temp[purchaseIndex].items[itemIndex].quantity++;
			temp[purchaseIndex].status = "updated";
			temp[purchaseIndex].cartStatus = "updated";
		}
		setPurchases(temp);
	};
	const decreaseQty = (item) => {
		let temp = [...purchases];
		const purchaseIndex = temp.indexOf(activeTab);
		const itemIndex = temp[purchaseIndex].items.indexOf(item);
		if (itemIndex > -1) {
			temp[purchaseIndex].items[itemIndex].quantity =
				temp[purchaseIndex].items[itemIndex].quantity - 1;
			if (temp[purchaseIndex].items[itemIndex].quantity <= 0) {
				temp[purchaseIndex].items.splice(itemIndex, 1);
			}
			temp[purchaseIndex].status = "updated";
			temp[purchaseIndex].cartStatus = "updated";
		}
		setPurchases(temp);
	};
	useEffect(() => {
		if (activeTab?.cartStatus === "updated") {
			updateCart();
		}
		// const purchaseIndex = purchases.indexOf(activeTab);
		if (!activeTab && purchases.length > 0) {
			setActiveTab(purchases[0]);
		}
		if (!(purchases.length > 0)) {
			setActiveTab("");
		}
		//eslint-disable-next-line
	}, [purchases]);

	useEffect(() => {
		setFilteredTableData(products.filter((item) => item.isOutOfStock !== true));
	}, [products]);
	return (
		<div className="w-100 font-dmsans">
			{/* <Sidebar /> */}
			<div className="cashier-main w-100">
				<TopBar
					title="All Products"
					button={{
						title: "Use Customer Purchase Code",
					}}
					buttonAction={showUsePurchaseCodeModal}
				/>
				<div className="row h-100 w-100">
					<div className="col px-5 bg-dark-gray3">
						<Tables
							columns={tableColumns}
							dataSource={dataSource}
							showPagination={true}
							handleSearch={true}
							source={products}
							setFilteredTableData={setFilteredTableData}
						/>
					</div>
					<div className="col bg-dark-gray2">
						{purchases.length > 0 ? (
							<ActivePurchases
								purchases={purchases}
								addPurchase={addPurchase}
								handleProceed={handleProceed}
								deletePurchase={deletePurchase}
								activeTab={activeTab}
								setActiveTab={setActiveTab}
								deletePurchaseItem={deletePurchaseItem}
								decreaseQty={decreaseQty}
								increaseQty={increaseQty}
							/>
						) : (
							<EmptyPurchases />
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
