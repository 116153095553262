import React, { useRef, useState } from "react";
import ImageRemove from "../assets/icons/image-upload-remove.svg";
import { showToast } from "../utils";

function ImageUploadPreview({
	tempImages,
	setTempImages,
	oldImages = [],
	setOldImages,
}) {
	const [file, setFile] = useState(tempImages ? tempImages : []);
	const fileRef = useRef();

	const uploadMultipleFiles = (e) => {
		let fileObj = [];
		let fileArray = [...file];
		fileObj.push(e.target.files);
		for (let i = 0; i < fileObj[0].length; i++) {
			fileArray.push(URL.createObjectURL(fileObj[0][i]));
		}
		if (fileArray.length + oldImages.length > 5) {
			return showToast("Max of 5 images for a product", "error");
		}
		setFile(fileArray);
		setTempImages([...tempImages, ...e.target.files]);
	};

	const removeImage = (url) => {
		const items = file.filter((item) => item !== url);
		setFile(items);
		// setTempImages(items);
	};

	const removeOldImage = (url) => {
		const items = oldImages.filter((item) => item !== url);
		setOldImages(items);
	};

	return (
		<form>
			<div className="form-group">
				<input
					hidden
					ref={fileRef}
					type="file"
					accept="image/x-png,image/jpg,image/jpeg"
					className="form-control"
					onChange={uploadMultipleFiles}
					multiple
				/>
			</div>
			<div className="fieldset">
				<label className="label">Product Image</label>
				<div className="input_box align-items-center">
					<div className="ml-3">
						{(file.length || oldImages.length) > 0
							? `Selected ${file.length + oldImages.length} ${file.length + oldImages.length === 1 ? "image" : "images"}`
							: "Add up to 5 Images"}
					</div>
					<button
						type="button"
						className="btn btn-success btn-block"
						onClick={() => fileRef.current.click()}
					>
						SELECT
					</button>
				</div>
			</div>
			<div className="d-flex flex-wrap multi-preview">
				{oldImages?.map((url, index) => (
					<div key={index} className="preview-image-container">
						<img src={url} className="img" alt="..." />
						<img
							src={ImageRemove}
							className="remove"
							alt="remove"
							onClick={() => removeOldImage(url)}
						/>
					</div>
				))}
				{file.map((url, index) => (
					<div key={index} className="preview-image-container">
						<img src={url} className="img" alt="..." />
						<img
							src={ImageRemove}
							className="remove"
							alt="remove"
							onClick={() => removeImage(url)}
						/>
					</div>
				))}
			</div>
		</form>
	);
}

export default ImageUploadPreview;
