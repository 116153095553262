import * as types from "../types";

const initialState = {
	loading: false,
	error: null,
	userInfo: {},
	transactions: [],
	transaction: {},
};

const CashierReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.CHECK_OUT_DATA.REQUEST:
		case types.COMPLETE_CASH_ORDER.REQUEST:
		case types.UPDATE_CART.REQUEST:
		case types.IMPORTED_PURCHASE_DETAILS.REQUEST:
		case types.GET_USER_INFO.REQUEST:
		case types.GET_CASHIER_TRANSACTIONS.REQUEST:
		case types.GET_CASHIER_TRANSACTION.REQUEST:
			return { ...state, loading: true };
		case types.CHECK_OUT_DATA.SUCCESS:
		case types.COMPLETE_CASH_ORDER.SUCCESS:
		case types.UPDATE_CART.SUCCESS:
		case types.IMPORTED_PURCHASE_DETAILS.SUCCESS:
			return { ...state, loading: false };
		case types.GET_USER_INFO.SUCCESS:
			return { ...state, loading: false, userInfo: payload };
		case types.GET_CASHIER_TRANSACTIONS.SUCCESS:
			return { ...state, loading: false, transactions: payload };
		case types.GET_CASHIER_TRANSACTION.SUCCESS:
			return { ...state, loading: false, transaction: payload };
		case types.CHECK_OUT_DATA.FAILURE:
		case types.COMPLETE_CASH_ORDER.FAILURE:
		case types.IMPORTED_PURCHASE_DETAILS.FAILURE:
		case types.UPDATE_CART.FAILURE:
		case types.GET_USER_INFO.FAILURE:
		case types.GET_CASHIER_TRANSACTIONS.FAILURE:
		case types.GET_CASHIER_TRANSACTION.FAILURE:
			return { ...state, loading: false, error: payload };
		default:
			return state;
	}
};

export default CashierReducer;
