import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { mainFunctions } from "../providers/MainProvider";
import { deleteCategoryAsync, getCategoriesAsync } from "../redux/actions";

const DeleteCategory = ({ data }) => {
	const dispatch = useDispatch();
	const { setShowModal } = useContext(mainFunctions);
	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(
			deleteCategoryAsync(data.id, async (_) => {
				dispatch(getCategoriesAsync());
				setShowModal(false);
			})
		);
	};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">{"Delete Category"}</div>
			<p className="subtitle text-center">
				Are you sure you want to delete this category?
			</p>
			<p className="subtitle text-center">
				<b>{data.name}</b>
			</p>
			<div className="auth_form_container">
				<div className="btn_ btn_orange" onClick={handleSubmit}>
					Delete Category
				</div>
			</div>
		</div>
	);
};

export default DeleteCategory;
