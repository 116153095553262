import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mainFunctions } from "../providers/MainProvider";
import {
	addServiceAsync,
	addServiceCategoryAsync,
	getCategoriesAsync,
	getServiceCategoriesAsync,
	getServicesAsync,
} from "../redux/actions";
import { validText } from "../utils";

const AddStaffModal = () => {
	const dispatch = useDispatch();
	const { services, categories, loading } = useSelector(
		(state) => state.service
	);
	const [allCategories, setallCategories] = useState([]);
	const [serviceData, setserviceData] = useState({
		name: "",
		description: "",
		serviceCategoryId: 0,
	});

	const { setShowModal } = useContext(mainFunctions);

	const [userId, setUserId] = useState("");
	const updateServiceData = (name, value) => {
		setserviceData({
			...serviceData,
			[name]: value,
		});
	};
	const handleSubmit = () => {
		dispatch(
			addServiceAsync(serviceData, async (_) => {
				dispatch(getServicesAsync());
				setShowModal(false);
			})
		);
	};

	useEffect(() => {
		if (typeof categories.data !== "undefined") {
			if (typeof categories.data.data !== "undefined") {
				setallCategories(categories.data.data);
			}
		}
	}, [categories]);

	useEffect(() => {
		dispatch(getServiceCategoriesAsync());
	}, []);

	const validValues = () => {
		let v =
			validText(serviceData.name) &&
			validText(serviceData.description) &&
			serviceData.serviceCategoryId !== 0;
		return v;
		//   name: '',
		//   description: '',
		//   serviceCategoryId: 0
		// }
	};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">Add a Service</div>
			<p className="subtitle text-center"></p>
			<div className="auth_form_container">
				<div className="fieldset">
					<label className="label">Service Name</label>
					<div className="input_box">
						<input
							type="text"
							onChange={(e) => updateServiceData("name", e.target.value)}
						></input>
					</div>
				</div>

				<div className="fieldset">
					<label className="label">Service Category</label>
					<div className="input_box">
						{/* {JSON.stringify(allCategories)} */}
						<select
							onChange={(e) => {
								updateServiceData("serviceCategoryId", e.target.value);
							}}
							defaultValue={0}
						>
							<option disabled value={0}>
								Select A Category
							</option>
							{allCategories.map((category, index) => {
								return <option value={category.id}>{category.name}</option>;
							})}
						</select>
					</div>
				</div>

				<div className="fieldset">
					<label className="label">Description</label>
					<div className="input_box">
						<textarea
							onChange={(e) => updateServiceData("description", e.target.value)}
						></textarea>
					</div>
				</div>

				<div
					className={`btn_ ${validValues() ? "btn_orange" : "btn_dumb"}`}
					onClick={() => {
						if (validValues()) {
							handleSubmit();
						}
					}}
				>
					{" "}
					Add Service
				</div>
			</div>
		</div>
	);
};

export default AddStaffModal;
