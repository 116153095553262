import { generateActions } from "../../utils";

export const GET_CATEGORIES = generateActions("GET_CATEGORIES");
export const CREATE_CATEGORY = generateActions("CREATE_CATEGORY");
export const UPDATE_CATEGORY = generateActions("UPDATE_CATEGORY");
export const DELETE_CATEGORY = generateActions("DELETE_CATEGORY");
export const GET_PRODUCTS = generateActions("GET_PRODUCTS");
export const CREATE_PRODUCT = generateActions("CREATE_PRODUCT");
export const UPDATE_PRODUCT = generateActions("UPDATE_PRODUCT");
export const DELETE_PRODUCT = generateActions("DELETE_PRODUCT");
export const UPDATE_PRODUCT_STATUS = generateActions("UPDATE_PRODUCT_STATUS");
