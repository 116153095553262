import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendSupportMessageAsync } from "../redux/actions";

export default function Support() {
	const dispatch = useDispatch();
	const { loading } = useSelector((state) => state.auth);

	const [postData, setPostData] = useState({
		message: "",
	});
	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(
			sendSupportMessageAsync(postData, async (_) => {
				setPostData({
					message: "",
				});
			})
		);
	};
	return (
		<div className="auth_form" style={{ boxShadow: "unset" }}>
			<div className="auth_form_container">
				<div className="fieldset">
					<label className="label">Message</label>
					<div className="input_box">
						<textarea
							value={postData.message}
							onChange={(e) => setPostData({ message: e.target.value })}
							className="support_message"
						></textarea>
					</div>
				</div>
				<div
					className="btn_ btn_orange"
					onClick={!loading ? handleSubmit : () => {}}
				>
					{loading ? (
						<div className="spinner-border" role="status"></div>
					) : (
						"Send Message"
					)}
				</div>
			</div>
		</div>
	);
}
