import * as types from "../types";

const initialState = {
	loading: false,
	error: null,
	growthAccounting: [],
};

const AnalyticReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.GET_GROWTH_ACCOUNTING.REQUEST:
			return { ...state, loading: true };

		case types.GET_GROWTH_ACCOUNTING.SUCCESS:
			return { ...state, loading: false, growthAccounting: payload };

		case types.GET_GROWTH_ACCOUNTING.FAILURE:
			return { ...state, loading: false, error: payload };

		default:
			return state;
	}
};

export default AnalyticReducer;
