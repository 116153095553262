import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGuard } from "../../hooks/useGuard";
import { mainFunctions } from "../../providers/MainProvider";
import {
	getServiceCategoriesAsync,
	getServicesAsync,
} from "../../redux/actions";
import Sidebar from "../../components/Sidebar";
import TopBar from "../../components/TopBar";
import Tables from "../../components/Tables";
import AddCategory from "../../modals/AddCategory";
import AddService from "../../modals/AddService";

export default function StoreTables() {
	const { services } = useSelector((state) => state.service);
	const [allServices, setAllServices] = useState([]);
	useGuard();
	const dispatch = useDispatch();

	const { setShowModal, setModalData } = useContext(mainFunctions);

	useEffect(() => {
		dispatch(getServicesAsync());
		dispatch(getServiceCategoriesAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (typeof services !== "undefined" && services !== null) {
			if (typeof services.data !== "undefined") {
				if (typeof services.data.data !== "undefined") {
					setAllServices(services.data.data);
				}
			}
		}
	}, [services]);

	const tableColumns = [
		{
			title: "ID",
			dataIndex: "id",
		},
		{
			title: "Service",
			dataIndex: "name",
		},
		{
			title: "Service Category",
			dataIndex: "serviceCategory",
		},
		{
			title: "Description",
			dataIndex: "description",
		},
	];

	const [filteredTableData, setFilteredTableData] = useState(allServices);

	const dataSource =
		filteredTableData && filteredTableData.length > 0
			? filteredTableData.map((row) => {
					return {
						id: row.id,
						name: <div>{row.name}</div>,
						serviceCategory: (
							<div>
								{typeof row.serviceCategory !== "undefined" && (
									<div>{row.serviceCategory.name}</div>
								)}
							</div>
						),
						description: <div>{row.description}</div>,
					};
				})
			: [];

	useEffect(() => {
		setFilteredTableData(allServices);
	}, [allServices]);

	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar
					title="Services"
					button={{
						title: "Add A Service",
					}}
					buttonAction={async () => {
						await setModalData(<AddService />);
						await setShowModal(true);
					}}
					alt_button={{
						title: "Add A Category",
					}}
					alt_buttonAction={async () => {
						await setModalData(<AddCategory />);
						await setShowModal(true);
					}}
				/>
				<div className="mainbar-container">
					<Tables
						title="All Services"
						columns={tableColumns}
						dataSource={dataSource}
						handleSearch={true}
						showPagination={true}
						source={allServices}
						setFilteredTableData={setFilteredTableData}
					/>
				</div>
			</div>
		</div>
	);
}
