import React, { useContext } from "react";
import { mainFunctions } from "../../providers/MainProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { MdDateRange } from "react-icons/md";
import { convertNumberToDateTime } from "../../utils";
import Sidebar from "../../components/Sidebar";
import TopBar from "../../components/TopBar";
import DetailsBox from "../../components/DetailsBox";
import EditResource from "../../modals/EditResource";
import CalendarViewResources from "../../modals/CalendarViewResources";
import DeleteResources from "../../modals/DeleteResources";

export default function ResourceDetails() {
	const navigate = useNavigate();
	const { state } = useLocation();
	const { setShowModal, setModalData } = useContext(mainFunctions);

	const printServices = (services) => {
		let m = "";
		if (typeof services === "object") {
			services.forEach((service, index) => {
				m = m + `${service.serviceId} `;
				if (index + 1 !== services.length) {
					m = m + ",";
				}
			});
		}
		return m;
	};
	const removeTime = (date) => {
		if (date !== null && date.toString().length > 5) return date.slice(0, 10);
		else return "-";
	};
	const printAddons = (row) => {
		let m = "";
		let r = [row.walkIn, row.homeService];
		r.forEach((addOn, index) => {
			if (addOn === true) {
				index < 1 ? (m = m + "Walk-In") : (m = m + "Home Service");

				if (index + 1 !== r.length) {
					m = m + ", ";
				}
			}
		});
		return m;
	};

	const getAvailableDays = (type = "weekdays") => {
		let { monday, tuesday, wednesday, thursday, friday, saturday, sunday } =
			state.row.resourceAvailability;
		let availableDays = "";
		let i;
		if (type === "weekdays") {
			i = [
				{ day: "Monday", value: monday },
				{ day: "Tuesday", value: tuesday },
				{ day: "Wednesday", value: wednesday },
				{ day: "Thursday", value: thursday },
				{ day: "Friday", value: friday },
			];
		} else {
			i = [
				{ day: "Saturday", value: saturday },
				{ day: "Sunday", value: sunday },
			];
		}
		i.forEach((day, index) => {
			if (day.value === true) {
				availableDays = availableDays + day.day;
				if (index + 1 !== i.length) {
					availableDays = availableDays + ", ";
				}
			}
		});
		return availableDays;
	};

	const getAvailableHours = (type = "weekdays") => {
		// let {checkInTime, checkOutTime, breakTimeStart, breakTimeEnd}
		let {
			monday,
			tuesday,
			wednesday,
			thursday,
			friday,
			saturday,
			sunday,
			weekdaysCheckIn,
			weekdaysCheckOut,
			weekendCheckIn,
			weekendCheckOut,
			weekDaysBreakTimeEnd,
			weekDaysBreakTimeStart,
			weekendBreakTimeEnd,
			weekendBreakTimeStart,
		} = state.row.resourceAvailability;
		let dailyHours = 0;
		let activeDays = 0;
		let breaktime = 0;
		if (type === "weekdays") {
			dailyHours = Number(weekdaysCheckOut) - Number(weekdaysCheckIn);
			breaktime = Number(weekDaysBreakTimeEnd) - Number(weekDaysBreakTimeStart);

			let i = [monday, tuesday, wednesday, thursday, friday];
			i.forEach((day, index) => {
				if (day === true) {
					activeDays = activeDays + 1;
				}
			});
		} else {
			dailyHours = Number(weekendCheckOut) - Number(weekendCheckIn);
			breaktime = weekendBreakTimeEnd - weekendBreakTimeStart;
			let i = [saturday, sunday];
			i.forEach((day, index) => {
				if (day === true) {
					activeDays = activeDays + 1;
				}
			});
		}
		return activeDays * (dailyHours - breaktime);
	};

	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar
					title="Resource Details"
					button={{
						title: "EDIT RESOURCE",
					}}
					buttonAction={async () => {
						setModalData(<EditResource row={state.row} />);
						setShowModal(true);
						await setShowModal(true);
					}}
					alt_button={{
						title: "REMOVE RESOURCE",
					}}
					alt_buttonAction={async () => {
						const goBack = () => {
							navigate(`../${state.type}`);
						};
						setModalData(<DeleteResources row={state.row} goBack={goBack} />);
						setShowModal(true);
					}}
				/>

				{typeof state.row !== "undefined" && state.row && (
					<div className="full-mainbar-container">
						<div className="row">
							<DetailsBox
								title="Resource ID"
								value={`ID: ${state.row.id}`}
							></DetailsBox>
							<DetailsBox
								title="Resource Name:"
								value={`${state.row.tableName ? state.row.tableName : state.row.firstName + " " + state.row.lastName}`}
							></DetailsBox>

							{state.row.discriminator !== "TableResouce" && (
								<DetailsBox
									title="Phone Number"
									value={`${state.row.phoneNumber}`}
								></DetailsBox>
							)}

							{/* {state.row.discriminator !== "TableResouce" &&
                            <DetailsBox
                                title="Phone Number"
                                value="+234 8135849068"
                            ></DetailsBox>
                        } */}
						</div>

						<div className="divider_box">
							<span className="line"></span>
						</div>

						<div className="row">
							{typeof state.row.services !== "undefined" && (
								<DetailsBox
									title="Service:"
									value={printServices(state.row.services)}
								></DetailsBox>
							)}
							{typeof state.row.services !== "undefined" && (
								<DetailsBox
									title="Service Type:"
									value={printAddons(state.row)}
								></DetailsBox>
							)}
							<DetailsBox
								title="Weekly Resource Time"
								value={
									<div className="flex_field justify_left">
										50hrs
										<div
											className="btn_ btn_white"
											onClick={async () => {
												await setModalData(
													<CalendarViewResources
														availability={state.row.resourceAvailability}
													/>
												);
												await setShowModal(true);
											}}
										>
											<MdDateRange /> Calendar View
										</div>
									</div>
								}
							></DetailsBox>
							<DetailsBox
								title="Date Joined:"
								value={removeTime(state.row.createdAt).replaceAll("-", "/")}
							></DetailsBox>
						</div>

						<div className="divider_box">
							<span className="line"></span>
						</div>

						<div className="row">
							<DetailsBox
								title="Days Available:"
								value={getAvailableDays("weekdays")}
							></DetailsBox>
							<DetailsBox
								title="Available Hours"
								value={getAvailableHours("weekdays")}
							></DetailsBox>
							<DetailsBox
								title="Available Times"
								value={`${convertNumberToDateTime(state.row.resourceAvailability.weekdaysCheckIn)} - ${convertNumberToDateTime(state.row.resourceAvailability.weekdaysCheckOut)} `}
							></DetailsBox>
							<DetailsBox
								title="Break Time"
								value={`${convertNumberToDateTime(state.row.resourceAvailability.weekDaysBreakTimeStart)} - ${convertNumberToDateTime(state.row.resourceAvailability.weekDaysBreakTimeEnd)} `}
							></DetailsBox>
						</div>
						<div className="divider_box">
							<span className="line"></span>
						</div>
						<div className="row">
							<DetailsBox
								title="Days Available:"
								value={getAvailableDays("weekend")}
							></DetailsBox>
							<DetailsBox
								title="Available Hours:"
								value={getAvailableHours("weekend")}
							></DetailsBox>
							<DetailsBox
								title="Available Times:"
								value={`${convertNumberToDateTime(state.row.resourceAvailability.weekendCheckIn)} - ${convertNumberToDateTime(state.row.resourceAvailability.weekendCheckOut)} `}
							></DetailsBox>
							<DetailsBox
								title="Break Time:"
								value={`${convertNumberToDateTime(state.row.resourceAvailability.weekendBreakTimeStart)} - ${convertNumberToDateTime(state.row.resourceAvailability.weekendBreakTimeEnd)} `}
							></DetailsBox>
						</div>

						{/* <div className='divider_box'>
                        <span className='line'></span>
                    </div>

                    <div className='row'>
                        <DetailsBox
                            title="Services Done:"
                            value="2,000"
                        ></DetailsBox>
                        <DetailsBox
                            title="Weekend Hours:"
                            value="4.8"
                        ></DetailsBox>
                    </div> */}
					</div>
				)}
			</div>
		</div>
	);
}
