import * as types from "../types";

const initialState = {
	loading: false,
	error: null,
	all_roles: [],
	FirstName: null,
	LastName: null,
	UserName: null,
	companyInfo: {},
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.LOGIN.REQUEST:
		case types.SSOLOGIN.REQUEST:
		case types.CHANGE_PASSWORD.REQUEST:
		case types.FORGOT_PASSWORD.REQUEST:
		case types.RESET_PASSWORD.REQUEST:
		case types.FETCH_ALL_ROLES.REQUEST:
		case types.FETCH_COMPANY_INFO.REQUEST:
		case types.UPDATE_COMPANY_INFO.REQUEST:
		case types.SEND_ADMIN_SUPPORT.REQUEST:
			return { ...state, loading: true };
		case types.LOGIN.SUCCESS:
		case types.SSOLOGIN.SUCCESS:
			return { ...state, loading: false, ...payload };
		case types.FORGOT_PASSWORD.SUCCESS:
		case types.RESET_PASSWORD.SUCCESS:
		case types.CHANGE_PASSWORD.SUCCESS:
		case types.UPDATE_COMPANY_INFO.SUCCESS:
		case types.SEND_ADMIN_SUPPORT.SUCCESS:
			return { ...state, loading: false };
		case types.FETCH_COMPANY_INFO.SUCCESS:
			return { ...state, loading: false, companyInfo: payload };
		case types.FETCH_ALL_ROLES.SUCCESS:
			return { ...state, loading: false, all_roles: [...payload] };
		case types.LOGIN.FAILURE:
		case types.SSOLOGIN.FAILURE:
		case types.CHANGE_PASSWORD.FAILURE:
		case types.FORGOT_PASSWORD.FAILURE:
		case types.RESET_PASSWORD.FAILURE:
		case types.FETCH_ALL_ROLES.FAILURE:
		case types.FETCH_COMPANY_INFO.FAILURE:
		case types.UPDATE_COMPANY_INFO.FAILURE:
		case types.SEND_ADMIN_SUPPORT.FAILURE:
			return { ...state, loading: false, error: payload };
		default:
			return state;
	}
};

export default reducer;
