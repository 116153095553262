export function StarIcon(props) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00002 0C6.25377 0 6.48551 0.144048 6.59782 0.371584L8.09086 3.39631L11.4298 3.88434C11.6808 3.92104 11.8893 4.09704 11.9675 4.33838C12.0458 4.57972 11.9803 4.84455 11.7985 5.02157L9.38303 7.37424L9.95309 10.698C9.99599 10.9481 9.89316 11.2009 9.68784 11.35C9.48252 11.4992 9.21033 11.5188 8.98572 11.4007L6.00002 9.83057L3.01432 11.4007C2.78971 11.5188 2.51752 11.4992 2.3122 11.35C2.10688 11.2009 2.00405 10.9481 2.04695 10.698L2.617 7.37424L0.201531 5.02157C0.0197855 4.84455 -0.0457297 4.57972 0.032522 4.33838C0.110774 4.09704 0.319227 3.92104 0.570267 3.88434L3.90918 3.39631L5.40221 0.371584C5.51453 0.144048 5.74627 0 6.00002 0Z"
        fill="#FFC65C"
      />
    </svg>
  );
}
