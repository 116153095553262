import axios from "./axios";

export default class appointmentService {
	async addCategory(data) {
		const response = await axios.post(`api/service/category`, data);
		return response;
	}

	async addService(data) {
		const response = await axios.post(`api/service/service`, data);
		return response;
	}

	async getServices() {
		const response = await axios.get(`api/service/services`);
		return response;
	}

	async getCategories() {
		const response = await axios.get(`api/service/categories`);
		return response;
	}

	async getServicePersonnel(serviceId) {
		const response = await axios.get(
			`api/service/service-personnels?serviceId=${serviceId}`
		);
		return response;
	}
}
