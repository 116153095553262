import axios from "./axios";

export default class CashierService {
	async checkout(data) {
		const response = await axios.post(`api/purchase/check-out`, data);

		return response;
	}
	async getUserInfo() {
		const response = await axios.get(`api/auth/user-info`);

		return response;
	}
	async updateCart(data) {
		const response = await axios.patch(`api/purchase/cart`, data);

		return response;
	}
	async completeOrder(data) {
		const response = await axios.post(
			`api/purchase/instore-make-payment`,
			data
		);

		return response;
	}
	async importPurchaseDetails(purchaseCode) {
		const response = await axios.get(
			`api/purchase/items-by-purchaseCode?purchaseCode=${purchaseCode}`
		);

		return response;
	}
	async getCashierTransactions() {
		const response = await axios.get(`api/transaction/cashier-transactions`);

		return response;
	}
	async getCashierTransaction(id) {
		const response = await axios.get(
			`api/transaction/cashier-transaction?id=${id}`
		);

		return response;
	}
}
