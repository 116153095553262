import React, { useContext, useState, useEffect } from "react";
import { BsThreeDots } from "react-icons/bs";
import { mainFunctions } from "../../providers/MainProvider";
import { useGuard } from "../../hooks/useGuard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPersonnelAsync } from "../../redux/actions";
import Sidebar from "../../components/Sidebar";
import TopBar from "../../components/TopBar";
import SummaryBox from "../../components/SummaryBox";
import Tables from "../../components/Tables";
import AddAPersonnel from "../../modals/AddAPersonnel.js";
import FilterTable from "../../modals/FilterTable";
import EditResource from "../../modals/EditResource";
import DeleteResources from "../../modals/DeleteResources";
import { calculateWeeklyhours } from "../../utils";
import AnalyticsWrapper from "../../components/AnalyticsWrapper.js";

const tableColumns = [
	{
		title: "ID",
		dataIndex: "id",
	},
	{
		title: "Name",
		dataIndex: "personnelName",
	},
	{
		title: "Available Hours",
		dataIndex: "hours",
	},
	{
		title: "Home Service",
		dataIndex: "homeService",
	},
	{
		title: "Walk In",
		dataIndex: "walkIn",
	},
	{
		title: "",
		dataIndex: "option",
		search: "false",
	},
];

export default function StoreTables() {
	let navigate = useNavigate();
	useGuard();
	const dispatch = useDispatch();
	const [allPersonnel, setAllPersonnel] = useState([]);
	const { personnel } = useSelector((state) => state.resource);

	const tableStatusOptions = [
		{ title: "All", value: "" },
		{ title: "booked", value: "Booked" },
		{ title: "active", value: "Active" },
		{ title: "used", value: "Used" },
	];
	const [tableStatus, setTableStatus] = useState(tableStatusOptions[0]);

	const totalTables = 29;

	const { setShowModal, setModalData } = useContext(mainFunctions);

	const [filteredTableData, setFilteredTableData] = useState(allPersonnel);

	const [visibilities, setVisibilities] = React.useState([]);

	const handleClick = (index) => {
		const newVisibilities = [...visibilities];
		newVisibilities.map((thisVisibility, ind) => {
			index !== ind
				? (newVisibilities[ind] = false)
				: (newVisibilities[index] = !newVisibilities[index]);
			return 0;
		});
		setVisibilities(newVisibilities);
	};

	const dataSource =
		filteredTableData && filteredTableData.length > 0
			? filteredTableData.map((row, index) => {
					return {
						id: row.id,
						personnelName: (
							<div className="capitalize">
								{row.firstName} {row.lastName}
							</div>
						),
						hours: <div>{calculateWeeklyhours(row)} hrs/w</div>,
						firstName: row.firstName,
						lastName: row.lastName,
						homeService: (
							<div
								className={`status-${row.homeService ? "success" : "pending"}`}
							>
								{row.homeService ? "Available" : "Not Available"}
							</div>
						),
						walkIn: (
							<div className={`status-${row.walkIn ? "success" : "pending"}`}>
								{row.walkIn ? "Available" : "Not Available"}
							</div>
						),
						option: (
							<div className="">
								<div className="position-relative">
									<div
										className="d-flex items-center"
										style={{ cursor: "pointer" }}
									>
										<BsThreeDots onClick={() => handleClick(index)} size={24} />
									</div>
									{visibilities[index] ? (
										<div
											className="position-absolute border border-muted px-3 w-32 bg-white"
											style={{
												right: "0",
												top: "100%",
												zIndex: "2",
												width: "150px",
											}}
										>
											<div
												onClick={async () => {
													navigate(`/resource/${row.id}`, {
														state: {
															row: row,
															type: "personnel",
														},
													});
												}}
												style={{ cursor: "pointer" }}
												className="d-flex text-left py-3 border-bottom border-muted hover:text-blue-dark text-small"
											>
												View
											</div>
											<div
												onClick={async () => {
													setModalData(<EditResource row={row} />);
													setShowModal(true);
												}}
												style={{ cursor: "pointer" }}
												className="d-flex text-left py-3 border-bottom border-muted status-success hover:text-blue-dark text-small"
											>
												Edit Personnel
											</div>

											<div
												onClick={() => {
													setModalData(<DeleteResources row={row} />);
													setShowModal(true);
												}}
												style={{ cursor: "pointer" }}
												className="d-flex text-left py-3 status-failed hover:text-blue-dark text-small"
											>
												Remove
											</div>
										</div>
									) : (
										""
									)}
								</div>
							</div>
						),
					};
				})
			: [];

	useEffect(() => {
		setFilteredTableData(allPersonnel);
	}, [allPersonnel]);

	useEffect(() => {
		dispatch(getPersonnelAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (typeof personnel.data !== "undefined") {
			if (typeof personnel.data.data !== "undefined") {
				setAllPersonnel(personnel.data.data);
			}
		}
	}, [personnel]);

	useEffect(() => {
		if (visibilities.length === 0) {
			let e = filteredTableData.map((x) => false);
			setVisibilities(e);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteredTableData]);

	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar
					title="Personnel"
					button={{
						title: "Add a Personnel",
					}}
					buttonAction={async () => {
						await setModalData(<AddAPersonnel />);
						await setShowModal(true);
					}}
				/>
				<div className="mainbar-container">
					<div className="timeline_wrapper"></div>
					<AnalyticsWrapper>
						<SummaryBox
							title={"All Personnel"}
							value={allPersonnel.length}
							comma={true}
						/>
					</AnalyticsWrapper>
					<div className="col-sm-12">
						<Tables
							title="All Personnel"
							dataSource={dataSource}
							columns={tableColumns}
							setFilteredTableData={setFilteredTableData}
							source={allPersonnel}
							//Filters Props
							handleStatusFilter={false}
							// handleDateFilter={true}
							tableStatusOptions={tableStatusOptions}
							tableStatus={tableStatus}
							setTableStatus={setTableStatus}
							//search prop
							handleSearch={true}
							// pagination props
							showPagination={true}
							showPageSize={true}
							// extrabutton={{
							//   title: "Filter Table",
							//   iconClassName: "filtertable",
							//   action:
							//     async () => {
							//       await setModalData(
							//         <FilterTable />
							//       )
							//       await setShowModal(true);
							//     }
							// }}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
