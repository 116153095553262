import React, { useContext, useState } from "react";
import { mainFunctions } from "../providers/MainProvider";
import CustomDatePicker from "../components/DatePicker.js";
import { MdDateRange } from "react-icons/md";
import EditResourceDetail from "./EditResourceDetail";
import ResourceAvailability from "./ResourceAvailability";

const EditResource = () => {
	const [page, setPage] = useState(1);
	const { setShowModal } = useContext(mainFunctions);

	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center add_staff_modal">
			{page === 1 && <EditResourceDetail page={page} setPage={setPage} />}
			{page === 2 && <ResourceAvailability page={page} setPage={setPage} />}
		</div>
	);
};

export default EditResource;
