import { productService } from "../../services";
import { showToast } from "../../utils";
import * as types from "../types";
import { showErrorMessage } from "./auth.actions";

export const getCategoriesAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_CATEGORIES.REQUEST });
		try {
			const responseData = await productService.getCategories();
			dispatch({
				type: types.GET_CATEGORIES.SUCCESS,
				payload: responseData.data.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_CATEGORIES.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const createCategoryAsync = (
	categoryData,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.CREATE_CATEGORY.REQUEST });
		try {
			await productService.createCategory(categoryData);
			showToast("Category Created Successful", "info");
			dispatch({ type: types.CREATE_CATEGORY.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.CREATE_CATEGORY.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const updateCategoryAsync = (
	categoryData,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.UPDATE_CATEGORY.REQUEST });
		try {
			await productService.updateCategory(categoryData);
			showToast("Category Updated Successful", "info");
			dispatch({ type: types.UPDATE_CATEGORY.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.UPDATE_CATEGORY.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const deleteCategoryAsync = (id, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.DELETE_CATEGORY.REQUEST });
		try {
			await productService.deleteCategory(id);
			showToast("Category Deleted Successful", "info");
			dispatch({ type: types.DELETE_CATEGORY.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.DELETE_CATEGORY.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const getProductsAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_PRODUCTS.REQUEST });
		try {
			const responseData = await productService.getProducts();
			dispatch({
				type: types.GET_PRODUCTS.SUCCESS,
				payload: responseData.data.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_PRODUCTS.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const createProductAsync = (
	productData,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.CREATE_PRODUCT.REQUEST });
		try {
			await productService.createProduct(productData);
			showToast("Product Created Successful", "info");
			dispatch({ type: types.CREATE_PRODUCT.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.CREATE_PRODUCT.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const updateProductAsync = (
	productData,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.UPDATE_PRODUCT.REQUEST });
		try {
			await productService.updateProduct(productData);
			showToast("Product Updated Successful", "info");
			dispatch({ type: types.UPDATE_PRODUCT.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.UPDATE_PRODUCT.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const deleteProductAsync = (id, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.DELETE_PRODUCT.REQUEST });
		try {
			await productService.deleteProduct(id);
			showToast("Product Deleted Successful", "info");
			dispatch({ type: types.DELETE_PRODUCT.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.DELETE_PRODUCT.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const updateProductStatusAsync = (
	id,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.UPDATE_PRODUCT_STATUS.REQUEST });
		try {
			await productService.updateProductStatus(id);
			showToast("Product Status Updated Successful", "info");
			dispatch({ type: types.UPDATE_PRODUCT_STATUS.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.UPDATE_PRODUCT_STATUS.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
