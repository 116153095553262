import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { forgotPasswordAsync } from "../redux/actions";

export default function ForgotPassword() {
	const dispatch = useDispatch();
	const [email, setEmail] = useState("");
	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(forgotPasswordAsync(email));
	};
	return (
		<div className="auth_bg">
			<div className="auth_form">
				<div className="auth_form_container">
					<div className="logo"></div>
					<div className="auth_title">Forgot Password</div>
					<div className="fieldset">
						<label className="label">Email Address</label>
						<div className="input_box">
							<input type="text" onChange={(e) => setEmail(e.target.value)} />
						</div>
					</div>

					<div className="btn_ btn_orange" onClick={handleSubmit}>
						RESET PASSWORD
					</div>
				</div>
			</div>
		</div>
	);
}
