import * as types from "../types";

const initialState = {
	loading: false,
	error: null,
	tableAvailability: null,
	appointment: {},
	appointments: [],
};

const AppointmentReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.ADD_APPOINTMENT.REQUEST:
		case types.GET_APPOINTMENT.REQUEST:
		case types.GET_APPOINTMENTS.REQUEST:
		case types.GET_TABLE_AVAILABILITY.REQUEST:
		case types.UPDATE_APPOINTMENT.REQUEST:
		case types.DELETE_APPOINTMENT.REQUEST:
			return { ...state, loading: true };

		case types.ADD_APPOINTMENT.SUCCESS:
		case types.UPDATE_APPOINTMENT.SUCCESS:
		case types.DELETE_APPOINTMENT.SUCCESS:
			return { ...state, loading: false };

		case types.GET_APPOINTMENT.SUCCESS:
			return { ...state, loading: false, appointment: payload };

		case types.GET_TABLE_AVAILABILITY.SUCESSS:
			return { ...state, loading: false, tableAvailability: payload };

		case types.GET_APPOINTMENTS.SUCCESS:
			return { ...state, loading: false, appointments: payload };

		case types.ADD_APPOINTMENT.FAILURE:
		case types.GET_APPOINTMENT.FAILURE:
		case types.GET_APPOINTMENTS.FAILURE:
		case types.UPDATE_APPOINTMENT.FAILURE:
		case types.DELETE_APPOINTMENT.FAILURE:
		case types.GET_TABLE_AVAILABILITY.FAILURE:
			return { ...state, loading: false, error: payload };

		default:
			return state;
	}
};

export default AppointmentReducer;
