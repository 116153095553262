import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { mainFunctions } from "../providers/MainProvider";
import { changePasswordAsync } from "../redux/actions";
import Input from "./Input";

export default function Security() {
	const { logout } = useContext(mainFunctions);
	const dispatch = useDispatch();
	const [showPasswords, setShowPasswords] = useState({
		old: false,
		new: false,
		confirm: false,
	});
	const [userData, setUserData] = useState({
		currentPassword: "",
		newPassword: "",
		confirmNewPassword: "",
	});

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(
			changePasswordAsync(userData, async (_) => {
				logout();
			})
		);
	};
	return (
		<div className="auth_form" style={{ boxShadow: "unset" }}>
			<div className="auth_form_container">
				<div className="fieldset">
					<label className="label">Old Password</label>
					<div className="input_box">
						<Input
							type={showPasswords.old ? "text" : "password"}
							onChange={(e) =>
								setUserData({ ...userData, currentPassword: e.target.value })
							}
						/>
						<span
							className="togglepassword"
							onClick={() =>
								setShowPasswords({ ...showPasswords, old: !showPasswords.old })
							}
						>
							{showPasswords.old ? "Hide" : "Show"}
						</span>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">New Password</label>
					<div className="input_box">
						<Input
							type={showPasswords.new ? "text" : "password"}
							onChange={(e) =>
								setUserData({ ...userData, newPassword: e.target.value })
							}
						/>
						<span
							className="togglepassword"
							onClick={() =>
								setShowPasswords({ ...showPasswords, new: !showPasswords.new })
							}
						>
							{showPasswords.new ? "Hide" : "Show"}
						</span>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Confirm New Password</label>
					<div className="input_box">
						<Input
							type={showPasswords.confirm ? "text" : "password"}
							onChange={(e) =>
								setUserData({ ...userData, confirmNewPassword: e.target.value })
							}
						/>
						<span
							className="togglepassword"
							onClick={() =>
								setShowPasswords({
									...showPasswords,
									confirm: !showPasswords.confirm,
								})
							}
						>
							{showPasswords.confirm ? "Hide" : "Show"}
						</span>
					</div>
				</div>
				<div className="btn_ btn_orange" onClick={handleSubmit}>
					Save Changes
				</div>
			</div>
		</div>
	);
}
