import React, { useEffect, useState } from "react";
import TopBar from "../../components/TopBar";
import { useGuard } from "../../hooks/useGuard";
import { useDispatch, useSelector } from "react-redux";
import { getCashierTransactionsAsync } from "../../redux/actions";
import Tables from "../../components/Tables";
import { formatToCurrency, getDateTimeFormatUK } from "../../utils";
import { Link } from "react-router-dom";

export default function CashierTranctions() {
	useGuard();
	const dispatch = useDispatch();
	const { transactions } = useSelector((state) => state.cashier);
	useEffect(() => {
		dispatch(getCashierTransactionsAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tableStatusOptions = [
		{ title: "All", value: "" },
		{ title: "Success", value: "success" },
		{ title: "Pending", value: "pending" },
		{ title: "Failed", value: "failed" },
	];
	const [tableStatus, setTableStatus] = useState(tableStatusOptions[0]);

	const tableColumns = [
		{
			title: "Trans ID",
			dataIndex: "id",
			sort: false,
			search: false,
		},
		{
			title: "Store Name",
			dataIndex: "storeName",
			sort: false,
			search: true,
		},
		{
			title: "Customer Name",
			dataIndex: "customerName",
			sort: false,
			search: false,
		},
		{
			title: "Purchase Value",
			dataIndex: "purchaseValue",
			sort: false,
			search: true,
		},
		{
			title: "Rewards Value",
			dataIndex: "rewardValue",
			sort: false,
			search: true,
		},
		{
			title: "Type",
			dataIndex: "purchaseType",
			sort: false,
			search: false,
		},
		{
			title: "Delivery Status",
			dataIndex: "deliveryStatus",
			sort: false,
			search: false,
		},
		{
			title: "Date",
			dataIndex: "date",
			sort: false,
			search: false,
		},
		{
			title: "Status",
			dataIndex: "status",
			sort: false,
			search: false,
		},
		{
			title: "",
			dataIndex: "details",
			search: false,
		},
	];

	const [filteredTableData, setFilteredTableData] = useState(transactions);

	const dataSource =
		filteredTableData && filteredTableData.length > 0
			? filteredTableData.map((row) => {
					return {
						id: row.transactionId,
						storeName: <div>{row.storeName}</div>,
						customerName: <div>{row.customerName}</div>,
						purchaseValue: <div>₦{formatToCurrency(row.purchaseValue, 2)}</div>,
						rewardValue: <div>₦{formatToCurrency(row.rewardValue, 2)}</div>,
						purchaseType: (
							<div>{row.deliveryStatus ? "Delivery" : "Store"}</div>
						),
						deliveryStatus: (
							<div
								className={`status-${row.deliveryStatus === "Processing" ? "pending" : "success"}`}
							>
								{row.deliveryStatus}
							</div>
						),
						date: <div>{getDateTimeFormatUK(row.date)}</div>,
						status: (
							<div
								className={`status-${
									row.status === "Successful"
										? "success"
										: row.status === "Pending"
											? "pending"
											: "failed"
								}`}
							>
								{row.status}
							</div>
						),
						details: (
							<div className="table_btn">
								<Link to={`/cashier/transaction/${row.transactionId}`}>
									Details
								</Link>
							</div>
						),
					};
				})
			: [];

	useEffect(() => {
		setFilteredTableData(transactions);
	}, [transactions]);

	return (
		<div className="body bg-dark-gray4">
			<div className="cashier-main w-100 bg-dark-gray3">
				<TopBar title="Transactions" small={true} />
				<div className="mainbar-container">
					<div className="col-sm-12">
						<Tables
							title="All Transactions"
							//base props
							columns={tableColumns}
							dataSource={dataSource}
							source={transactions}
							setFilteredTableData={setFilteredTableData}
							//Filters Props
							handleStatusFilter={true}
							handleDateFilter={true}
							tableStatusOptions={tableStatusOptions}
							tableStatus={tableStatus}
							setTableStatus={setTableStatus}
							//search prop
							handleSearch={true}
							// pagination props
							showPagination={true}
							showPageSize={true}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
