import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mainFunctions } from "../providers/MainProvider";
import { assignCashiersAsync, getCashiersAsync } from "../redux/actions";

const AddStaffModal = () => {
	const dispatch = useDispatch();
	const { cashiers, loading } = useSelector((state) => state.user);

	useEffect(() => {
		dispatch(getCashiersAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	let filteredUsers = cashiers.filter((cashier) => {
		return cashier.assinged === false;
	});

	const { setShowModal } = useContext(mainFunctions);

	const [userId, setUserId] = useState("");

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(
			assignCashiersAsync(userId, async (_) => {
				dispatch(getCashiersAsync());
				setShowModal(false);
			})
		);
	};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">Add Staff</div>
			<p className="subtitle text-center"></p>
			<div className="auth_form_container">
				<div className="fieldset">
					<label className="label">Cashiers</label>
					<div className="input_box">
						<select onChange={(e) => setUserId(e.target.value)}>
							<option selected disabled>
								Select a Cashier
							</option>
							{filteredUsers.map((row, index) => {
								return (
									<option key={index} value={row.id}>
										{row.firstName} {row.lastName}({row.userName})
									</option>
								);
							})}
						</select>
					</div>
				</div>

				<div
					className="btn_ btn_orange"
					onClick={!loading ? handleSubmit : () => {}}
				>
					{loading ? (
						<div className="spinner-border" role="status"></div>
					) : (
						"Add Cashier"
					)}
				</div>
			</div>
		</div>
	);
};

export default AddStaffModal;
