import React, { useContext, useEffect, useState } from "react";
import { mainFunctions } from "../../providers/MainProvider";

import Sidebar from "../../components/Sidebar";
import TopBar from "../../components/TopBar";
import SummaryBox from "../../components/SummaryBox";
import Title from "../../components/Title";
import AddStore from "../../modals/AddStore";
import { useGuard } from "../../hooks/useGuard";
import { useDispatch, useSelector } from "react-redux";
import { getStoresAsync } from "../../redux/actions/store.actions";
import EditStore from "../../modals/EditStore";
import DeactivateStore from "../../modals/DeactivateStore";
import Tables from "../../components/Tables";

import { formatToCurrency, getDateTimeFormatUK } from "../../utils";
import { BsThreeDots } from "react-icons/bs";
import AnalyticsWrapper from "../../components/AnalyticsWrapper";
export default function Stores() {
	useGuard();
	const dispatch = useDispatch();
	const { subStores } = useSelector((state) => state.store);
	useEffect(() => {
		dispatch(getStoresAsync());
	}, []);
	const {
		totalStoresOverview,
		setModalPage,
		setModalData,
		setShowModal,
		ADD_STORE_SUPER_MODAL,
		EDIT_STORE_SUPER_MODAL,
	} = useContext(mainFunctions);

	const ACTIVE_STORES_TAB = {
		id: "active",
		title: "Active Stores",
		data: subStores,
	};
	const DEACTIVATED_STORES_TAB = {
		id: "deactivated",
		title: "Deactivated Stores",
		data: subStores,
	};
	const [activeChartTab, setActiveChartTab] = useState(ACTIVE_STORES_TAB);

	const tableColumns = [
		{
			title: "ID",
			dataIndex: "id",
			sort: true,
		},
		{
			title: "Store",
			dataIndex: "store",
			sort: true,
		},
		{
			title: "Store Location",
			dataIndex: "location",
			sort: true,
		},
		{
			title: "Store Manager",
			dataIndex: "manager",
			sort: true,
		},
		{
			title: "VAT",
			dataIndex: "vat",
			sort: true,
		},
		{
			title: "Flat Delivery Fee",
			dataIndex: "flatDeliveryFee",
			sort: true,
		},
		{
			title: "Date Added",
			dataIndex: "dateAdded",
			sort: true,
		},
		{
			title: "",
			dataIndex: "option",
		},
	];

	const handleSearch = (query) => {};

	const [filteredTableData, setFilteredTableData] = useState(subStores);

	const [visibilities, setVisibilities] = useState(() =>
		filteredTableData.map((x) => false)
	);

	const handleClick = (index) => {
		const newVisibilities = [...visibilities];
		newVisibilities.map((thisVisibility, ind) => {
			index !== ind
				? (newVisibilities[ind] = false)
				: (newVisibilities[index] = !newVisibilities[index]);
			return 0;
		});
		setVisibilities(newVisibilities);
	};

	function getManager(users) {
		const manager = users.find((user) => user.role === "Administrator");
		return <div>{manager && `${manager.firstName} ${manager.lastName}`}</div>;
	}

	const dataSource =
		filteredTableData && filteredTableData?.length > 0
			? filteredTableData.map((row, index) => {
					return {
						id: <div>ID: {row.id}</div>,
						store: <div>{row.name || ""}</div>,
						location: <div>{row.location}</div>,
						manager: (
							<div>{row.users.length > 0 ? getManager(row.users) : ""}</div>
						),
						vat: <div>{row.vat}</div>,
						flatDeliveryFee: (
							<div>₦{formatToCurrency(row.flatDeliveryFee, 1)}</div>
						),
						dateAdded: <div>{getDateTimeFormatUK(row.createdAt)}</div>,
						option: (
							<div className="">
								<div className="position-relative">
									<div
										className="d-flex items-center"
										style={{ cursor: "pointer" }}
									>
										<BsThreeDots onClick={() => handleClick(index)} size={24} />
									</div>
									{visibilities[index] ? (
										<div
											className="position-absolute border border-muted px-3 w-32 bg-white"
											style={{
												right: "0",
												top: "100%",
												zIndex: "2",
												width: "150px",
											}}
										>
											<div
												onClick={async () => {
													setModalPage(EDIT_STORE_SUPER_MODAL);
													setModalData(<EditStore store={row} />);
													setShowModal(true);
												}}
												style={{ cursor: "pointer" }}
												className="d-flex text-left py-3 border-bottom border-muted status-success hover:text-blue-dark text-small"
											>
												Edit Store
											</div>
											<div
												onClick={() => {
													setModalPage(EDIT_STORE_SUPER_MODAL);
													setModalData(
														<DeactivateStore store={row} active={row.active} />
													);
													setShowModal(true);
												}}
												style={{ cursor: "pointer" }}
												className={`d-flex text-left py-3 
                          hover:text-blue-dark text-small
                          ${row.active ? "status-failed" : "status-success"}
                          `}
											>
												{row.active ? "Deactivate Store" : "Activate Store"}
											</div>
										</div>
									) : (
										""
									)}
								</div>
							</div>
						),
					};
				})
			: [];

	useEffect(() => {
		let temp = [];
		if (activeChartTab.id === "active") {
			temp = subStores.filter((store) => store.active === true);
			setFilteredTableData(temp);
		} else if (activeChartTab.id === "deactivated") {
			temp = subStores.filter((store) => store.active === false);
			setFilteredTableData(temp);
		}
		setVisibilities(() => temp.map((x) => false));
		setFilteredTableData(temp);
	}, [activeChartTab, subStores]);

	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar title="Stores" />
				<div className="mainbar-container">
					<div
						className="btn_ btn_green mb-3 top_btn"
						onClick={async () => {
							await setModalPage(ADD_STORE_SUPER_MODAL);
							await setModalData(<AddStore />);
							setShowModal(true);
						}}
					>
						ADD A STORE
					</div>
					<Title title="Overview" />
					<AnalyticsWrapper>
						<SummaryBox
							title={totalStoresOverview.title}
							value={subStores.length}
						/>
					</AnalyticsWrapper>

					<div>
						<Tables
							title={activeChartTab.title}
							dataSource={dataSource}
							columns={tableColumns}
							handleSearch={handleSearch}
							showPagination={true}
							showPageSize={true}
							tabs={[ACTIVE_STORES_TAB, DEACTIVATED_STORES_TAB]}
							activeTab={activeChartTab}
							setActiveTab={setActiveChartTab}
							source={subStores}
							setFilteredTableData={setFilteredTableData}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
