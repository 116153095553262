import React, { useContext, useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import TopBar from "../../components/TopBar";
import SummaryBox from "../../components/SummaryBox";
import Tables from "../../components/Tables";
import { getDate } from "../../utils";
import { mainFunctions } from "../../providers/MainProvider";
import AddStaffModal from "../../modals/AddStaff";
import { useDispatch, useSelector } from "react-redux";
import { getCashiersAsync } from "../../redux/actions";
import { useGuard } from "../../hooks/useGuard";
import AnalyticsWrapper from "../../components/AnalyticsWrapper";

export default function StoreCashier() {
	useGuard();
	const dispatch = useDispatch();
	const { cashiers, totalCashiers } = useSelector((state) => state.user);
	useEffect(() => {
		dispatch(getCashiersAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const { setShowModal, setModalPage, ADD_STAFF_MODAL, setModalData } =
		useContext(mainFunctions);
	const tableColumns = [
		{
			title: "Cashier ID",
			dataIndex: "id",
		},
		{
			title: "Cashier Name",
			dataIndex: "cashierName",
			search: true,
		},
		{
			title: "Email Address",
			dataIndex: "email",
			search: true,
		},
		{
			title: "Start Date",
			dataIndex: "date",
		},
		{
			title: "",
			dataIndex: "option",
		},
	];
	const [filteredTableData, setFilteredTableData] = useState(cashiers);

	const dataSource =
		filteredTableData && filteredTableData.length > 0
			? filteredTableData.map((row, index) => {
					return {
						id: row.id,
						cashierName: (
							<div>
								{row.image && (
									<span style={{ marginRight: "11px" }}>
										<img
											src={require("../../" + row.image)}
											style={{ width: "32px", height: "32px" }}
											alt="img"
										/>
									</span>
								)}
								<span>
									{row.firstName} {row.lastName}
								</span>
							</div>
						),
						email: <div>{row.userName}</div>,
						date: <div>{getDate(row.dateCreated)}</div>,
					};
				})
			: [];

	useEffect(() => {
		let filteredCashiers = cashiers.filter((data) => data.subStoreId !== 0);
		setFilteredTableData(filteredCashiers);
	}, [cashiers]);

	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar title="Store Cashier" />
				<div className="mainbar-container">
					<div
						className="btn_ btn_green mb-3"
						onClick={async () => {
							await setModalPage(ADD_STAFF_MODAL);
							await setModalData(<AddStaffModal />);
							setShowModal(true);
						}}
					>
						ADD A CASHIER
					</div>
					<AnalyticsWrapper>
						<SummaryBox title="No of Staff" value={totalCashiers} />
					</AnalyticsWrapper>

					<Tables
						title="All Cashiers"
						columns={tableColumns}
						dataSource={dataSource}
						handleSearch={true}
						showPagination={true}
						source={cashiers}
						setFilteredTableData={setFilteredTableData}
					/>
				</div>
			</div>
		</div>
	);
}
