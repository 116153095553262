import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mainFunctions } from "../providers/MainProvider";
import {
	getProductsAsync,
	getDealTypesAsync,
	getDealsAsync,
	createDealAsync,
} from "../redux/actions";

const AddDeal = () => {
	const { setShowModal } = useContext(mainFunctions);
	const dispatch = useDispatch();
	const [dealData, setDealData] = useState({
		productId: "",
		discount: 0,
		xBuyItem: 0,
		dealType: "",
	});
	const { products } = useSelector((state) => state.product);
	const { dealTypes, loading } = useSelector((state) => state.reward);
	useEffect(() => {
		dispatch(getProductsAsync());
		dispatch(getDealTypesAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(
			createDealAsync(dealData, async (_) => {
				dispatch(getDealsAsync());
				setShowModal(false);
			})
		);
	};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">Add Product Deal</div>
			<p className="subtitle text-center"></p>
			<div className="auth_form_container">
				<div className="fieldset">
					<label className="label">Product</label>
					<div className="input_box">
						<select
							value={dealData.productId}
							onChange={(e) =>
								setDealData({
									...dealData,
									productId: parseInt(e.target.value),
								})
							}
						>
							<option value="" selected disabled>
								Select a Product
							</option>
							{products.map((row, index) => {
								return (
									<option value={row.id} key={index}>
										{row.name}
									</option>
								);
							})}
						</select>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Deal Type</label>
					<div className="input_box">
						<select
							value={dealData.dealType}
							onChange={(e) =>
								setDealData({ ...dealData, dealType: parseInt(e.target.value) })
							}
						>
							<option value="" selected disabled>
								Select a Deal Type
							</option>
							{dealTypes.map((row, index) => {
								return (
									<option value={row.id} key={index}>
										{row.name}
									</option>
								);
							})}
						</select>
					</div>
				</div>
				{dealData.dealType === 1 && (
					<div className="fieldset">
						<label className="label">Discount(%)</label>
						<div className="input_box">
							<input
								type="number"
								value={dealData.discount}
								onChange={(e) =>
									setDealData({ ...dealData, discount: e.target.value })
								}
							/>
						</div>
					</div>
				)}
				{dealData.dealType === 2 && (
					<div className="fieldset">
						<label className="label">no of items to buy to get one free</label>
						<div className="input_box">
							<input
								type="number"
								value={dealData.xBuyItem}
								onChange={(e) =>
									setDealData({ ...dealData, xBuyItem: e.target.value })
								}
							/>
						</div>
					</div>
				)}

				<div
					className="btn_ btn_orange"
					onClick={!loading ? handleSubmit : () => {}}
				>
					{loading ? (
						<div className="spinner-border" role="status"></div>
					) : (
						"Add Deal"
					)}
				</div>
			</div>
		</div>
	);
};

export default AddDeal;
