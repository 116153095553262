import axios from "./axios";

export default class RewardService {
	async getDealTypes() {
		const response = await axios.get(`api/deal/dealTypes`);

		return response;
	}
	async getDeals() {
		const response = await axios.get(`api/deal/deals`);

		return response;
	}
	async addDeal(data) {
		const response = await axios.post(`api/deal/deal`, data);

		return response;
	}
	async updateDeal(data) {
		const response = await axios.put(`api/deal/deal`, data);

		return response;
	}
	async deleteDeal(id) {
		const response = await axios.delete(`api/deal/deal?id=${id}`);

		return response;
	}
	async updateDealStatus(data) {
		const response = await axios.patch(`api/deal/status`, data);

		return response;
	}

	async getChallengePeriods() {
		const response = await axios.get(`api/challenge/periods`);

		return response;
	}
	async getChallengeTypes() {
		const response = await axios.get(`api/challenge/types`);

		return response;
	}
	async getChallenges() {
		const response = await axios.get(`api/challenge/challenges`);

		return response;
	}
	async addChallenge(data) {
		const response = await axios.post(`api/challenge/challenge`, data);

		return response;
	}
	async updateChallenge(data) {
		const response = await axios.put(`api/challenge/challenge`, data);

		return response;
	}
	async deleteChallenge(id) {
		const response = await axios.delete(`api/challenge/challenge?id=${id}`);

		return response;
	}
	async updateChallengeStatus(data) {
		const response = await axios.patch(`api/challenge/status`, data);

		return response;
	}
}
