import React from "react";

const ConfirmCancelOrder = ({cancelOrder}) => {
	
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">Cancel order</div>
			<p className="subtitle text-center !text-white">
				Are you sure you want to cancel this order?
			</p>
			<div className="auth_form_container">
				<div className="btn_ btn_orange" onClick={cancelOrder}>
					Cancel Order
				</div>
			</div>
		</div>
	);
};

export default ConfirmCancelOrder;
