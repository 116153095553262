import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import TopBar from "../../components/TopBar";
import Tables from "../../components/Tables";
import SummaryBox from "../../components/SummaryBox";
import { formatToCurrency, getDateTimeFormatUK } from "../../utils";
import {
	getProductsAsync,
	updateProductStatusAsync,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useGuard } from "../../hooks/useGuard";
import { BsThreeDots } from "react-icons/bs";
import SetStock from "../../modals/SetStock";
import { useContext } from "react";
import { mainFunctions } from "../../providers/MainProvider";

export default function Products() {
	useGuard();
	const dispatch = useDispatch();
	const { products, totalProducts } = useSelector((state) => state.product);
	useEffect(() => {
		dispatch(getProductsAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const {
		setModalPage,
		setModalData,
		setShowModal,
		UPDATE_PRODUCT_STATUS_MODAL,
	} = useContext(mainFunctions);

	const [filteredTableData, setFilteredTableData] = useState(products);

	const tableColumns = [
		{
			title: "Product ID",
			dataIndex: "id",
			sort: true,
		},
		{
			title: "Product Name",
			dataIndex: "name",
			sort: true,
		},
		{
			title: "Unit Price",
			dataIndex: "unitPrice",
			sort: true,
		},
		{
			title: "No of Purchases",
			dataIndex: "purchases",
		},
		{
			title: "Date Uploaded",
			dataIndex: "date",
		},
		{
			title: "Stock Status",
			dataIndex: "stock",
		},
		{
			title: "",
			dataIndex: "option",
		},
	];

	const [visibilities, setVisibilities] = useState(() =>
		filteredTableData.map((x) => false)
	);

	const handleClick = (index) => {
		const newVisibilities = [...visibilities];
		newVisibilities.map((thisVisibility, ind) => {
			index !== ind
				? (newVisibilities[ind] = false)
				: (newVisibilities[index] = !newVisibilities[index]);
			return 0;
		});
		setVisibilities(newVisibilities);
	};

	const dataSource =
		filteredTableData && filteredTableData.length > 0
			? filteredTableData.map((row, index) => {
					return {
						id: row.id,
						name: (
							<div className="flex items-center gap-2">
								{row.coverImage && (
									<span>
										<img
											src={row.coverImage}
											style={{
												width: "32px",
												height: "32px",
												objectFit: "cover",
											}}
											alt="img"
										/>
									</span>
								)}
								<span>{row.name}</span>
							</div>
						),
						unitPrice: <div>₦{formatToCurrency(row.unitPrice, 2)}</div>,
						purchases: row.totalNumberOfPurchase,
						date: <div>{getDateTimeFormatUK(row.dateCreated)}</div>,
						stock: (
							<div
								className={`status-${row.isOutOfStock ? "pending" : "success"}`}
							>
								{row.isOutOfStock ? "Out of Stock" : "In Stock"}
							</div>
						),
						option: (
							<div className="">
								<div className="position-relative">
									<div
										className="d-flex items-center"
										style={{ cursor: "pointer" }}
									>
										<BsThreeDots onClick={() => handleClick(index)} size={24} />
									</div>
									{visibilities[index] ? (
										<div
											className="position-absolute border border-muted px-3 w-32 bg-white"
											style={{
												right: "0",
												top: "100%",
												zIndex: "2",
												width: "170px",
											}}
										>
											<div
												onClick={() => {
													setModalPage(UPDATE_PRODUCT_STATUS_MODAL);
													setModalData(<SetStock product={row} />);
													setShowModal(true);
												}}
												style={{ cursor: "pointer" }}
												className={`d-flex text-left py-3 
                        hover:text-blue-dark text-small
                        ${
													!row.isOutOfStock ? "status-failed" : "status-success"
												}
                        `}
											>
												{!row.isOutOfStock
													? "Set to OUT of Stock"
													: "Set to IN Stock"}
											</div>
										</div>
									) : (
										""
									)}
								</div>
							</div>
						),
					};
				})
			: [];

	useEffect(() => {
		setFilteredTableData(products);
		setVisibilities(() => products.map((x) => false));
	}, [products]);

	const updateStatus = (id) => {
		dispatch(updateProductStatusAsync(id));
	};

	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar title="Products" />
				<div className="mainbar-container">
					<div className="wrapper pt-5">
						<SummaryBox
							title={"Total Products"}
							value={totalProducts}
							comma={true}
						/>
					</div>
					<div className="col-sm-12">
						<Tables
							title="All Products"
							dataSource={dataSource}
							columns={tableColumns}
							handleSearch={true}
							showPagination={true}
							showPageSize={true}
							setFilteredTableData={setFilteredTableData}
							source={products}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
