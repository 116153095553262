import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import SummaryBox from "../../components/SummaryBox";
import TopBar from "../../components/TopBar";
// import Title from "../../components/Title";
import { useGuard } from "../../hooks/useGuard";
import { useDispatch, useSelector } from "react-redux";
import { getRevenueDataAsync } from "../../redux/actions";
import Tables from "../../components/Tables";
import { formatToCurrency, getDateTimeFormatUK } from "../../utils";
import { Link } from "react-router-dom";
import PageFilters from "../../components/PageFilters";
import { mainFunctions } from "../../providers/MainProvider";
import { storageService } from "../../services";
import AnalyticsWrapper from "../../components/AnalyticsWrapper";
import Divider from "../../components/Divider";
import { useSignalR } from "../../components/SignalRContext";

// Helper function to format date
const formatDate = (date) => {
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	const day = date.getDate().toString().padStart(2, "0");
	return `${year}-${month}-${day}`;
};

// Function to determine the date filter based on the given dateFilter
const getActiveDateFilter = (dateFilter) => {
	const currentDate = new Date();
	let activeDateFilter = { startDate: "", endDate: "" };

	if (dateFilter?.id) {
		switch (dateFilter.id) {
			case "today":
				const formattedDate = formatDate(currentDate);
				activeDateFilter = { startDate: formattedDate, endDate: formattedDate };
				break;

			case "thisweek":
				const currentDay = currentDate.getDay();
				const startDate = new Date(currentDate);
				startDate.setDate(currentDate.getDate() - currentDay); // Start of the week
				const endDate = new Date(startDate);
				endDate.setDate(startDate.getDate() + 6); // End of the week
				activeDateFilter = {
					startDate: formatDate(startDate),
					endDate: formatDate(endDate),
				};
				break;

			case "thismonth":
				const startOfMonth = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth(),
					1
				);
				const endOfMonth = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth() + 1,
					0
				);
				activeDateFilter = {
					startDate: formatDate(startOfMonth),
					endDate: formatDate(endOfMonth),
				};
				break;

			case "thisyear":
				const startOfYear = `${currentDate.getFullYear()}-01-01`;
				const endOfYear = `${currentDate.getFullYear()}-12-31`;
				activeDateFilter = { startDate: startOfYear, endDate: endOfYear };
				break;

			default:
				break;
		}
	}

	return activeDateFilter;
};

export default function Revenue() {
	useGuard();
	const { MimicPaymentConfirmation } = useSignalR();
	const authData = storageService.getAuthData();
	const dispatch = useDispatch();
	const { dateFilter } = useContext(mainFunctions);
	const { revenueData } = useSelector((state) => state.transaction);

	useEffect(() => {
		// Get the active date filter
		const activeDateFilter = getActiveDateFilter(dateFilter);

		// Fetch revenue data immediately
		dispatch(getRevenueDataAsync(activeDateFilter));

		// Set up polling every 60 seconds
		// const interval = setInterval(() => {
		// 	dispatch(getRevenueDataAsync(activeDateFilter));
		// }, 60000);

		// // Clear the interval when the component unmounts
		// return () => clearInterval(interval);
	}, [MimicPaymentConfirmation, dateFilter, dispatch]);

	const tableStatusOptions = [
		{ title: "All", value: "" },
		{ title: "Successful", value: "Successful" },
		{ title: "Refunded", value: "Refunded" },
		{ title: "AwaitingApproval", value: "AwaitingApproval" },
		{ title: "PartiallyPaid", value: "PartiallyPaid" },
		{ title: "Cancelled", value: "Cancelled" },
	];
	const [tableStatus, setTableStatus] = useState(tableStatusOptions[0]);

	const tableColumns = [
		{
			title: "Trans ID",
			dataIndex: "id",
			sort: false,
			search: false,
		},
		{
			title: "Store Name",
			dataIndex: "storeName",
			sort: false,
			display: authData.Role === "SuperAdministrator",
		},
		{
			title: "Customer Name",
			dataIndex: "customerName",
			sort: false,
			search: false,
		},
		{
			title: "Purchase Value",
			dataIndex: "purchaseValue",
			sort: false,
			search: true,
		},
		{
			title: "Rewards Value",
			dataIndex: "rewardValue",
			sort: false,
			search: true,
		},
		{
			title: "Type",
			dataIndex: "purchaseType",
			sort: false,
			search: false,
		},
		{
			title: "Delivery Status",
			dataIndex: "deliveryStatus",
			sort: false,
			search: false,
		},
		{
			title: "Date",
			dataIndex: "date",
			sort: false,
			search: false,
		},
		{
			title: "Payment Status",
			dataIndex: "status",
			sort: false,
			search: false,
		},
		{
			title: "",
			dataIndex: "details",
			search: false,
		},
	];

	const [filteredTableData, setFilteredTableData] = useState(
		revenueData.transactions
	);

	const dataSource =
		filteredTableData && filteredTableData.length > 0
			? filteredTableData.map((row) => {
					return {
						id: row.transactionId,
						storeName: <div>{row.storeName}</div>,
						customerName: <div>{row.customerName}</div>,
						purchaseValue: <div>₦{formatToCurrency(row.purchaseValue, 2)}</div>,
						rewardValue: <div>₦{formatToCurrency(row.rewardValue, 2)}</div>,
						purchaseType: <div>{row?.transactionDetail}</div>,
						deliveryStatus: (
							<div
								className={`status-${
									row.deliveryStatus === "Dispatched"
										? "success"
										: row.status === "Processing"
										? "pending"
										: "failed"
								}`}
							>
								{row.deliveryStatus}
							</div>
						),
						date: <div>{getDateTimeFormatUK(row.date)}</div>,
						status: (
							<div
								className={`status-${
									row.status === "Successful"
										? "success"
										: ["AwaitingApproval", "PartiallyPaid"].includes(row.status)
										? "pending"
										: "failed"
								}`}
							>
								{row.status}
							</div>
						),
						details: (
							<div className="table_btn px-2">
								<Link to={`/orders/${row.transactionId}`}>Details</Link>
							</div>
						),
					};
			  })
			: [];

	useEffect(() => {
		setFilteredTableData(revenueData.transactions);
	}, [revenueData]);

	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar title="Orders" />
				<div className="mainbar-container">
					<div className="h-[70px] w-full">
						<PageFilters showStoreFilter={false} />
					</div>

					<AnalyticsWrapper>
						<SummaryBox
							title={"Number of Transactions"}
							value={revenueData.totalTransactionMade}
							comma={true}
						/>
						<Divider />
						{typeof revenueData.totalRevenueMade !== "undefined" && (
							<>
								<SummaryBox
									title={"Revenue Made"}
									value={`₦${formatToCurrency(
										revenueData.totalRevenueMade,
										2
									)}`}
								/>
								<Divider />
							</>
						)}

						<SummaryBox
							title={"Pending Deliveries"}
							value={revenueData.pendingDeliveries}
						/>
						{/* <SummaryBox 
						title={"Wallet Balance"} 
						value={`₦${formatToCurrency(revenueData.walletBalance, 2)}`}
						/> */}
					</AnalyticsWrapper>
					<div className="col-sm-12">
						<Tables
							title="All Transactions"
							//base props
							columns={tableColumns}
							dataSource={dataSource}
							source={revenueData.transactions}
							setFilteredTableData={setFilteredTableData}
							//Filters Props
							handleStatusFilter={true}
							handleDateFilter={true}
							tableStatusOptions={tableStatusOptions}
							tableStatus={tableStatus}
							setTableStatus={setTableStatus}
							//search prop
							handleSearch={true}
							// pagination props
							showPagination={true}
							showPageSize={true}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
