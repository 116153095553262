import { generateActions } from "../../utils";

export const GET_USERS = generateActions("GET_USERS");
export const GET_USER_DETAILS = generateActions("GET_USER_DETAILS");
export const CREATE_USER = generateActions("CREATE_USER");
export const UPDATE_USER = generateActions("UPDATE_USER");
export const UPDATE_USER_STATUS = generateActions("UPDATE_USER_STATUS");
export const GET_CASHIERS = generateActions("GET_CASHIERS");
export const GET_CUSTOMERS = generateActions("GET_CUSTOMERS");
export const GET_CUSTOMER_DETAILS = generateActions("GET_CUSTOMER_DETAILS");
export const ASSIGN_CASHIER = generateActions("ASSIGN_CASHIER");
