import React, { useContext, useState, useEffect } from "react";
import { mainFunctions } from "../providers/MainProvider";
import CustomDatePicker from "../components/DatePicker.js";
import { useDispatch, useSelector } from "react-redux";
import { MdDateRange } from "react-icons/md";
import { getServicesAsync } from "../redux/actions";

const EditResourceDetail = (props) => {
	const dispatch = useDispatch();
	const { services, loading } = useSelector((state) => state.service);
	const [allServices, setAllServices] = useState([]);
	const { setShowModal } = useContext(mainFunctions);
	useEffect(() => {
		console.log(allServices);
		if (typeof services !== "undefined" && services !== null) {
			if (typeof services.data !== "undefined") {
				if (typeof services.data.data !== "undefined") {
					setAllServices(services.data.data);
				}
			}
		}
	}, [services]);

	const allFieldValid = () => {
		return true;
	};
	const handleSubmit = () => {
		props.setPage(2);
	};
	useEffect(() => {
		dispatch(getServicesAsync());
	}, []);
	return (
		<div className="flex-column align-items-center">
			<div className="title">Edit Resource</div>
			<p className="subtitle text-center"></p>

			{typeof props.thisresource !== "undefined" && (
				<div className="auth_form_container">
					{props.thisresource.discriminator === "TableResource" && (
						<div>
							<div className="fieldset">
								<label className="label">Table Name</label>
								<div className="input_box">
									<input
										defaultValue={props.thisresource.tableName}
										onChange={(e) => {
											props.updateThisResource("tableName", e.target.value);
										}}
										type="text"
									/>
								</div>
							</div>
							<div className="fieldset">
								<label className="label">Number of Seats</label>
								<div className="input_box">
									<input
										defaultValue={props.thisresource.noOfSeats}
										onChange={(e) => {
											props.updateThisResource("noOfSeats", e.target.value);
										}}
										type="text"
									/>
								</div>
							</div>

							<div className="fieldset">
								<label className="label">Attached Personnel</label>
								<div className="input_box">
									<input
										defaultValue={props.thisresource.attachedPersonnel}
										onChange={(e) => {
											props.updateThisResource(
												"attachedPersonnel",
												e.target.value
											);
										}}
										type="text"
									/>
								</div>
							</div>
							<div className="fieldset">
								<label className="label">Description</label>
								<div className="input_box">
									<textarea
										defaultValue={props.thisresource.description}
										onChange={(e) => {
											props.updateThisResource("description", e.target.value);
										}}
									></textarea>
								</div>
							</div>

							<div className="fieldset">
								<label className="label">Table Cost</label>
								<div className="input_box">
									<input
										defaultValue={props.thisresource.tableCost}
										onChange={(e) => {
											props.updateThisResource("tableCost", e.target.value);
										}}
										type="text"
									/>
								</div>
							</div>
						</div>
					)}
					{props.thisresource.discriminator === "PersonnelResource" && (
						<div>
							<div className="fieldset">
								<label className="label">First Name</label>
								<div className="input_box">
									<input
										defaultValue={props.thisresource.firstName}
										onChange={(e) => {
											props.updateThisResource("firstName", e.target.value);
										}}
										type="text"
									/>
								</div>
							</div>
							<div className="fieldset">
								<label className="label">Last Name</label>
								<div className="input_box">
									<input
										defaultValue={props.thisresource.lastName}
										onChange={(e) => {
											props.updateThisResource("lastName", e.target.value);
										}}
										type="text"
									/>
								</div>
							</div>

							<div className="fieldset">
								<label className="label">Phone Number</label>
								<div className="input_box">
									<input
										defaultValue={props.thisresource.phoneNumber}
										onChange={(e) => {
											props.updateThisResource("phoneNumber", e.target.value);
										}}
										type="text"
									/>
								</div>
							</div>

							<div className="fieldset">
								<label className="label">Service</label>
								<div className="input_box">
									{typeof services === "object" && (
										<select
											defaultValue={props.thisresource.services[0].serviceId}
											onChange={(e) => {
												let s = e.target.value;
												props.setThisresource({
													...props.thisresource,
													services: [{ serviceId: s }],
												});
											}}
										>
											<option value="" disabled>
												Select a service
											</option>
											{allServices.map((thisservice, index) => {
												return (
													<option value={thisservice.id} key={index}>
														{thisservice.name}
													</option>
												);
											})}
										</select>
									)}
								</div>
							</div>

							<div className="divider_box">
								<div className="text">Service Type</div>
								<div className="line"></div>
							</div>

							<div className="flex_field justify_left">
								<div className="combofield">
									<div
										className={`combobox 
                                ${props.thisresource.walkIn ? "active" : ""}
                                `}
										onClick={() => {
											props.updateThisResource(
												"walkIn",
												!props.thisresource.walkIn
											);
										}}
									></div>
									<div className="text">Walk in</div>
								</div>
								<div className="combofield">
									<div
										className={`combobox
                                ${props.thisresource.homeService ? "active" : ""}
                                `}
										onClick={() =>
											props.updateThisResource(
												"homeService",
												!props.thisresource.homeService
											)
										}
									></div>
									<div className="text">Home Service</div>
								</div>
							</div>
						</div>
					)}
					<div
						className={`btn_  ${allFieldValid ? "btn_orange" : "btn_inactive"}`}
						onClick={() => (allFieldValid ? handleSubmit() : {})}
					>
						Next
					</div>
				</div>
			)}
		</div>
	);
};

export default EditResourceDetail;
