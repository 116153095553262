import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";

import TabTitle from "../../../components/TabTitle";
import EditChallenge from "../../../modals/EditChallenge";
import { mainFunctions } from "../../../providers/MainProvider";
import {
	getChallengesAsync,
	updateChallengeStatusAsync,
} from "../../../redux/actions";
import { formatToCurrency } from "../../../utils";

export default function Challenges({ challenges, loading }) {
	const { setModalPage, setModalData, setShowModal, EDIT_CHALLENGE_MODAL } =
		useContext(mainFunctions);
	const dispatch = useDispatch();
	const [filteredChallenges, setFilteredChallenges] = useState([]);
	const DAILY_CHALLENGE_TAB = {
		id: "Daily",
		title: "Daily Challenges",
		data: {},
	};
	const WEEKLY_CHALLENGE_TAB = {
		id: "Weekly",
		title: "Weekly Challenges",
		data: {},
	};
	const MONTHLY_CHALLENGE_TAB = {
		id: "Monthly",
		title: "Monthly Challenges",
		data: {},
	};
	const LIFETIME_CHALLENGE_TAB = {
		id: "LifeTime",
		title: "LifeTime Challenges",
		data: {},
	};
	const [activeChartTab, setActiveChartTab] = useState(DAILY_CHALLENGE_TAB);

	const toggleChallenge = (challenge) => {
		if (!loading) {
			let challengeData = {
				id: challenge.id,
				status: challenge.isActive ? "false" : "true",
			};
			dispatch(
				updateChallengeStatusAsync(challengeData, async (_) => {
					dispatch(getChallengesAsync());
				})
			);
		}
	};
	useEffect(() => {
		if (challenges) {
			var fc = challenges.filter((thisChallenge) => {
				if (activeChartTab.id === thisChallenge.resetTimePeriod) {
					return true;
				}
				return false;
			});
			setFilteredChallenges(fc);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [challenges, activeChartTab]);
	return (
		<div>
			<TabTitle
				pages={[
					DAILY_CHALLENGE_TAB,
					WEEKLY_CHALLENGE_TAB,
					MONTHLY_CHALLENGE_TAB,
					LIFETIME_CHALLENGE_TAB,
				]}
				active={activeChartTab}
				setActive={setActiveChartTab}
				underline={true}
			/>
			<div className="challenges_title">Challenges</div>
			<div className="challenge_box">
				{filteredChallenges.map((thisChallenge, index) => {
					return (
						<div className="challenge_box_tr" key={thisChallenge.id}>
							<div className="title_box">
								<div className="title">{thisChallenge.title}</div>
								<div className="challengeType">({thisChallenge.type})</div>
								<div className="sub_title">{thisChallenge.description}</div>
							</div>
							<div className="challenge_box_td">
								<div className="item_text">
									₦{formatToCurrency(thisChallenge.rewardAmount, 2)}
								</div>
							</div>
							<div className="challenge_box_td">
								<div
									className="item_action"
									onClick={async () => {
										await setModalPage(EDIT_CHALLENGE_MODAL);
										await setModalData(<EditChallenge data={thisChallenge} />);
										setShowModal(true);
									}}
								>
									Edit
								</div>
							</div>
							<div className="challenge_box_td">
								<div
									className={`toggle_container ${thisChallenge.isActive && "active"}`}
									onClick={() => {
										toggleChallenge(thisChallenge);
									}}
								>
									<div className="toggle_knob"></div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}
