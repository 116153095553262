import React, { useContext, useEffect, useState } from "react";
import { mainFunctions } from "../providers/MainProvider";
import { postTriggerWithdrawAsync } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";

const ConfirmPassword = () => {
	const dispatch = useDispatch();
	const { withdrawData } = useSelector((state) => state.transaction);
	const { setShowModal } = useContext(mainFunctions);
	const [data, setData] = useState({});
	useEffect(() => {
		setData({
			...withdrawData,
			narration: "transfer",
		});
	}, [withdrawData]);
	const handleSubmit = () => {
		dispatch(
			postTriggerWithdrawAsync(data, async (_) => {
				setShowModal(false);
			})
		);
	};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">Authenticate Transaction</div>
			<p className="subtitle text-center">
				Enter your password to complete the transaction
			</p>
			<div className="auth_form_container">
				<div className="fieldset">
					<label className="label">Password</label>
					<div className="input_box">
						<input
							type="password"
							name="password"
							onChange={(e) => setData({ ...data, password: e.target.value })}
						/>
					</div>
				</div>

				<div className="btn_ btn_orange" onClick={handleSubmit}>
					Make Transfer
				</div>
			</div>
		</div>
	);
};

export default ConfirmPassword;
