import React, { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { mainFunctions } from "../../providers/MainProvider";
import Sidebar from "../../components/Sidebar";
import TopBar from "../../components/TopBar";
import DetailsBox from "../../components/DetailsBox";
import EditAppointment from "../../modals/EditAppointment";
import { convertNumberToDateTime } from "../../utils";
import DeleteAppointment from "../../modals/DeleteAppointment";

export default function AppointmentDetails() {
	const navigate = useNavigate();
	const { state } = useLocation();
	const { setShowModal, setModalData } = useContext(mainFunctions);

	const removeTime = (date) => {
		if (date !== null && date.toString().length > 5) return date.slice(0, 10);
		else return "-";
	};

	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar
					title="Appointment Details"
					button={{
						title: "EDIT APPOINTMENT",
					}}
					buttonAction={async () => {
						setModalData(<EditAppointment row={state.row} />);
						setShowModal(true);
					}}
					alt_button={{
						title: "DELETE APPOINTMENT",
					}}
					alt_buttonAction={async () => {
						const goBack = () => {
							navigate(`../appointments`);
						};
						setModalData(<DeleteAppointment row={state.row} goBack={goBack} />);
						setShowModal(true);
					}}
				/>
				{typeof state.row !== "undefined" && state.row && (
					<div className="full-mainbar-container">
						<div className="row">
							<DetailsBox
								title="Resource ID"
								value={`ID: ${state.row.id}`}
							></DetailsBox>
							<DetailsBox
								title="Resource Name:"
								value={`${state.row.TableName ? state.row.TableName : state.row.resourceId}`}
							></DetailsBox>
							<DetailsBox
								title="Customer"
								value={`${state.row.customerName}`}
							></DetailsBox>
							<DetailsBox
								title="Service"
								value={`${state.row.serviceId ? state.row.serviceId : "Table"}`}
							></DetailsBox>
						</div>

						<div className="divider_box">
							<span className="line"></span>
						</div>

						<div className="row">
							<DetailsBox
								title="Booking Time"
								value={removeTime(state.row.appointmentDay)}
							></DetailsBox>
							<DetailsBox
								title="Appointment Time"
								value={convertNumberToDateTime(
									Number(state.row.appointmentTime)
								)}
							></DetailsBox>
							<DetailsBox
								title="Payment Info:"
								value={state.row.paymentStatus}
							></DetailsBox>
						</div>

						<div className="divider_box">
							<span className="line"></span>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
