import { getDecodedAccessToken } from "../utils";
import axios from "./axios";
import { storageService } from "./index";

export default class IdentityService {
	async fetchAllRoles() {
		const response = await axios.get(`api/auth/list-all-roles`);

		return response;
	}

	async login(loginData) {
		storageService.clearAuthData();
		const response = await axios.post(`api/auth/login`, loginData);

		const userData = getDecodedAccessToken(response.data.data.accessToken);

		storageService.saveAuthData({
			accessToken: response.data.data.accessToken,
			UserName: userData.UserName,
			SubStoreId: userData.SubStoreId,
			name: `${userData.FirstName} ${userData.LastName}`,
			Role: userData.Role,
			StoreId: userData.StoreId,
			expires_in: userData.exp,
		});
		return userData;
	}

	async ssoLogin() {
		const response = await axios.post(`api/auth/sso-login`);

		const userData = getDecodedAccessToken(response.data.data.accessToken);

		storageService.saveAuthData({
			accessToken: response.data.data.accessToken,
			UserName: userData.UserName,
			SubStoreId: userData.SubStoreId,
			Role: userData.Role,
			StoreId: userData.StoreId,
			expires_in: userData.exp,
		});
		this.getUserDetails();
		return userData;
	}

	async getUserDetails() {
		const response = await axios.get(`api/auth/user-info`);
		console.log("Userdetails >>", response)
		let userData = response.data.data;
		storageService.set("name", `${userData.firstName} ${userData.lastName}`);
		return userData;
	}

	async forgotPassword(data) {
		storageService.clearAuthData();
		const response = await axios.post(
			`api/auth/forgot-password-admin?email=${data}`
		);

		return response;
	}

	async resetPassword(data) {
		storageService.clearAuthData();
		const response = await axios.post(`api/auth/reset-password`, data);

		return response;
	}

	async changePassword(data) {
		const response = await axios.post(`api/auth/change-admin-password`, data);

		return response;
	}

	async logout() {
		storageService.clearCookieData();
		return window.location.replace(process.env.REACT_APP_LOGIN_URL);
	}

	async isAuthenticated() {
		const authData = storageService.getAuthData();
		if (!authData.accessToken) {
			return false;
		}
		return true;
	}

	async getCompanyInfo() {
		const response = await axios.get(`api/store/company-info`);

		return response;
	}

	async patchCompanyInfo(data) {
		const response = await axios.patch(`api/auth/company-setup`, data);

		return response;
	}
}
