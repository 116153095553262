import React, { useState, useEffect } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { chartColors } from './colors';
import { randomColor, transparentize } from '../../utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


export default function ResponsiveLineChartComponent({ data }) {
  const [chartData, setChartData] = useState({
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [{
      label: 'Sample Data',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
    }]
  });
  const [chartOptions, setChartOptions] = useState({
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: data.title,
      },
    },
  });

  function transformData(data) {
    const datasets = data.plots?.map((plot, i) => {
      const tempCoordinates = [];
      plot.coordinates.map((item) => {
        tempCoordinates.push({
          x: item.xTag,
          y: item.yValue,
        })
      });
      const random_Color = randomColor();

      return {
        label: plot.key,
        borderColor: plot.key === 'average' ? ("#172B4D") : (chartColors[i] ? chartColors[i] : random_Color),
        backgroundColor: plot.key === 'average' ? (transparentize("#172B4D", 0.5)) : (chartColors[i] ? transparentize(chartColors[i], 0.5) : random_Color),
        fill: false,
        borderDash: plot.key === 'average' ? [5, 5] : [0, 0],
        data: tempCoordinates
      }
    });

    let dataSet = {
      labels: data.plots?.[0]?.coordinates?.map(({ xTag }) => xTag),
      datasets,
    };
    let options = {
      maintainAspectRatio: false,
      aspectRatio: 2,
      legend: {
        display: false,
      },
      responsive: true,
      plugins: {
        legend: {
          position: 'right',
        },
        title: {
          display: true,
          text: data.title,
          font: {
            family: 'Comic Sans MS',
            size: 22,
            weight: 'bold',
            lineHeight: 1.2,
          },
        },
        tooltip: {
          bodyFontSize: 16,
          bodyFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          titleFontSize: 18,
          // backgroundColor: '#fff',
          // titleFontColor: '#000',
          // bodyFontColor: '#005C01',
          bodySpacing: 10,
          titleSpacing: 9,
          titleMarginBottom: 5,
          caretPadding: 10,
          caretSize: 10,
          displayColors: true,
          // borderColor: '#eee',
          borderWidth: 1,
          xPadding: 5,
          yPadding: 5,
          titleAlign: 'center',
        },
      },
      scales: {
        x: {
          display: true,
          gridLines: {
            display: false,
            drawBorder: false,
          },
          title: {
            display: true,
            text: data.xAxisTitle,
            color: '#911',
            font: {
              family: 'Comic Sans MS',
              size: 20,
              weight: 'bold',
              lineHeight: 1.2,
            },
            padding: {top: 20, left: 0, right: 0, bottom: 0}
          }
        },
        y: {
          display: true,
          gridLines: {
            drawBorder: false,
          },
          ticks: {
            // beginAtZero: true,
            // stepSize: 100,
            // max: 500,
          },
          title: {
            display: true,
            text: data.yAxisTitle,
            color: '#191',
            font: {
              family: 'Comic Sans MS',
              size: 20,
              weight: 'bold',
              lineHeight: 1.2,
            },
            padding: {top: 20, left: 0, right: 0, bottom: 10}
          }
        },
      },
    };
    setChartData(dataSet);
    setChartOptions(options);

  }

  useEffect(() => {
    transformData(data);
  }, [data]);

  return (
    <div className='responsive-chart-wrapper'>
      <Line options={chartOptions} data={chartData} />
    </div>
  )
}
