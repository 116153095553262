import React, { useEffect, useState } from "react";
import { convertNumberToDateTime } from "../utils";

export default function CheckInCheckOut(props) {
	const [timeType, setTimeType] = useState(96);
	const [checkInTime, setcheckInTime] = useState(0);
	const [checkOutTime, setcheckOutTime] = useState(1);
	const [breakTimeStart, setbreakTimeStart] = useState(0);
	const [breakTimeEnd, setbreakTimeEnd] = useState(0);
	const [breakTime, setbreakTime] = useState(0);
	const [workTime, setworkTime] = useState(0);

	useEffect(() => {
		if (Number(checkInTime) > Number(checkOutTime)) {
			setcheckOutTime(Number(checkInTime) + 1);
		}
	}, [checkInTime, checkOutTime]);
	useEffect(() => {
		if (Number(checkInTime) > Number(breakTimeStart)) {
			setbreakTimeStart(Number(checkInTime) + 1);
		}
	}, [checkInTime, breakTimeStart]);

	useEffect(() => {
		if (Number(breakTimeEnd) < Number(breakTimeStart)) {
			setbreakTimeEnd(Number(breakTimeStart) + 1);
		}
	}, [breakTimeStart, breakTimeEnd]);

	useEffect(() => {
		props.setCheckIn(checkInTime);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkInTime]);

	useEffect(() => {
		props.setCheckOut(checkOutTime);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkOutTime]);

	useEffect(() => {
		props.setBreakStart(breakTimeStart);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [breakTimeStart]);

	useEffect(() => {
		props.setBreakEnd(breakTimeEnd);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [breakTimeEnd]);

	useEffect(() => {
		var bt = Number(breakTimeEnd) - Number(breakTimeStart);
		var wt = Number(checkOutTime) - Number(checkInTime);
		setworkTime(wt - bt);
		props.setTotalTime(wt - bt);
		setbreakTime(bt);
	}, [checkInTime, checkOutTime, breakTimeStart, breakTimeEnd]);

	useEffect(() => {
		if (typeof props.timeType !== "undefined") {
			if (typeof Number(props.timeType) === "number") {
				setTimeType(props.timeType);
			}
		}
	}, [props.timeType]);
	return (
		<div className="flex_field">
			<div className="selectfield">
				<div className="text">Check-In Time</div>
				<div className="input_box">
					<select
						value={checkInTime}
						onChange={(e) => setcheckInTime(e.target.value)}
						defaultValue=""
					>
						<option value="" disabled>
							Select check-in time
						</option>
						{[...Array(Number(timeType) - Number(0))].map(
							(elementInArray, index) => {
								let value = index;
								let timestring = convertNumberToDateTime(value, timeType);
								return (
									<option key={index} value={value}>
										{timestring}
									</option>
								);
							}
						)}
					</select>
				</div>
			</div>

			<div className="selectfield">
				<div className="text">Check-Out Time</div>
				<div className="input_box">
					<select
						value={checkOutTime}
						onChange={(e) => setcheckOutTime(e.target.value)}
						defaultValue=""
					>
						<option value={null} disabled>
							Select check-out time
						</option>
						{[...Array(Number(timeType) - (Number(checkInTime) + 1))].map(
							(elementInArray, index) => {
								let value = index + Number(checkInTime);
								let timestring = convertNumberToDateTime(value, timeType);
								return (
									<option key={index} value={value}>
										{timestring}
									</option>
								);
							}
						)}
					</select>
				</div>
			</div>

			<div className="selectfield">
				<div className="text">Break Time Start</div>
				<div className="input_box">
					{Number(checkOutTime) > Number(checkInTime) && (
						<select
							value={breakTimeStart}
							onChange={async (e) => {
								await setbreakTimeStart(Number(e.target.value));
							}}
							defaultValue=""
						>
							<option value={null} disabled>
								Select break start time
							</option>
							{[...Array(Number(checkOutTime) - Number(checkInTime))].map(
								(elementInArray, index) => {
									let value = index + Number(checkInTime);
									let timestring = convertNumberToDateTime(value, timeType);
									return (
										<option key={index} value={value}>
											{timestring}
										</option>
									);
								}
							)}
						</select>
					)}
				</div>
			</div>

			<div className="selectfield">
				<div className="text">Break Time End</div>
				<div className="input_box">
					{Number(checkOutTime) > Number(checkInTime) && (
						<select
							value={breakTimeEnd}
							onChange={async (e) => {
								setbreakTimeEnd(Number(e.target.value));
							}}
							defaultValue=""
						>
							<option value={null} disabled>
								Select break end time
							</option>
							{[
								...Array(Number(checkOutTime) + 1 - Number(breakTimeStart)),
							].map((elementInArray, index) => {
								let value = index + Number(breakTimeStart);
								let timestring = convertNumberToDateTime(value, timeType);
								return (
									<option key={index} value={value}>
										{timestring}
									</option>
								);
							})}
						</select>
					)}
				</div>
			</div>
		</div>
	);
}
