import React, { useContext, useState, useEffect } from "react";
import CurrencyFormat from "react-currency-format";
import { useDispatch, useSelector } from "react-redux";
import ImageUploadPreview from "../components/ImageUploadPreview";
import { mainFunctions } from "../providers/MainProvider";
import {
	createProductAsync,
	getCategoriesAsync,
	getProductsAsync,
} from "../redux/actions";
import { showToast } from "../utils";

const AddProduct = () => {
	const { setShowModal } = useContext(mainFunctions);
	const dispatch = useDispatch();
	const { categories, loading } = useSelector((state) => state.product);
	useEffect(() => {
		dispatch(getCategoriesAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const [tempImages, setTempImages] = useState([]);
	const [data, setData] = useState({
		productName: "",
		unitPrice: "",
		categoryId: "",
		description: "",
	});
	useEffect(() => {
		setData({ ...data, images: tempImages });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tempImages]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const { productName, unitPrice, categoryId, description, images } = data;
		if (
			!productName ||
			!unitPrice ||
			!categoryId ||
			!description ||
			!images.length > 0
		) {
			return showToast("Fill out all the fields", "error");
		}
		const productData = new FormData();
		productData.append("ProductName", data.productName);
		productData.append("UnitPrice", data.unitPrice);
		productData.append("CategoryId", data.categoryId);
		productData.append("Description", data.description);
		[...data.images].forEach((image) => {
			productData.append("Images", image);
		});
		dispatch(
			createProductAsync(productData, async (_) => {
				dispatch(getProductsAsync());
				setShowModal(false);
			})
		);
	};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">Add Product</div>
			<p className="subtitle text-center"></p>
			<div className="auth_form_container">
				<ImageUploadPreview
					tempImages={tempImages}
					setTempImages={setTempImages}
				/>
				<div className="fieldset">
					<label className="label">Product Name</label>
					<div className="input_box">
						<input
							type="text"
							value={data.productName}
							onChange={(e) =>
								setData({ ...data, productName: e.target.value })
							}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Unit Price</label>
					<div className="input_box">
						<CurrencyFormat
							value={data.unitPrice}
							thousandSeparator={true}
							prefix={"₦"}
							onValueChange={(values) => {
								const { value } = values;
								setData({
									...data,
									unitPrice: value,
								});
							}}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Category</label>
					<div className="input_box">
						<select
							defaultValue={data.categoryId}
							onChange={(e) => setData({ ...data, categoryId: e.target.value })}
						>
							<option disabled value="">
								Select a Category
							</option>
							{categories.map((category) => (
								<option key={category.id} value={category.id}>
									{category.name}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Description</label>
					<div className="input_box">
						<input
							value={data.description}
							onChange={(e) =>
								setData({ ...data, description: e.target.value })
							}
						/>
					</div>
				</div>

				<div
					className="btn_ btn_orange"
					onClick={!loading ? handleSubmit : () => {}}
				>
					{loading ? (
						<div className="spinner-border" role="status"></div>
					) : (
						"Add Product"
					)}
				</div>
			</div>
		</div>
	);
};

export default AddProduct;
