import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mainFunctions } from "../providers/MainProvider";
import { createUserAsync, getAllRolesAsync } from "../redux/actions";
import { showToast } from "../utils";

const AddUser = () => {
	const { all_roles } = useSelector((state) => state.auth);
	const { setShowModal } = useContext(mainFunctions);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getAllRolesAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const [userData, setUserData] = useState({
		emailAddress: "",
		firstName: "",
		lastName: "",
		roleName: "",
		phoneNumber: "",
	});

	const handleSubmit = (e) => {
		e.preventDefault();
		const { emailAddress, firstName, lastName, roleName, phoneNumber } =
			userData;
		if (!emailAddress || !firstName || !lastName || !roleName || !phoneNumber) {
			return showToast("Fill out all the fields", "error");
		}
		dispatch(
			createUserAsync(userData, async (_) => {
				setShowModal(false);
			})
		);
	};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">Add New User</div>
			<p className="subtitle text-center"></p>
			<div className="auth_form_container">
				<div className="fieldset">
					<label className="label">First Name</label>
					<div className="input_box">
						<input
							type="text"
							required={true}
							value={userData.firstName}
							onChange={(e) =>
								setUserData({ ...userData, firstName: e.target.value })
							}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Last Name</label>
					<div className="input_box">
						<input
							type="text"
							required={true}
							value={userData.lastName}
							onChange={(e) =>
								setUserData({ ...userData, lastName: e.target.value })
							}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Email</label>
					<div className="input_box">
						<input
							type="email"
							required={true}
							value={userData.emailAddress}
							onChange={(e) =>
								setUserData({ ...userData, emailAddress: e.target.value })
							}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Phone Number</label>
					<div className="input_box">
						<input
							type="text"
							required={true}
							value={userData.phoneNumber}
							onChange={(e) =>
								setUserData({ ...userData, phoneNumber: e.target.value })
							}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">User Role</label>
					<div className="input_box">
						<select
							onChange={(e) =>
								setUserData({ ...userData, roleName: e.target.value })
							}
						>
							<option selected disabled>
								Select a Manager
							</option>
							{all_roles.map((row, index) => {
								return (
									<option value={row} key={index}>
										{row}
									</option>
								);
							})}
						</select>
					</div>
				</div>

				<div className="btn_ btn_orange" onClick={handleSubmit}>
					Add USER
				</div>
			</div>
		</div>
	);
};

export default AddUser;
