import React, { createContext, useState, useEffect, useContext } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { storageService } from "../../services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Create the SignalR Context
const SignalRContext = createContext();

// Create the SignalR Provider
export const SignalRProvider = ({ children }) => {
	const navigate = useNavigate();
	const authData = storageService.getAuthData();
	const [connection, setConnection] = useState(null);
	const [mimicMessage, setMimicMessage] = useState(null);
	const [mimicOverrideRequested, setMimicOverrideRequested] = useState(false);
	const [mimicPaymentConfirmation, setMimicPaymentConfirmation] =
		useState(null);

	useEffect(() => {
		if (authData?.accessToken) {
			// Initialize the SignalR connection
			const conn = new HubConnectionBuilder()
				.withUrl(process.env.REACT_APP_BACKEND_BASEURL + "StoreHub", {
					headers: {
						Authorization: `Bearer ${authData.accessToken}`,
					},
				})
				.build();

			// Start the connection
			conn
				.start()
				.then(() => {
					console.log("Connected to SignalR!");
				})
				.catch((err) => console.error("SignalR connection error: ", err));

			// Listen to the events
			conn.on("MimicMessage", (message) => {
				try {
					if (message?.senderType?.toLowerCase() === "customer") {
						const audio = new Audio(
							"https://all-public-images.s3.us-east-2.amazonaws.com/Mimic+Tones/message.mp3"
						);
						audio.oncanplaythrough = () => {
							audio.play().catch((err) => {
								console.error("Error playing audio:", err);
							});
						};
						console.log("message", message);
						toast.info(`New message: ${message.phoneNumber}`, {
							position: "top-right",
							autoClose: 5000,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							onClick: () => {
								navigate(
									`mimic-logs?phoneNumber=${encodeURIComponent(
										message.phoneNumber
									)}`
								);
							},
						});
					}
				} catch (error) {
					console.error("Audio error:", error);
				}

				// Set the mimic message state
				setMimicMessage(message);
			});

			conn.on("MimicOverrideRequested", (phoneNumber) => {
				try {
					const audio = new Audio(
						"https://all-public-images.s3.us-east-2.amazonaws.com/Mimic+Tones/manual-message.mp3"
					);
					audio.play().catch((error) => {
						console.error("Error playing audio:", error);
					});
					toast.error(`New manual order request: ${phoneNumber}`, {
						position: "top-right",
						autoClose: 5000,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						onClick: () => {
							navigate(
								`mimic-logs?phoneNumber=${encodeURIComponent(phoneNumber)}`
							);
						},
					});
				} catch (error) {
					console.error("Audio error:", error);
				}
				setMimicOverrideRequested(phoneNumber);
			});

			conn.on("MimicPaymentConfirmation", (order) => {
				try {
					const audio = new Audio(
						"https://all-public-images.s3.us-east-2.amazonaws.com/Mimic+Tones/confirmation.mp3"
					);
					audio.play().catch((error) => {
						console.error("Error playing audio:", error);
					});
					toast.success(`New order: ${order?.purchaseCode}`, {
						position: "top-right",
						autoClose: 5000,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						onClick: () => {
							navigate("/orders");
						},
					});
				} catch (error) {
					console.error("Audio error:", error);
				}
				console.log("tt");
				setMimicPaymentConfirmation(order);
			});

			// Store the connection
			setConnection(conn);

			// Cleanup connection on component unmount
			return () => {
				if (conn) {
					conn.stop();
				}
			};
		}
	}, [authData.accessToken, navigate]);

	return (
		<SignalRContext.Provider
			value={{
				connection,
				mimicMessage,
				mimicOverrideRequested,
				mimicPaymentConfirmation,
			}}
		>
			{children}
		</SignalRContext.Provider>
	);
};

// Custom hook to access the SignalR context
export const useSignalR = () => {
	const context = useContext(SignalRContext);
	if (!context) {
		throw new Error("useSignalR must be used within a SignalRProvider");
	}
	return context;
};
