import { cashierService } from "../../services";
import { showToast } from "../../utils";
import * as types from "../types";
import { showErrorMessage } from "./auth.actions";

export const getUserInfoAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_USER_INFO.REQUEST });
		try {
			const responseData = await cashierService.getUserInfo();
			dispatch({
				type: types.GET_USER_INFO.SUCCESS,
				payload: responseData.data.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_USER_INFO.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const getCheckoutDataAsync = (
	cashierData,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.CHECK_OUT_DATA.REQUEST });
		try {
			const responseData = await cashierService.checkout(cashierData);
			dispatch({ type: types.CHECK_OUT_DATA.SUCCESS });
			successCallback?.(responseData.data.data);
		} catch (err) {
			dispatch({ type: types.CHECK_OUT_DATA.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const updateCartAsync = (
	cashierData,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.UPDATE_CART.REQUEST });
		try {
			const responseData = await cashierService.updateCart(cashierData);
			dispatch({ type: types.UPDATE_CART.SUCCESS });
			successCallback?.(responseData.data.data);
		} catch (err) {
			dispatch({ type: types.CHECK_OUT_DATA.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const getCompleteCashOrderAsync = (
	data,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.COMPLETE_CASH_ORDER.REQUEST });
		try {
			const responseData = await cashierService.completeOrder(data);
			dispatch({ type: types.COMPLETE_CASH_ORDER.SUCCESS });
			showToast("Operation successful, Payment Confirmed!", "success");
			successCallback?.(responseData.data);
		} catch (err) {
			dispatch({ type: types.COMPLETE_CASH_ORDER.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const getImportPurchaseDetailsAsync = (
	data,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.IMPORTED_PURCHASE_DETAILS.REQUEST });
		try {
			const responseData = await cashierService.importPurchaseDetails(data);
			dispatch({ type: types.IMPORTED_PURCHASE_DETAILS.SUCCESS });
			successCallback?.(responseData.data);
		} catch (err) {
			dispatch({ type: types.IMPORTED_PURCHASE_DETAILS.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const getCashierTransactionsAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_CASHIER_TRANSACTIONS.REQUEST });
		try {
			const responseData = await cashierService.getCashierTransactions();
			dispatch({
				type: types.GET_CASHIER_TRANSACTIONS.SUCCESS,
				payload: responseData.data.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_CASHIER_TRANSACTIONS.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const getCashierTransactionAsync = (
	id,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_CASHIER_TRANSACTION.REQUEST });
		try {
			const responseData = await cashierService.getCashierTransaction(id);
			dispatch({
				type: types.GET_CASHIER_TRANSACTION.SUCCESS,
				payload: responseData.data.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_CASHIER_TRANSACTION.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
