import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { mainFunctions } from "../providers/MainProvider";
import { getCustomersAsync, updateUserAsync } from "../redux/actions";
import { showToast } from "../utils";

const AddUser = ({ user }) => {
	const { setShowModal } = useContext(mainFunctions);
	const dispatch = useDispatch();
	const [userData, setUserData] = useState({
		userId: user.userId,
		emailAddress: user.userName,
		firstName: user.firstName,
		lastName: user.lastName,
	});

	const handleSubmit = (e) => {
		e.preventDefault();
		const { emailAddress, firstName, lastName } = userData;
		if (!emailAddress || !firstName || !lastName) {
			return showToast("Fill out all the fields", "error");
		}
		dispatch(
			updateUserAsync(userData, async (_) => {
				dispatch(getCustomersAsync());
				setShowModal(false);
			})
		);
	};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">Edit Customer Details</div>
			<p className="subtitle text-center"></p>
			<div className="auth_form_container">
				<div className="fieldset">
					<label className="label">First Name</label>
					<div className="input_box">
						<input
							type="text"
							required={true}
							value={userData.firstName}
							onChange={(e) =>
								setUserData({ ...userData, firstName: e.target.value })
							}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Last Name</label>
					<div className="input_box">
						<input
							type="text"
							required={true}
							value={userData.lastName}
							onChange={(e) =>
								setUserData({ ...userData, lastName: e.target.value })
							}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Email</label>
					<div className="input_box">
						<input
							type="email"
							required={true}
							value={userData.emailAddress}
							onChange={(e) =>
								setUserData({ ...userData, emailAddress: e.target.value })
							}
						/>
					</div>
				</div>

				<div className="btn_ btn_orange" onClick={handleSubmit}>
					Save
				</div>
			</div>
		</div>
	);
};

export default AddUser;
