import React, { useContext, useState } from "react";
import { mainFunctions } from "../providers/MainProvider";
import CustomDatePicker from "../components/DatePicker.js";
import { MdDateRange } from "react-icons/md";

const AddNewTables = () => {
	const [filterDate, setFilterDate] = useState("");
	const { setShowModal } = useContext(mainFunctions);
	const handleSubmit = async (e) => {};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center add_staff_modal">
			<div className="title">Filter Table</div>
			<p className="subtitle text-center"></p>
			<div className="auth_form_container">
				<div className="fieldset">
					<label className="label">Company</label>
					<div className="input_box">
						<input type="text" />
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Service</label>
					<div className="input_box">
						<select>
							<option selected disabled>
								Select a Manager
							</option>
							<option>Service 1</option>
							<option>Service 2</option>
						</select>
					</div>
				</div>

				<div className="fieldset">
					<label className="label">Resource</label>
					<div className="input_box">
						<select>
							<option selected disabled>
								Select a Service
							</option>
							<option>Resource 1</option>
							<option>Resource 2</option>
						</select>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Date</label>
					<div className="input_box">
						<CustomDatePicker
							// date={data.dateOfBirth}
							// setDate={(value) => setData({ ...data, dateOfBirth: value })}

							date={filterDate}
							setDate={(value) => setFilterDate(value)}
						/>
						<MdDateRange size={22} />
					</div>
				</div>
				<div
					className="btn_ btn_orange"
					onClick={() => {
						setShowModal(false);
					}}
				>
					Filter
				</div>
			</div>
		</div>
	);
};

export default AddNewTables;
