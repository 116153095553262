import React from 'react'

// import { chartColors as COLORS} from './colors';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


export default function LineChartComponent({data, dataKey, yTitle, xTitle}) {
  const currencyFormatter = (value) => {
    return dataKey === "amount" ? `₦${value.toLocaleString()}` : value.toLocaleString();
  };
  return (
    <div className='border-solid border-[1px] border-dark-gray3 rounded-[6px] bg-dark-gray2 pb-[15px]'>
      <div className='bar-chart-wrapper  text-white'>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 50,
              right: 30,
              left: 50,
              bottom: 50,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name"></XAxis>
            <YAxis orientation='left' tickFormatter={currencyFormatter}></YAxis>
            <Tooltip formatter={currencyFormatter} />
            {/* <Legend /> */}
            <Line type="monotone" dataKey={dataKey} stroke="#A9FF50" />
          </LineChart>
        </ResponsiveContainer>
        
      </div>
      {/* <div className='text-center text-dark-gray3'>
        {xTitle}
      </div> */}
    </div>
  )
}