import React, { useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { mainFunctions } from "../providers/MainProvider";
import { getTablesAsync } from "../redux/actions";
import { showToast } from "../utils";
import TableAvailability from "./TableAvailability";
import CurrencyInput from "react-currency-input-field";
import { validText } from "../utils";

const AddNewTables = () => {
	const { setShowModal, setModalData } = useContext(mainFunctions);

	const dispatch = useDispatch();
	const [tableData, setTableData] = useState({
		tableName: null,
		noOfSeats: null,
		available: true,
		attachedPersonnel: "",
		description: null,
		tableCost: null,
		resourceAvailability: {
			monday: true,
			tuesday: true,
			wednesday: true,
			thursday: true,
			friday: true,
			saturday: true,
			sunday: true,
			weekdaysCheckIn: "",
			weekdaysCheckOut: "",
			weekendCheckIn: "",
			weekendCheckOut: "",
			weekDaysBreakTimeStart: "",
			weekDaysBreakTimeEnd: "",
			weekendBreakTimeStart: "",
			weekendBreakTimeEnd: "",
		},
	});

	const updateTableDate = (name, value) => {
		setTableData({
			...tableData,
			[name]: value,
		});
	};

	const handleSubmit = async () => {
		await setModalData(
			<TableAvailability tableData={tableData} setTableData={setTableData} />
		);
		await setShowModal(true);
	};

	const validTable = () => {
		let v =
			validText(tableData.tableName) &&
			tableData.noOfSeats !== null &&
			tableData.noOfSeats > 0 &&
			validText(tableData.attachedPersonnel) &&
			validText(tableData.description);
		return v;
	};

	useEffect(() => {
		dispatch(getTablesAsync());
	}, []);

	return (
		// <div className='w-100 h-100 d-flex flex-column align-items-center add_staff_modal'>
		<div className="d-flex flex-column align-items-center add_staff_modal">
			<div className="title">Add New Table</div>
			<p className="subtitle text-center"></p>
			<div className="auth_form_container">
				<div className="fieldset">
					<label className="label">
						Table Name
						<div
							className={`label_icon ${!validText(tableData.tableName) ? "important" : "check"}`}
						></div>
					</label>
					<div className="input_box">
						<input
							onChange={(e) => {
								updateTableDate("tableName", e.target.value);
							}}
							type="text"
							placeholder="Enter the table name"
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">
						Number of Seats
						<div
							className={`label_icon ${tableData.noOfSeats === null || tableData.noOfSeats <= 0 ? "important" : "check"}`}
						></div>
					</label>
					<div className="input_box">
						<input
							onChange={(e) => {
								if (!isNaN(Number(e.target.value))) {
									if (Number(e.target.value) > 0)
										updateTableDate("noOfSeats", e.target.value);
								}
							}}
							value={tableData.noOfSeats}
							type="text"
							placeholder="Enter the number of seats"
						/>
					</div>
				</div>

				<div className="fieldset">
					<label className="label">
						Attached Personnel
						<div
							className={`label_icon ${!validText(tableData.attachedPersonnel) ? "important" : "check"}`}
						></div>
					</label>
					<div className="input_box">
						<input
							onChange={(e) => {
								updateTableDate("attachedPersonnel", e.target.value);
							}}
							type="text"
							placeholder="Enter the attached personnel"
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">
						Description
						<div
							className={`label_icon ${!validText(tableData.description) ? "important" : "check"}`}
						></div>
					</label>
					<div className="input_box">
						<textarea
							onChange={(e) => {
								updateTableDate("description", e.target.value);
							}}
							placeholder="Enter the description for the table"
						></textarea>
					</div>
				</div>

				<div className="fieldset">
					<label className="label">Table Cost</label>
					<div className="input_box">
						<CurrencyInput
							placeholder="Enter the table cost"
							defaultValue={tableData.tableCost}
							decimalsLimit={2}
							prefix="₦"
							onValueChange={(value) => {
								updateTableDate("tableCost", value);
							}}
						/>
					</div>
				</div>

				<div
					className={`btn_ ${validTable() ? "btn_orange" : "btn_dumb"}`}
					onClick={() => {
						if (validTable()) {
							handleSubmit();
						}
					}}
				>
					Next
				</div>
			</div>
		</div>
	);
};

export default AddNewTables;
