import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mainFunctions } from "../providers/MainProvider";
import {
	createStoreAsync,
	getStoresAsync,
	getUsersAsync,
} from "../redux/actions";
import CurrencyFormat from "react-currency-format";

const AddStore = () => {
	const { users } = useSelector((state) => state.user);
	const { loading } = useSelector((state) => state.store);
	const { setShowModal } = useContext(mainFunctions);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getUsersAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	let filteredUsers = users.filter((user) => {
		return user.roleName === "Administrator" && user.subStoreId === 0;
	});
	const [storeData, setStoreData] = useState({
		location: "",
		assigneeId: "",
		vat: 0,
		flatDeliveryFee: 0,
	});

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(
			createStoreAsync(storeData, async (_) => {
				dispatch(getStoresAsync());
				setShowModal(false);
			})
		);
	};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">Add New Store</div>
			<p className="subtitle text-center"></p>
			<div className="auth_form_container">
				<div className="fieldset">
					<label className="label">Store Name</label>
					<div className="input_box">
						<input
							type="text"
							value={storeData.name}
							onChange={(e) =>
								setStoreData({ ...storeData, name: e.target.value })
							}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Store Location</label>
					<div className="input_box">
						<input
							type="text"
							value={storeData.location}
							required={true}
							onChange={(e) =>
								setStoreData({ ...storeData, location: e.target.value })
							}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Store Manager</label>
					<div className="input_box">
						<select
							onChange={(e) =>
								setStoreData({ ...storeData, assigneeId: e.target.value })
							}
						>
							<option selected disabled>
								Select a Manager
							</option>
							{filteredUsers.map((row, index) => {
								return (
									<option key={index} value={row.userId}>
										{row.firstName} {row.lastName}
									</option>
								);
							})}
						</select>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">VAT</label>
					<div className="input_box">
						<input
							type="number"
							value={storeData.vat}
							required={true}
							onChange={(e) =>
								setStoreData({ ...storeData, vat: e.target.value })
							}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">Flat Delivery Fee</label>
					<div className="input_box">
						<CurrencyFormat
							value={storeData.flatDeliveryFee}
							thousandSeparator={true}
							prefix={"₦"}
							onValueChange={(values) => {
								const { value } = values;
								setStoreData({
									...storeData,
									flatDeliveryFee: value,
								});
							}}
						/>
					</div>
				</div>

				<div
					className="btn_ btn_orange"
					onClick={!loading ? handleSubmit : () => {}}
				>
					{loading ? (
						<div className="spinner-border" role="status"></div>
					) : (
						"Add Store"
					)}
				</div>
			</div>
		</div>
	);
};

export default AddStore;
