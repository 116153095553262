import React, { useEffect, useState } from "react";
import EmptyPurchases from "../../components/EmptyPurchases";
import Tables from "../../components/Tables";
import TopBar from "../../components/TopBar";
import { formatToCurrency } from "../../utils";
import ShoppingBag from "../../assets/icons/shopping_bag.svg";
import { useGuard } from "../../hooks/useGuard";
import { useDispatch, useSelector } from "react-redux";
import {
	adminUpdateOrderAsync,
	getProductsAsync,
	getTransactionDataAsync,
} from "../../redux/actions";
import Sidebar from "../../components/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import OrderItems from "../../components/OrderItems";

export default function AdminEditOrder() {
	useGuard();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();
	const { products } = useSelector((state) => state.product);
	const { transactionData } = useSelector((state) => state.transaction);
	useEffect(() => {
		dispatch(getProductsAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (id) {
			dispatch(getTransactionDataAsync(id));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);
	const [purchaseItems, setPurchaseItems] = useState([]);
	const [initialPurchaseItems, setInitialPurchaseItems] = useState([]);
	

	const tableColumns = [
		{
			title: "Product ID",
			dataIndex: "id",
		},
		{
			title: "Product Name",
			dataIndex: "name",
			search: true,
		},
		{
			title: "Unit Price",
			dataIndex: "price",
			search: false,
		},
		{
			title: "",
			dataIndex: "action",
		},
	];
	let [filteredTableData, setFilteredTableData] = useState(products);
	const dataSource =
		filteredTableData && filteredTableData.length > 0
			? filteredTableData.map((row) => {
					return {
						id: row.id,
						name: (
							<div className="flex items-center">
								{row.coverImage && (
									<span style={{ marginRight: "11px" }}>
										<img
											src={row.coverImage}
											style={{
												width: "32px",
												height: "32px",
												objectFit: "cover",
											}}
											alt="img"
										/>
									</span>
								)}
								<span>{row.name}</span>
							</div>
						),
						price: <div>₦{formatToCurrency(row.unitPrice, 2)}</div>,
						action: (
							<div
								className="bg-success flex w-max p-2 border rounded-circle cursor-pointer"
								onClick={() => addPurchaseItem(row)}
							>
								<img src={ShoppingBag} alt="img" />
							</div>
						),
					};
				})
			: [];

	const addPurchaseItem = async (item) => {
		let temp = [...purchaseItems];
		temp.push({
			id: item.id,
			productName: item.name,
			price: item.unitPrice,
			image: item.coverImage,
			quantity: 1,
			specialRequest: "",
		});
		setPurchaseItems(temp);
	};
	const handleProceed = () => {
		let postData = {
			cartReq: [],
			purchaseCode: transactionData?.purchaseCode,
		};
		purchaseItems.map((item) =>
			postData.cartReq.push({
				quantity: item.quantity,
				productId: item.id,
				specialRequest: "",
			})
		);
		console.log("postData", postData);
		dispatch(
			adminUpdateOrderAsync(postData, (res) => {
				if (res?.data?.newOrderId) {
					navigate(`/orders/${res.data.newOrderId}`);
				} else {
					navigate(`/orders`);
				}
			})
		);
	};
	const deleteOrderItem = (item) => {
		let temp = [...purchaseItems];
		const itemIndex = temp.indexOf(item);
		if (itemIndex > -1) {
			temp.splice(itemIndex, 1);
		}
		setPurchaseItems(temp);
	};
	const importPurchase = async (data) => {
		let res = [];
		if (data?.purchasedItem.length > 0) {
			data.purchasedItem.map((item) =>
				res.push({
					id: item.productId,
					image: "",
					productName: item.name,
					price: item.unitPrice,
					quantity: item.quantity,
					specialRequest: item.specialRequest,
				})
			);

			setPurchaseItems(res);
			setInitialPurchaseItems(res)
		}
	};
	const increaseQty = (item) => {
		let temp = [...purchaseItems];
		const itemIndex = temp.indexOf(item);
		if (itemIndex > -1) {
			temp[itemIndex].quantity++;
		}
		setPurchaseItems(temp);
	};
	const decreaseQty = (item) => {
		let temp = [...purchaseItems];
		const itemIndex = temp.indexOf(item);
		if (itemIndex > -1) {
			temp[itemIndex].quantity = temp[itemIndex].quantity - 1;
			if (temp[itemIndex].quantity <= 0) {
				temp.splice(itemIndex, 1);
			}
		}
		setPurchaseItems(temp);
	};

	useEffect(() => {
		setFilteredTableData(products.filter((item) => item.isOutOfStock !== true));
	}, [products]);
	useEffect(() => {
		importPurchase(transactionData);
	}, [transactionData]);

	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar title="Edit Order" />
				<div className="row h-100 w-100">
					<div className="col px-5">
						<Tables
							columns={tableColumns}
							dataSource={dataSource}
							showPagination={true}
							handleSearch={true}
							source={products}
							setFilteredTableData={setFilteredTableData}
						/>
					</div>
					<div className="col bg-dark-gray1">
						{purchaseItems.length > 0 ? (
							<OrderItems
								items={purchaseItems}
								handleSubmit={handleProceed}
								deleteOrderItem={deleteOrderItem}
								decreaseQty={decreaseQty}
								increaseQty={increaseQty}
								initialPurchaseItems={initialPurchaseItems}
							/>
						) : (
							<EmptyPurchases />
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
