import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import TopBar from "../../components/TopBar";
import DetailsBox from "../../components/DetailsBox";
import Tables from "../../components/Tables";
import {
	calcDate,
	formatToCurrency,
	getDate,
	getDateTimeFormatUK,
} from "../../utils";
import { useGuard } from "../../hooks/useGuard";
import { getCustomerDetailsAsync } from "../../redux/actions";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export default function CustomerDetails() {
	useGuard();
	const dispatch = useDispatch();
	const { id } = useParams();
	const { customerDetails } = useSelector((state) => state.user);
	useEffect(() => {
		if (id) {
			dispatch(getCustomerDetailsAsync(id));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const tableColumns = [
		{
			title: "Trans ID",
			dataIndex: "id",
		},
		{
			title: "Purchase",
			dataIndex: "purchase",
		},
		{
			title: "Purchase Value",
			dataIndex: "purchaseValue",
		},
		{
			title: "Rewards Value",
			dataIndex: "rewardsValue",
		},
		{
			title: "Date",
			dataIndex: "date",
		},
		{
			title: "Status",
			dataIndex: "status",
		},
		{
			title: "",
			dataIndex: "transactionId",
			display: false,
		},
		{
			title: "",
			dataIndex: "product",
			display: false,
		},
		{
			title: "",
			dataIndex: "purchaseAmount",
			display: false,
		},
		{
			title: "",
			dataIndex: "purchaseReward",
			display: false,
		},
		{
			title: "",
			dataIndex: "purchaseStatus",
			display: false,
		},
	];
	const [filteredTableData, setFilteredTableData] = useState(
		customerDetails.customerPurchaseList
	);

	const dataSource =
		filteredTableData && filteredTableData.length > 0
			? filteredTableData.map((row) => {
					return {
						id: row.transactionId,
						purchase: <div>{row.product}</div>,
						purchaseValue: (
							<div>₦{formatToCurrency(row.purchaseAmount, 2)}</div>
						),
						rewardsValue: <div>₦{formatToCurrency(row.purchaseReward, 2)}</div>,
						date: <div>{getDateTimeFormatUK(row.purchaseDate)}</div>,
						status: (
							<div
								className={`status-${
									row.purchaseStatus === "Successful"
										? "success"
										: row.status === "Pending"
											? "pending"
											: "failed"
								}`}
							>
								{row.purchaseStatus}
							</div>
						),
						transactionId: row.transactionId,
						product: row.product,
						purchaseAmount: row.purchaseAmount,
						purchaseReward: row.purchaseReward,
						purchaseStatus: row.purchaseStatus,
					};
				})
			: [];

	useEffect(() => {
		setFilteredTableData(customerDetails.customerPurchaseList);
	}, [customerDetails]);
	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar title="Customer Details" small={true} />
				<div className="full-mainbar-container">
					<div className="row pt-5 border-bottom">
						<DetailsBox
							title="Customer ID:"
							value={customerDetails?.customerDetailList?.userId?.substring(
								0,
								customerDetails?.customerDetailList?.userId?.indexOf("-")
							)}
						/>
						<DetailsBox
							title="Customer Name:"
							value={`${customerDetails.customerDetailList?.firstName} ${customerDetails.customerDetailList?.lastName}`}
						/>
						<DetailsBox
							title="Email Address:"
							value={customerDetails.customerDetailList?.email}
						/>
						<DetailsBox
							title="Phone Number:"
							value={customerDetails.customerDetailList?.phoneNumber}
						/>
					</div>
					<div className="row">
						<DetailsBox
							title="Date Joined:"
							value={getDate(customerDetails.customerDetailList?.dateCreated)}
						/>
						{customerDetails.customerDetailList?.lastTransaction && (
							<DetailsBox
								title="Last Transaction:"
								value={[
									<p>
										{getDate(
											customerDetails.customerDetailList?.lastTransaction
										)}
										<br />(
										{calcDate(
											customerDetails.customerDetailList?.lastTransaction
										)}
										)
									</p>,
								]}
							/>
						)}
						<DetailsBox
							title="Number of Purchases:"
							value={customerDetails.customerDetailList?.numberOfPurchases}
						/>
						<DetailsBox
							title="Rewards Gotten:"
							value={customerDetails.customerDetailList?.numberOfRewardsGotten}
						/>
					</div>

					<Tables
						title="All Transactions"
						columns={tableColumns}
						dataSource={dataSource}
						handleSearch={true}
						showPagination={true}
						source={customerDetails.customerPurchaseList}
						setFilteredTableData={setFilteredTableData}
					/>
				</div>
			</div>
		</div>
	);
}
