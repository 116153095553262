import React from "react";
import "../assets/timeLine.css";
export default function DayTimeline() {
	const generatLineMatrix = (number) => {
		let base = 9;
		let stepsize = 36;
		let position = Number(base) + Number(number) * stepsize;
		return `matrix(1 0 0 1 ${position}.9302 18.3661)`;
		// return 'matrix(1 0 0 1 9.9303 18.3661)'
	};
	let position2 = generatLineMatrix(1);
	return (
		<div>
			<svg
				version="1.1"
				id="Layer_1"
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				style={{ background: "#838292", width: "100%" }}
			>
				<rect x="6.4" y="27.8" className="st0" width="100%" height="17.5" />
				<g>
					<g>
						<text transform={generatLineMatrix(23)} className="st0 st1 st2">
							11
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(22)} className="st0 st1 st2">
							10
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(21)} className="st0 st1 st2">
							9
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(20)} className="st0 st1 st2">
							8
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(19)} className="st0 st1 st2">
							7
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(18)} className="st0 st1 st2">
							6
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(17)} className="st0 st1 st2">
							5
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(16)} className="st0 st1 st2">
							4
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(15)} className="st0 st1 st2">
							3
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(14)} className="st0 st1 st2">
							2
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(13)} className="st0 st1 st2">
							1
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(12)} className="st0 st1 st2">
							12
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(11)} className="st0 st1 st2">
							11
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(10)} className="st0 st1 st2">
							10
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(10)} className="st0 st1 st2">
							9
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(8)} className="st0 st1 st2">
							8
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(7)} className="st0 st1 st2">
							7
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(6)} className="st0 st1 st2">
							6
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(5)} className="st0 st1 st2">
							5
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(4)} className="st0 st1 st2">
							4
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(3)} className="st0 st1 st2">
							3
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(2)} className="st0 st1 st2">
							2
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(1)} className="st0 st1 st2">
							1
						</text>
					</g>
					<g>
						<text transform={generatLineMatrix(0)} className="st0 st1 st2">
							12
						</text>
					</g>
				</g>
				<rect x="366.4" y="27.8" className="st3" width="288" height="17.5" />
				<rect x="474.4" y="26.8" className="st4" width="72" height="18.5" />
				<g>
					<g>
						<line className="st5" x1="870.4" y1="21.6" x2="870.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="834.4" y1="21.6" x2="834.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="798.4" y1="21.6" x2="798.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="762.4" y1="21.6" x2="762.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="726.4" y1="21.6" x2="726.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="690.4" y1="21.6" x2="690.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="654.4" y1="21.6" x2="654.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="618.4" y1="21.6" x2="618.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="582.4" y1="21.6" x2="582.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="546.4" y1="21.6" x2="546.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="510.4" y1="21.6" x2="510.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="474.4" y1="21.6" x2="474.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="438.4" y1="21.6" x2="438.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="402.4" y1="21.6" x2="402.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="366.4" y1="21.6" x2="366.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="330.4" y1="21.6" x2="330.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="294.4" y1="21.6" x2="294.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="258.4" y1="21.6" x2="258.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="222.4" y1="21.6" x2="222.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="186.4" y1="21.6" x2="186.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="150.4" y1="21.6" x2="150.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="114.4" y1="21.6" x2="114.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="78.4" y1="21.6" x2="78.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="42.4" y1="21.6" x2="42.4" y2="45.3" />
					</g>
					<g>
						<line className="st5" x1="6.4" y1="21.6" x2="6.4" y2="45.3" />
					</g>
				</g>
			</svg>
		</div>
	);
}
