import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mainFunctions } from "../providers/MainProvider";
import {
	addResourceAsync,
	getServicesAsync,
	getTablesAsync,
} from "../redux/actions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { showToast, validText, validPhone, validEmail } from "../utils";
import ResourceAvailability from "./ResourceAvailability";

const AddNewTables = () => {
	const { services, loading } = useSelector((state) => state.service);
	const [allServices, setAllServices] = useState([]);
	const { setShowModal, setModalData } = useContext(mainFunctions);
	const dispatch = useDispatch();
	const [personnelData, setpersonnelData] = useState({
		firstName: null,
		lastName: null,
		phoneNumber: null,
		services: null,
	});

	const [allFieldValid, setAllFieldValid] = useState(false);

	const updateTableData = (name, value) => {
		setpersonnelData({
			...personnelData,
			[name]: value,
		});
	};
	const handleSubmit = async () => {
		await setModalData(<ResourceAvailability personnelData={personnelData} />);
		await setShowModal(true);
	};

	useEffect(() => {
		dispatch(getServicesAsync());
	}, []);

	useEffect(() => {
		setAllFieldValid(
			validText(personnelData.firstName) &&
				validText(personnelData.lastName) &&
				validPhone(personnelData.phoneNumber) &&
				personnelData.services !== null
		);
	}, [personnelData]);

	useEffect(() => {
		if (typeof services !== "undefined" && services !== null) {
			if (typeof services.data !== "undefined") {
				if (typeof services.data.data !== "undefined") {
					setAllServices(services.data.data);
				}
			}
		}
	}, [services]);

	return (
		<div className="d-flex flex-column align-items-center add_staff_modal">
			<div className="title">Add a Personnel</div>
			<p className="subtitle text-center"></p>
			<div className="auth_form_container">
				<div className="fieldset">
					<label className="label">
						First Name
						<div
							className={`label_icon ${!validText(personnelData.firstName) ? "important" : "check"}`}
						></div>
					</label>
					<div className="input_box">
						<input
							onChange={(e) => {
								updateTableData("firstName", e.target.value);
							}}
							type="text"
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label">
						Last Name
						<div
							className={`label_icon ${!validText(personnelData.lastName) ? "important" : "check"}`}
						></div>
					</label>
					<div className="input_box">
						<input
							onChange={(e) => {
								updateTableData("lastName", e.target.value);
							}}
							type="text"
						/>
					</div>
				</div>

				<div className="fieldset">
					<label className="label">
						Phone Number
						<div
							className={`label_icon ${!validPhone(personnelData.phoneNumber) ? "important" : "check"}`}
						></div>
					</label>
					<div className="input_box">
						<PhoneInput
							country={"ng"}
							onlyCountries={["ng"]}
							value={personnelData.phoneNumber}
							onChange={(phone) => updateTableData("phoneNumber", phone)}
						/>
					</div>
				</div>

				<div className="fieldset">
					<label className="label">
						Service
						<div
							className={`label_icon ${personnelData.services === null ? "important" : "check"}`}
						></div>
					</label>
					<div className="input_box">
						{typeof services === "object" && (
							<select
								onChange={(e) => {
									updateTableData("services", e.target.value);
								}}
								defaultValue=""
							>
								<option value="" disabled>
									Select a service
								</option>
								{allServices.map((thisservice, index) => {
									return (
										<option value={thisservice.id}>{thisservice.name}</option>
									);
								})}
							</select>
						)}
					</div>
				</div>

				{/* To add Services Rendered */}
				<div
					className={`btn_  ${allFieldValid ? "btn_orange" : "btn_inactive"}`}
					onClick={() => (allFieldValid ? handleSubmit() : {})}
				>
					Next
				</div>
			</div>
		</div>
	);
};

export default AddNewTables;
